import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用'

const 宠物增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '领胡',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 308 / 1024 }],
  },
  {
    增益名称: '朱厌',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: -(307 / 1024) }],
    // 弛律召野召唤的宠物攻击减少30%伤害
  },
]

export default 宠物增益
