// 默认不展示在页面上的，但是鼠标移动上去可以点击
import React from 'react'
import './index.css'

function 页面隐藏工具() {
  // const [skillVisible, setSkillVisible] = useState(false)

  return (
    <div className='tools-wrapper'>
      {/* <span className='tools-btn' onClick={() => setSkillVisible(true)}>
        技能详情
      </span> */}
      {/* 技能详情 */}
      {/* <SkillDamageTable visible={skillVisible} onClose={() => setSkillVisible(false)} /> */}
    </div>
  )
}

export default 页面隐藏工具
