export default {
  装备基础属性: {
    基础攻击: 41694,
    破防等级: 42939,
    无双等级: 92176,
    会心等级: 43495,
    会心效果等级: 4593,
    全能等级: 0,
    武器伤害_最小值: 9266,
    武器伤害_最大值: 13283,
    破招值: 834,
    力道: 9111,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 136103,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 20536,
    装分: 510596,
  },
  装备镶嵌附魔属性: {
    基础攻击: 9268,
    破防等级: 3336,
    无双等级: 7094,
    会心等级: 11882,
    会心效果等级: 1668,
    全能等级: 0,
    武器伤害_最小值: 856,
    武器伤害_最大值: 856,
    破招值: 834,
    力道: 256,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 1593,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101454,
      装备部位: '帽子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101485,
      装备部位: '衣服',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101392,
      装备部位: '腰带',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101581,
      装备部位: '护腕',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101586,
      装备部位: '下装',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101423,
      装备部位: '鞋子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41315,
      装备部位: '项链',
      附魔: '体质+774',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41363,
      装备部位: '腰坠',
      附魔: '体质+774',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41357,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41357,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39288,
      装备部位: '暗器',
      附魔: '加速+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39355,
      装备部位: '武器',
      附魔: '武伤+856',
    },
  ],
  五彩石: '彩·无双·痛击·狂攻(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 1,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
