import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '涅果',
        奇穴图片: 'https://icon.jx3box.com/icon/4571.png',
        id: '5896',
        奇穴描述: '“普渡四方”伤害提高10%。',
      },
      {
        奇穴名称: '明法',
        奇穴图片: 'https://icon.jx3box.com/icon/446.png',
        id: '6589',
        奇穴描述: '“普渡四方”可叠加的“普渡”效果提高到3层。“韦陀献杵”使目标获得“普渡”效果。',
      },
      {
        奇穴名称: '秉心',
        奇穴图片: 'https://icon.jx3box.com/icon/14122.png',
        id: '6788',
        奇穴描述: '任意顺序施展“捕风式”“守缺式”“捉影式”后，激活“拿云式”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '幻身',
        奇穴图片: 'https://icon.jx3box.com/icon/2963.png',
        id: '5910',
        奇穴描述:
          '“横扫六合”若只命中一个目标，则在易筋经心法下伤害提高65%，在洗髓经心法下产生的威胁值提高100%。',
      },
      {
        奇穴名称: '善心',
        奇穴图片: 'https://icon.jx3box.com/icon/4535.png',
        id: '5912',
        奇穴描述:
          '施展“韦陀献杵”“拿云式”命中附带自身“横扫六合”持续伤害目标时能使下三次持续伤害立即生效。',
      },
      {
        奇穴名称: '抱残',
        奇穴图片: 'https://icon.jx3box.com/icon/1510.png',
        id: '16897',
        奇穴描述: '“捕风式”命中目标使目标移动速度降低60%，持续12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '身意',
        奇穴图片: 'https://icon.jx3box.com/icon/2967.png',
        id: '5915',
        奇穴描述:
          '“守缺式”会心几率提高10%，会心效果提高10%。施展“守缺式”后使自身4秒内会心几率提高5%，会心效果提高5%。',
      },
      {
        奇穴名称: '纷纭',
        奇穴图片: 'https://icon.jx3box.com/icon/17175.png',
        id: '30913',
        奇穴描述:
          '施展“千斤坠”后进入“纷纭”10秒，增加15%气血上限，可施展主动招式“千斤坠·无取”和“千斤坠·无舍”。施展“千斤坠”、“千斤坠·无取”、“千斤坠·无舍”每命中一个玩家回复自身5%的气血值。',
      },
      {
        奇穴名称: '挂碍',
        奇穴图片: 'https://icon.jx3box.com/icon/16210.png',
        id: '37369',
        奇穴描述:
          '“拿云式”命中目标后，在目标周围6尺引发一次冲击波造成伤害，冲击波依据自身基础体质和敌方目标玩家的差值，使目标获得3秒10—30%的降低加速效果。',
      },
      {
        奇穴名称: '抢珠式',
        奇穴图片: 'https://icon.jx3box.com/icon/1509.png',
        id: '240',
        奇穴描述: '打断目标运功，使其不能施展内功招式，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '缩地',
        奇穴图片: 'https://icon.jx3box.com/icon/4517.png',
        id: '17750',
        奇穴描述: '消耗禅那有50%几率使得下一次攻击附带阳性内功伤害。',
      },
      {
        奇穴名称: '空识',
        奇穴图片: 'https://icon.jx3box.com/icon/419.png',
        id: '38618',
        奇穴描述:
          '每次自身成功造成拉拽、眩晕、击倒时，使受控目标体质上限降低6500点，持续8秒，该效果最多叠加5层。',
      },
      {
        奇穴名称: '无可证',
        奇穴图片: 'https://icon.jx3box.com/icon/16212.png',
        id: '37370',
        奇穴描述:
          '“守缺式”命中敌方玩家时，获得6500点基础体质，最多叠加6层，持续8秒。满层时提供每秒回复1%最大生命值。',
      },
      {
        奇穴名称: '布泽',
        奇穴图片: 'https://icon.jx3box.com/icon/13420.png',
        id: '37455',
        奇穴描述:
          '消耗三点禅那施展“拿云式”“韦陀献杵”命中非侠士目标后，获得“布泽”最多3层。下一次“普渡四方”或“韦陀献杵”会消耗一层“布泽”并对非侠士目标附带一次额外伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '降魔渡厄',
        奇穴图片: 'https://icon.jx3box.com/icon/2964.png',
        id: '5913',
        奇穴描述:
          '3点禅那施展“韦陀献杵”“拿云式”附带“降魔”效果，使目标受到该招式25%的阳性内功伤害。',
      },
      {
        奇穴名称: '意相',
        奇穴图片: 'https://icon.jx3box.com/icon/422.png',
        id: '14793',
        奇穴描述:
          '“无相诀”可在“眩晕”、“定身”状态下释放，自身气血值每低于10%，减伤效果增加5%，减伤效果最多增加至90%。',
      },
      {
        奇穴名称: '静色',
        奇穴图片: 'https://icon.jx3box.com/icon/7482.png',
        id: '14758',
        奇穴描述:
          '“捉影式”变为2层充能技能，25秒充能时间。战斗状态下“捉影式”处于调息状态，4秒内未施展任何伤害招式，则使“捉影式”调息时间降低3秒。',
      },
      {
        奇穴名称: '法身',
        奇穴图片: 'https://icon.jx3box.com/icon/441.png',
        id: '37371',
        奇穴描述:
          '“守缺式”、“拿云式”造成伤害时，依据和目标侠士的体质差值造成额外伤害、回复自身气血值。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '狮啸',
        奇穴图片: 'https://icon.jx3box.com/icon/430.png',
        id: '18623',
        奇穴描述:
          '“大狮子吼”不再附带控制效果，生成一个半径10尺的阵法，使自身当前气血及气血最大值提高150%，阵法内的队友受到的伤害的99%传递给自身，期间可施展二段招式结束阵法。少林自身不可移动，调息时间50秒。',
      },
      {
        奇穴名称: '系珠',
        奇穴图片: 'https://icon.jx3box.com/icon/7426.png',
        id: '14750',
        奇穴描述: '“擒龙诀”变为3层充能效果，25秒充能时间，其增益效果持续9秒。',
      },
      {
        奇穴名称: '威服',
        奇穴图片: 'https://icon.jx3box.com/icon/23474.png',
        id: '38621',
        奇穴描述:
          '“大狮子吼”释放后留下一只金刚狮子在原地释放吼叫，每3秒对附近8尺最多5个目标造成眩晕2秒效果，持续9秒。',
      },
      {
        奇穴名称: '金刚怒目',
        奇穴图片: 'https://icon.jx3box.com/icon/440.png',
        id: '17730',
        奇穴描述: '施展“罗汉金身”使自身内功基础攻击提高30%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '净果',
        奇穴图片: 'https://icon.jx3box.com/icon/4544.png',
        id: '6590',
        奇穴描述:
          '“拿云式”命中血量低于30%的目标伤害提高15%。施展内功伤害招式有30%几率获得“拿云”效果:使自身无视目标气血百分比可立刻施展“拿云式”，最多可叠加3层。',
      },
      {
        奇穴名称: '无量波罗',
        奇穴图片: 'https://icon.jx3box.com/icon/2974.png',
        id: '6587',
        奇穴描述:
          '“摩诃无量”招式有效距离提高4尺，若成功控制目标使目标无法施展轻功持续3秒，命中带有自身“普渡”效果的目标，每层“普渡”使击倒和封轻功效果提高1秒。',
      },
      {
        奇穴名称: '独觉',
        奇穴图片: 'https://icon.jx3box.com/icon/421.png',
        id: '14795',
        奇穴描述: '“锻骨诀”施展后使自身不受外功伤害影响，持续4秒。',
      },
      {
        奇穴名称: '无色',
        奇穴图片: 'https://icon.jx3box.com/icon/2977.png',
        id: '5934',
        奇穴描述:
          '“无相诀”调息时间降低15秒，不再附带减伤效果，反弹受到的70%伤害，效果不会被卸除，持续10秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '明王身',
        奇穴图片: 'https://icon.jx3box.com/icon/2973.png',
        id: '5930',
        奇穴描述: '基础气血上限提高20%。',
      },
      {
        奇穴名称: '妙法',
        奇穴图片: 'https://icon.jx3box.com/icon/2968.png',
        id: '6595',
        奇穴描述:
          '“千斤坠”变为2层充能，施展“千斤坠”解除自身锁足、减速效果，自身不受招式控制（击退和被拉除外），持续4秒。',
      },
      {
        奇穴名称: '三生',
        奇穴图片: 'https://icon.jx3box.com/icon/4571.png',
        id: '6586',
        奇穴描述: '每点禅那增加自身10%无双等级，最多增加30%。',
      },
      {
        奇穴名称: '清心',
        奇穴图片: 'https://icon.jx3box.com/icon/442.png',
        id: '16894',
        奇穴描述:
          '施展“舍身诀”，则召唤一尊佛像为目标承担伤害，最多可承受自身50%气血最大值，且每3秒卸除友方目标阳性、阴性、混元、毒性不利效果各两个。',
      },
      {
        奇穴名称: '我闻',
        奇穴图片: 'https://icon.jx3box.com/icon/12728.png',
        id: '24884',
        奇穴描述:
          '“横扫六合”调息时间降低3秒，会心几率提高10%，会心效果提高20%，持续伤害可以叠加3层。“横扫六合”每多命中一个目标，调息时间降低1秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '非梵',
        奇穴图片: 'https://icon.jx3box.com/icon/7472.png',
        id: '14757',
        奇穴描述:
          '“舍身诀”减伤效果提高至50%，若效果期间自身血量低于1%则自身化为舍利子，可被攻击，持续5秒，5秒内若舍利子未被打破则回复自身20%气血最大值与20%内力最大值，若打破则死亡。',
      },
      {
        奇穴名称: '众嗔',
        奇穴图片: 'https://icon.jx3box.com/icon/2972.png',
        id: '6596',
        奇穴描述: '施展“守缺式”、“韦陀献杵”、“拿云式”命中“横扫六合”持续伤害的目标伤害提高20%。',
      },
      {
        奇穴名称: '菩提身',
        奇穴图片: 'https://icon.jx3box.com/icon/11886.png',
        id: '6580',
        奇穴描述: '“擒龙诀”期间，禅那值上限提升到6个，每有一个禅那点，每1.5秒恢复0.35%气血最大值。',
      },
      {
        奇穴名称: '尘息',
        奇穴图片: 'https://icon.jx3box.com/icon/437.png',
        id: '16886',
        奇穴描述: '“守缺式”使目标被疗伤效果减低25%，持续12秒，可叠加2层。',
      },
      {
        奇穴名称: '诸行',
        奇穴图片: 'https://icon.jx3box.com/icon/11885.png',
        id: '24885',
        奇穴描述:
          '“千斤坠”施展后使得接下来5次跳跃，落地短暂延迟后将会对6尺范围最多5个目标造成点阳性内功伤害。跳跃在空中时间越长，下次下落伤害越高，范围越大。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '善护',
        奇穴图片: 'https://icon.jx3box.com/icon/4553.png',
        id: '5909',
        奇穴描述: '当气血值低于70%时被攻击则获得“善护”气劲，使自身受到伤害降低20%，持续5秒。',
      },
      {
        奇穴名称: '正念',
        奇穴图片: 'https://icon.jx3box.com/icon/23475.png',
        id: '38623',
        奇穴描述:
          '“擒龙诀”期间获得“正念”效果：使自身移动速度降低30%，无法受到其它减速效果影响。“罗汉棍法”距离增加2尺且伤害提高40%，“捉影式”前两次施展无调息。',
      },
      {
        奇穴名称: '华香',
        奇穴图片: 'https://icon.jx3box.com/icon/4526.png',
        id: '38957',
        奇穴描述: '“伏魔”状态下造成伤害无视目标60%内功防御。',
      },
      {
        奇穴名称: '轮回诀',
        奇穴图片: 'https://icon.jx3box.com/icon/444.png',
        id: '259',
        奇穴描述:
          '重伤倒地后方可施展，救治自身并使自身恢复15%(点)气血值最大值与15%(点)内力最大值，重伤起身后4秒内受到的伤害降低80%。',
      },
      {
        奇穴名称: '无执',
        奇穴图片: 'https://icon.jx3box.com/icon/1508.png',
        id: '32647',
        奇穴描述:
          '战斗中无法使用武器、无法运功效果期间每秒获得获得1秒“宿缘”效果，自身移动速度提高20%且免疫减速，“龙爪功”套路招式公共调息时间降低至1秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '明澈',
        奇穴图片: 'https://icon.jx3box.com/icon/11889.png',
        id: '21697',
        奇穴描述:
          '施展“擒龙诀”，自身“禅意”将守护自身，“禅意”会每1秒对10尺范围的一个目标造成阳性内功伤害，并会优先以攻击自身的目标作为对象。且当“禅意”和自身攻击同一个对象时，“禅意”的该次攻击会变成4尺范围的伤害和减速效果。“禅意”持续10秒。',
      },
      {
        奇穴名称: '五识',
        奇穴图片: 'https://icon.jx3box.com/icon/7432.png',
        id: '14752',
        奇穴描述: '“罗汉金身”反弹效果提高50%，效果期间受到伤害有10%几率使自身回复5%气血最大值。',
      },
      {
        奇穴名称: '缘觉',
        奇穴图片: 'https://icon.jx3box.com/icon/11884.png',
        id: '21703',
        奇穴描述: '消耗三点禅那施展“拿云式”，施展距离提高至15尺并附带冲刺效果，造成伤害提高20%。',
      },
      {
        奇穴名称: '金刚日轮',
        奇穴图片: 'https://icon.jx3box.com/icon/7459.png',
        id: '32648',
        奇穴描述: '在“千斤坠”产生的“金刚日轮阵”中，施展“龙爪功”套路伤害招式附带一次伤害。',
      },
      {
        奇穴名称: '趺坐',
        奇穴图片: 'https://icon.jx3box.com/icon/14123.png',
        id: '25063',
        奇穴描述:
          '“罗汉金身”效果持续期间，自身被外功伤害命中时，会心几率提高1%，御劲提高1%，持续6秒，最多叠加10层。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '无诤',
        奇穴图片: 'https://icon.jx3box.com/icon/18297.png',
        id: '32649',
        奇穴描述:
          '“横扫六合”调息时间增加至20秒。施展“横扫六合”后重置“守缺式”调息时间，下次“守缺式”释放距离提高至15尺并附带冲刺效果，期间使棍子围绕自身旋转，持续9秒。期间对周围四尺最多5个目标每秒造成阳性内功伤害，无法使用武器，施展“守缺式”后激活“拿云式”。可施展二段招式结束无法使用武器状态。',
      },
      {
        奇穴名称: '心诤',
        奇穴图片: 'https://icon.jx3box.com/icon/16213.png',
        id: '29191',
        奇穴描述:
          '持续舞棍3秒，过程免疫全部控制效果。持续左右交替挥舞手中棍棒六次，每次舞棍都将提高自身15%阳性会心率，对6尺180度范围内最多5个目标造成中等伤害，每次造成伤害自身获得1层“讼言”，最多30层。每层“讼言”提高“心诤·扫击”3%造成的伤害。舞棍结束后打出一次12尺范围终结扫击并消耗全部“讼言”。',
      },
      {
        奇穴名称: '普度临凡',
        奇穴图片: 'https://icon.jx3box.com/icon/16210.png',
        id: '37372',
        奇穴描述:
          '向15尺内目标点方向丢出一尊佛像，并激活二段招式“朝参暮礼”：对目标施展后将目标周围6尺范围内距离佛像不超过20尺的最多5个目标拉向佛像。',
      },
      {
        奇穴名称: '业因',
        奇穴图片: 'https://icon.jx3box.com/icon/17176.png',
        id: '32651',
        奇穴描述:
          '“二业依缘”调息时间调整为60秒。切换“袈裟”状态后，下一次施展“守缺式”“横扫六合”无调息，施展后重置“二业依缘”调息时间，并记录为“业因”。切换“伏魔”时将“业因”转化为4层“果报”。每次施展“韦陀献杵”“拿云式”时消耗一层“果报”并且额外回复一点禅那，“业因”为“守缺式”则招式对非侠士目标造成额外伤害，“业因”为“横扫六合”时招式对非侠士目标造成范围伤害。',
      },
      {
        奇穴名称: '醍醐灌顶',
        奇穴图片: 'https://icon.jx3box.com/icon/13423.png',
        id: '24819',
        奇穴描述:
          '回复1点“禅那”，免疫控制效果1.5秒，降低自身“擒龙诀”20%调息时间。自身和禅灵都挥棍对自身前方12尺范围最多5个目标造成阳性内功伤害，伤害命中目标使其眩晕2秒并降低自身“千斤坠”1.5秒调息时间。',
      },
    ],
  },
]

export default 奇穴数据
