// 参考魔盒计算装分js

import { 镶嵌孔数组类型 } from '@/@types/装备'
import { 客户端类型 } from '../type'
import { 系数_A, 系数_B } from './系数'

/**
 * 获取五行石分数
 * 老算法:
 * 大于 5 级 则是 (五行石等级 * (五行石等级 - 1) * 0.025 + 0.15) * 系数A * 系数B
 * 小于等于 5 级 则是系数A * 系数B * 五行石等级 * 0.15
 * 新算法:
 * @param {*} 等级 （五行石等级）
 * @param {*} 客户端 （客户端版本）
 */
export function 获取五行石分数(等级, 客户端: 客户端类型 = '旗舰') {
  let 分数 = 0
  if (客户端 === '旗舰') {
    // 重制1.0.0.5389修改镶嵌分数公式
    if (等级 > 6) {
      分数 = (等级 * 0.65 - 3.2) * 1.3 * 系数_A[客户端] * 系数_B[客户端]
    } else {
      分数 = 等级 * 1.3 * 0.15 * 系数_A[客户端] * 系数_B[客户端]
    }
  } else {
    // 缘起2024不知道什么版本血战天策 系数修改
    if (等级 > 6) {
      分数 = (等级 * 0.55 - 2.48) * 系数_A[客户端] * 4.25
    } else {
      分数 = 等级 * 系数_A[客户端] * 0.15 * 4.25
    }
  }
  return 分数
}

export function 获取镶嵌列表分数(镶嵌孔数组: 镶嵌孔数组类型[]) {
  let 镶嵌总分 = 0
  镶嵌孔数组.forEach((镶嵌) => {
    const 当前镶嵌分数 = 获取五行石分数(镶嵌?.镶嵌宝石等级)
    if (当前镶嵌分数) {
      镶嵌总分 += 当前镶嵌分数
    }
  })
  return Math.round(镶嵌总分)
}
