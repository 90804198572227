import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 护腕装备数据: 装备属性信息模型[] = [
  {
    id: 103764,
    uid: 103764,
    装备名称: '陶然意·拥狸护手',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103763,
    uid: 103763,
    装备名称: '陶然意·万仞护手',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103760,
    uid: 103760,
    装备名称: '陶然意·徇节护手',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103759,
    uid: 103759,
    装备名称: '陶然意·临溟护手',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103758,
    uid: 103758,
    装备名称: '陶然意·上造护手',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103756,
    uid: 103756,
    装备名称: '陶然意·征惯护手',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103754,
    uid: 103754,
    装备名称: '陶然意·春瓮护手',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103753,
    uid: 103753,
    装备名称: '陶然意·吴陵护手',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103752,
    uid: 103752,
    装备名称: '陶然意·穿心护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103748,
    uid: 103748,
    装备名称: '陶然意·蜕羽护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103746,
    uid: 103746,
    装备名称: '陶然意·登锋护手',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 766 },
      { 属性: 属性类型.外功基础攻击, 值: 1248 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101593,
    uid: 101593,
    装备名称: '花永袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.身法, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101592,
    uid: 101592,
    装备名称: '花世袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.力道, 值: 825 },
      { 属性: 属性类型.外功基础攻击, 值: 1873 },
      { 属性: 属性类型.外功破防等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 104336,
    uid: 104336,
    装备名称: '锋权护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2459 },
      { 属性: 属性类型.破招值, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 6786 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101647,
    uid: 101647,
    装备名称: '客行江湖·泉葩护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101646,
    uid: 101646,
    装备名称: '客行江湖·冥绝护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101581,
    uid: 101581,
    装备名称: '孤飞护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 3443 },
      { 属性: 属性类型.加速等级, 值: 7465 },
      { 属性: 属性类型.无双等级, 值: 6560 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101580,
    uid: 101580,
    装备名称: '攘袂护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2951 },
      { 属性: 属性类型.外功会心等级, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101579,
    uid: 101579,
    装备名称: '紫垣护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 2951 },
      { 属性: 属性类型.外功破防等级, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101578,
    uid: 101578,
    装备名称: '化鹤护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.外功基础攻击, 值: 3566 },
      { 属性: 属性类型.外功破防等级, 值: 4524 },
      { 属性: 属性类型.破招值, 值: 4524 },
      { 属性: 属性类型.无双等级, 值: 4524 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101527,
    uid: 101527,
    装备名称: '羡雁护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101526,
    uid: 101526,
    装备名称: '君深护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101491,
    uid: 101491,
    装备名称: '留诗护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101490,
    uid: 101490,
    装备名称: '清月护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101362,
    uid: 101362,
    装备名称: '孤漠·阑影护手',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101361,
    uid: 101361,
    装备名称: '孤漠·苍雨护手',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101357,
    uid: 101357,
    装备名称: '孤漠·狂澜护手',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101356,
    uid: 101356,
    装备名称: '孤漠·舟帆护手',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101355,
    uid: 101355,
    装备名称: '孤漠·庭月护手',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101351,
    uid: 101351,
    装备名称: '孤漠·雁际护手',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101348,
    uid: 101348,
    装备名称: '孤漠·泛浪护手',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101347,
    uid: 101347,
    装备名称: '孤漠·寒鸣护手',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101346,
    uid: 101346,
    装备名称: '孤漠·照江护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101340,
    uid: 101340,
    装备名称: '孤漠·立霄护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101337,
    uid: 101337,
    装备名称: '孤漠·踏沙护手',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.破招值, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101240,
    uid: 101240,
    装备名称: '漠名护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.身法, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101239,
    uid: 101239,
    装备名称: '漠若护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.力道, 值: 812 },
      { 属性: 属性类型.外功基础攻击, 值: 1844 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 103602,
    uid: 103602,
    装备名称: '陶然意·拥狸护手',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103601,
    uid: 103601,
    装备名称: '陶然意·万仞护手',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103598,
    uid: 103598,
    装备名称: '陶然意·徇节护手',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103597,
    uid: 103597,
    装备名称: '陶然意·临溟护手',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103596,
    uid: 103596,
    装备名称: '陶然意·上造护手',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103594,
    uid: 103594,
    装备名称: '陶然意·征惯护手',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103592,
    uid: 103592,
    装备名称: '陶然意·春瓮护手',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103591,
    uid: 103591,
    装备名称: '陶然意·吴陵护手',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103590,
    uid: 103590,
    装备名称: '陶然意·穿心护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103586,
    uid: 103586,
    装备名称: '陶然意·蜕羽护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103584,
    uid: 103584,
    装备名称: '陶然意·登锋护手',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1073 },
      { 属性: 属性类型.外功破防等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 103489,
    uid: 103489,
    装备名称: '孤山寒月·逐刃护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 103488,
    uid: 103488,
    装备名称: '孤山寒月·归期护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101629,
    uid: 101629,
    装备名称: '客行江湖·烟景护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101628,
    uid: 101628,
    装备名称: '客行江湖·秋序护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101198,
    uid: 101198,
    装备名称: '扬明护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101197,
    uid: 101197,
    装备名称: '重悬护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.外功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101162,
    uid: 101162,
    装备名称: '静言护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101161,
    uid: 101161,
    装备名称: '棣仪护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101033,
    uid: 101033,
    装备名称: '西塞·离巢护手',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101032,
    uid: 101032,
    装备名称: '西塞·秋星护手',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101028,
    uid: 101028,
    装备名称: '西塞·麟台护手',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101027,
    uid: 101027,
    装备名称: '西塞·川冥护手',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101026,
    uid: 101026,
    装备名称: '西塞·流电护手',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101022,
    uid: 101022,
    装备名称: '西塞·行歌护手',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101019,
    uid: 101019,
    装备名称: '西塞·平塘护手',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101018,
    uid: 101018,
    装备名称: '西塞·赤眉护手',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101017,
    uid: 101017,
    装备名称: '西塞·江鸥护手',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101011,
    uid: 101011,
    装备名称: '西塞·冷辉护手',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101008,
    uid: 101008,
    装备名称: '西塞·壮志护手',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100977,
    uid: 100977,
    装备名称: '寻踪觅宝·方思袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.身法, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100976,
    uid: 100976,
    装备名称: '寻踪觅宝·汉广袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.力道, 值: 705 },
      { 属性: 属性类型.外功基础攻击, 值: 1602 },
      { 属性: 属性类型.破招值, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100947,
    uid: 100947,
    装备名称: '寻踪觅宝·迭微袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100946,
    uid: 100946,
    装备名称: '寻踪觅宝·予吉袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100917,
    uid: 100917,
    装备名称: '壁明袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.身法, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100916,
    uid: 100916,
    装备名称: '壁邺袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.力道, 值: 658 },
      { 属性: 属性类型.外功基础攻击, 值: 1495 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100785,
    uid: 100785,
    装备名称: '语岳护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.身法, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.外功会心等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100784,
    uid: 100784,
    装备名称: '语越护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.力道, 值: 649 },
      { 属性: 属性类型.外功基础攻击, 值: 1474 },
      { 属性: 属性类型.外功会心等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104503,
    uid: 104503,
    装备名称: '润源袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104502,
    uid: 104502,
    装备名称: '辞羁袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104457,
    uid: 104457,
    装备名称: '羽缀护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 104456,
    uid: 104456,
    装备名称: '夏凌护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 104114,
    uid: 104114,
    装备名称: '平皋袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 104113,
    uid: 104113,
    装备名称: '淮沛袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100881,
    uid: 100881,
    装备名称: '润源袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100880,
    uid: 100880,
    装备名称: '辞羁袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100845,
    uid: 100845,
    装备名称: '平皋袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100844,
    uid: 100844,
    装备名称: '淮沛袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.外功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100809,
    uid: 100809,
    装备名称: '羽缀护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100808,
    uid: 100808,
    装备名称: '夏凌护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 643 },
      { 属性: 属性类型.外功基础攻击, 值: 1460 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100677,
    uid: 100677,
    装备名称: '镂云袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100676,
    uid: 100676,
    装备名称: '炎翳袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.外功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100641,
    uid: 100641,
    装备名称: '智渊袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.身法, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100640,
    uid: 100640,
    装备名称: '韶曦袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.力道, 值: 597 },
      { 属性: 属性类型.外功基础攻击, 值: 973 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100761,
    uid: 100761,
    装备名称: '语岳护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.身法, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.外功会心等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100760,
    uid: 100760,
    装备名称: '语越护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.力道, 值: 608 },
      { 属性: 属性类型.外功基础攻击, 值: 1381 },
      { 属性: 属性类型.外功会心等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100605,
    uid: 100605,
    装备名称: '修篁护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.身法, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.加速等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100604,
    uid: 100604,
    装备名称: '绮陌护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.力道, 值: 589 },
      { 属性: 属性类型.外功基础攻击, 值: 1339 },
      { 属性: 属性类型.加速等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100737,
    uid: 100737,
    装备名称: '语岳护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.身法, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.外功会心等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100736,
    uid: 100736,
    装备名称: '语越护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.力道, 值: 567 },
      { 属性: 属性类型.外功基础攻击, 值: 1289 },
      { 属性: 属性类型.外功会心等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100569,
    uid: 100569,
    装备名称: '涵煦袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100568,
    uid: 100568,
    装备名称: '惜霜袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100713,
    uid: 100713,
    装备名称: '语岳护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.身法, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.外功会心等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100712,
    uid: 100712,
    装备名称: '语越护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.力道, 值: 527 },
      { 属性: 属性类型.外功基础攻击, 值: 1196 },
      { 属性: 属性类型.外功会心等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101833,
    uid: 101833,
    装备名称: '春齐袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101832,
    uid: 101832,
    装备名称: '曲汜袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 1175 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100533,
    uid: 100533,
    装备名称: '嵘嶂护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.身法, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100532,
    uid: 100532,
    装备名称: '湛影护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.力道, 值: 480 },
      { 属性: 属性类型.外功基础攻击, 值: 783 },
      { 属性: 属性类型.外功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
]

export default 护腕装备数据
