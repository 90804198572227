import { 团队增益选项数据类型, 增益选项数据类型 } from '@/@types/团队增益'
import { 属性加成, 属性类型 } from '@/@types/属性'
import { 装备信息数据类型, 装备增益类型 } from '@/@types/装备'
import { 角色基础属性类型 } from '@/@types/角色'
import { 计算增益数据中加速值 } from '@/工具函数/data'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { 计算增益加成后最终计算属性 } from '@/计算模块/循环秒伤计算'
import { 根据增益选项获取增益集合 } from '@/计算模块/统一工具函数/增益计算函数'
import { 获取属性加成后的面板 } from '@/计算模块/郭氏计算/郭氏技能总伤害计算'

const { 技能增益, 装备增益数据, 团队增益: 团队增益数据 } = 获取当前数据()

interface 获取角色需要展示的面板数据入参 {
  装备信息: 装备信息数据类型
  当前奇穴信息: string[]
  增益数据: 增益选项数据类型
  增益启用: boolean
  显示增益后面板: boolean
}

export const 获取角色需要展示的面板数据 = (
  params: 获取角色需要展示的面板数据入参
): 角色基础属性类型 => {
  const { 装备信息, 当前奇穴信息, 增益数据, 增益启用, 显示增益后面板 } = params
  let 角色面板 = 装备信息?.装备基础属性
  let 全部增益: 属性加成[] = []
  const 奇穴增益: 属性加成[] = 获取奇穴加成(当前奇穴信息)
  // 显示秀气、雷等常用增益
  let 小药阵眼团队增益: 属性加成[] = []
  // 装备增益数据（例五彩石百分比郭氏加成
  let 装备属性增益: 属性加成[] = []

  if (装备信息?.装备增益) {
    const { 角色面板: 增益角色面板, 装备属性增益: 增益装备属性增益 = [] } = 获取装备加成后面板(
      角色面板,
      装备信息?.装备增益
    )
    角色面板 = 增益角色面板
    装备属性增益 = 增益装备属性增益
  }

  if (增益启用) {
    if (显示增益后面板) {
      小药阵眼团队增益 = 根据增益选项获取增益集合(
        {
          小吃: 增益数据?.小吃,
          阵眼: 增益数据?.阵眼,
          团队增益: 筛选出团队增益中的常用增益(增益数据?.团队增益),
        },
        true
      )
    } else {
      角色面板 = {
        ...角色面板,
        加速等级: 角色面板.加速等级 + 计算增益数据中加速值(增益数据),
      }
    }
  }

  全部增益 = 全部增益
    .concat(奇穴增益 || [])
    .concat(小药阵眼团队增益 || [])
    .concat(装备属性增益 || [])

  const 增益加成后最终计算属性 = 计算增益加成后最终计算属性({
    装备基础属性: 角色面板,
    增益集合: 全部增益,
    当前目标: {} as any,
  })

  const 属性加成后面板 = 获取属性加成后的面板(增益加成后最终计算属性, true)

  return 属性加成后面板
}

// 这里主要计算百分比奇穴加成
export const 获取奇穴加成 = (当前奇穴信息) => {
  let 奇穴加成: 属性加成[] = []
  if (技能增益?.通用增益?.length) {
    技能增益?.通用增益.forEach((增益) => {
      if (
        增益?.增益所在位置 === '奇穴' &&
        当前奇穴信息?.includes(增益?.增益名称) &&
        增益?.是否常驻面板
      ) {
        奇穴加成 = 奇穴加成.concat(增益.增益集合 || [])
      }
    })
  }
  return 奇穴加成
}

const 显示在面板团队增益名称 = [
  '禅语',
  '瘦肉粽',
  '瓜仁粽',
  '瑰栗粽',
  '芝麻杆',
  '蛋黄粽',
  '云片糕',
  '芦兜粽',
  '酣畅淋漓',
  '振奋',
  '配伍',
  '左旋右转',
  '百炼水煮鱼',
  '炼狱水煮鱼',
]

export const 筛选出团队增益中的常用增益 = (团队增益: 团队增益选项数据类型[]) => {
  团队增益数据
  return 团队增益.filter((item) => {
    const data = 团队增益数据.find((a) => a.增益名称 === item.增益名称)
    return (
      data?.团队增益类型 === '常用增益' ||
      (data?.增益名称 && 显示在面板团队增益名称?.includes(data?.增益名称))
    )
  })
}

// 获取装备加成后面板
const 获取装备加成后面板 = (
  初始面板: 角色基础属性类型,
  装备增益: 装备增益类型
): { 角色面板: 角色基础属性类型; 装备属性增益: 属性加成[] } => {
  const 面板 = { ...初始面板 }
  const 增益: 属性加成[] = []
  if (装备增益?.切糕会心) {
    面板.会心等级 = 面板.会心等级 + 装备增益数据?.切糕会心?.[0]?.值
  }
  if (装备增益?.切糕会心_英雄) {
    面板.会心等级 = 面板.会心等级 + 装备增益数据?.切糕会心_英雄?.[0]?.值
  }
  if (装备增益?.切糕无双) {
    面板.无双等级 = 面板.无双等级 + 装备增益数据?.切糕无双?.[0]?.值
  }
  if (装备增益?.切糕无双_英雄) {
    面板.无双等级 = 面板.无双等级 + 装备增益数据?.切糕无双_英雄?.[0]?.值
  }
  if (装备增益?.大附魔_伤帽) {
    if (装备增益?.大附魔_伤帽 >= 2) {
      面板.破防等级 = 面板.破防等级 + 装备增益数据?.大附魔_伤帽_英雄?.[0]?.值
    } else {
      面板.破防等级 = 面板.破防等级 + 装备增益数据?.大附魔_伤帽?.[0]?.值
    }
  }
  if (装备增益?.大附魔_伤衣) {
    if (装备增益?.大附魔_伤衣 >= 2) {
      面板.基础攻击 = 面板.基础攻击 + 装备增益数据?.大附魔_伤衣_英雄?.[0]?.值
      面板.面板攻击 = (面板.面板攻击 || 0) + 装备增益数据?.大附魔_伤衣_英雄?.[0]?.值
    } else {
      面板.基础攻击 = 面板.基础攻击 + 装备增益数据?.大附魔_伤衣?.[0]?.值
      面板.面板攻击 = (面板.面板攻击 || 0) + 装备增益数据?.大附魔_伤衣?.[0]?.值
    }
  }
  if (装备增益?.冬至套装) {
    面板.力道 = 面板.力道 + 装备增益数据?.冬至套装?.[0]?.值
    面板.身法 = 面板.身法 + 装备增益数据?.冬至套装?.[0]?.值
    面板.根骨 = 面板.根骨 + 装备增益数据?.冬至套装?.[0]?.值
    面板.元气 = 面板.元气 + 装备增益数据?.冬至套装?.[0]?.值
    面板.体质 = 面板.体质 + 装备增益数据?.冬至套装?.[0]?.值
    面板.会心等级 = (面板.会心等级 || 0) + 装备增益数据?.冬至套装?.[1]?.值
  }

  if (装备增益?.郭氏元气) {
    增益.push({ 属性: 属性类型.郭氏元气, 值: 装备增益?.郭氏元气 })
  }
  if (装备增益?.郭氏力道) {
    增益.push({ 属性: 属性类型.郭氏力道, 值: 装备增益?.郭氏力道 })
  }
  if (装备增益?.郭氏身法) {
    增益.push({ 属性: 属性类型.郭氏身法, 值: 装备增益?.郭氏身法 })
  }
  if (装备增益?.郭氏根骨) {
    增益.push({ 属性: 属性类型.郭氏根骨, 值: 装备增益?.郭氏根骨 })
  }

  return { 角色面板: 面板, 装备属性增益: 增益 }
}
