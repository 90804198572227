import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 鸣锋每层基础伤害 = 0.13

const 鸣锋增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '鸣锋_流血_1',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 1 }],
  },
  {
    增益名称: '鸣锋_流血_2',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 2 }],
  },
  {
    增益名称: '鸣锋_流血_3',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 3 }],
  },
  {
    增益名称: '鸣锋_流血_4',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 4 }],
  },
  {
    增益名称: '鸣锋_流血_5',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 5 }],
  },
  {
    增益名称: '鸣锋_流血_6',
    依赖奇穴: '鸣锋',
    增益类型: '部分启用',
    增益所在位置: '奇穴',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 鸣锋每层基础伤害 * 6 }],
  },
]

export default 鸣锋增益
