import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 悬象著明增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    // 25759
    增益名称: '明光·日',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 246 }],
  },
  {
    // 25758
    增益名称: '明光·月',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 246 }],
  },
]

export default 悬象著明增益
