import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// import 测试 from './测试.json'
import 承磊 from './承磊.json'
import 威声 from './威声.json'
import 击懈 from './击懈.json'
import 橙武 from './橙武.json'

const 计算循环: 循环数据[] = [承磊, 威声, 击懈, 橙武] as 循环数据[]

export default 计算循环
