import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用增益'

const 百足增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '10%伤害_1',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.1 }],
  },
  {
    增益名称: '10%伤害_2',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.1 }],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.05 }],
  },
  {
    增益名称: '减CD_1',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
  {
    增益名称: '减CD_2',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
]

export default 百足增益
