import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用增益'
import { 按数字生成数组 } from '@/工具函数/help'
import 门派套装增益 from './通用增益/门派套装增益'

// 9052
const 绝返函数 = () => {
  const 数组 = 按数字生成数组(9)
  const list = [1.2, 1.4, 1.6, 1.8, 1.2, 1.4, 1.6, 1.8, 2.2]
  return 数组.map((item) => {
    return {
      增益名称: `绝返·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '绝返',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.系数增伤, 值: list[item - 1] }],
    } as 技能增益列表类型
  })
}

const 绝刀增益: 技能增益列表类型[] = [
  ...通用增益,
  ...绝返函数(),
  ...门派套装增益,
  {
    // 8474 CWbuff，无消耗且增伤
    增益名称: '致远',
    增益所在位置: '装备',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 410 / 1024 }],
  },
  {
    增益名称: '分野',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.15 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 200 },
    ],
  },
  {
    // 9889
    增益名称: '刀煞',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.03 }],
  },
]

export default 绝刀增益
