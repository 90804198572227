import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'

import 通用增益 from './技能增益/通用增益/通用增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 兵主逆增益 from './技能增益/兵主逆'
import 三星临增益 from './技能增益/三星临'
import 列宿游增益 from './技能增益/列宿游'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '102247',
    技能名称: '飞宫·悟·撞击',
    统计名称: '飞宫·悟',
    技能伤害系数: 373,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102249',
    技能名称: '飞宫·悟·连撞',
    统计名称: '飞宫·悟',
    技能伤害系数: 392,
    技能破招系数: 1249 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102238',
    技能名称: '奇门飞宫·悟',
    技能伤害系数: 24,
    技能破招系数: 72 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102248',
    技能名称: '列宿游·悟',
    技能伤害系数: 1542,
    基础伤害_基础值: 1,
    技能增益列表: 列宿游增益,
  },
  {
    技能ID: '102205',
    技能名称: '辰星·悟',
    技能伤害系数: 116,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '101481',
    技能名称: '兵主逆·悟',
    技能伤害系数: 444,
    技能破招系数: 1423 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 兵主逆增益,
  },
  {
    技能ID: '101466',
    技能名称: '三星临·悟',
    技能伤害系数: 275,
    技能破招系数: 1434 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 三星临增益,
  },
  {
    技能ID: '101497',
    技能名称: '杀星在尾·悟',
    技能伤害系数: 183,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  // --------------
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
