/**
 * @name 心法模块-凌海诀
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 默认数据 from './默认数据'
import 计算循环 from './计算循环'
import 奇穴数据 from './奇穴'
import 技能系数, { 技能增益 } from './技能系数'
import 端游凌海诀数据 from '../凌海诀/index'

const 心法配置: 心法配置类型 = {
  ...端游凌海诀数据,
  名称: '凌海诀_悟',
  简写: 'w_lhj',
  数据提供: '数据：考拉；测试：会灰 方小皮',
  所属门派: '蓬莱',
  心法所属端: '无界',
  主属性: '身法',
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  覆盖率: { 套装会心会效: 0.7 },
  系统配置: {
    ...(端游凌海诀数据.系统配置 || {}),
    心法图标: 'https://icon.jx3box.com/icon/101090.png',
  },
}

export default 心法配置
