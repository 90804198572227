import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

const 蛊增益: 技能增益列表类型[] = [
  {
    // 25769
    增益名称: '重蛊',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 154 / 1024 }],
  },
]

export default 蛊增益
