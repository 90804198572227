import { 装备部位枚举 } from '@/@types/枚举'
import { 属性类型 } from '@/@types/属性'
import { 附魔数据类型 } from '@/@types/附魔'

const 内功攻击附魔数组 = [633, 316]
const 外功攻击附魔数组 = [568, 284]

// 按顺序位置索引
const 附魔分数索引 = [2282, 1139]

export const 获取攻击附魔数据 = (功法?): 附魔数据类型[] => {
  const 基础数组 = 功法 === '外功' ? 外功攻击附魔数组 : 内功攻击附魔数组
  return 基础数组.map((item, index) => {
    return {
      附魔名称: `攻击+${item}`,
      附魔支持部位: [装备部位枚举.帽子, 装备部位枚举.鞋子, 装备部位枚举.戒指, 装备部位枚举.武器],
      增益集合: [{ 属性: 属性类型.全基础攻击, 值: item }],
      附魔装分: 附魔分数索引[index],
    }
  })
}
