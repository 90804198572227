import { 获取主属性附魔数据 } from './主属性附魔'
import { 获取攻击附魔数据 } from './攻击附魔'
import { 获取衣服腰带节日附魔数据 } from './衣服腰带节日附魔'
import { 获取通用附魔数据, 通用数值属性枚举 } from './通用数值附魔'
import { 获取武伤附魔数据 } from './武器伤害附魔'
import { 获取体质附魔数据 } from './体质附魔'

export const 获取附魔数据 = (功法?, 主属性?) => {
  let 数据 = [
    ...获取主属性附魔数据(主属性),
    ...获取攻击附魔数据(功法),
    ...获取武伤附魔数据(主属性),
    ...获取衣服腰带节日附魔数据(),
    ...获取体质附魔数据(),
  ]

  Object.keys(通用数值属性枚举).forEach((key) => {
    数据 = 数据.concat(获取通用附魔数据(key))
  })

  return 数据
}
