import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用外功增益 from './通用外功'

const 通用武伤增益: 技能增益列表类型[] = [
  ...通用外功增益,
  {
    增益名称: '通用武伤',
    增益所在位置: '技能',
    增益类型: '全局启用',
    增益启用: true,
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: -1024 }],
  },
]

export default 通用武伤增益
