import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 断云势增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '镇机_破绽_1',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 123 / 1024 }],
    // 秘籍ID 3014
  },
  {
    增益名称: '镇机_破绽_2',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 246 / 1024 }],
    // 秘籍ID 3016
  },
  {
    增益名称: '镇机_破绽_3',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 369 / 1024 }],
    // 秘籍ID 3017
  },
  {
    增益名称: '镇机_破绽_4',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 492 / 1024 }],
    // 秘籍ID 3018
  },
  {
    增益名称: '镇机_破绽_5',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 614 / 1024 }],
    // 秘籍ID 3028
  },
  {
    增益名称: '镇机_破绽_6',
    依赖奇穴: '镇机',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 737 / 1024 }],
    // 秘籍ID 3029
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.05 }],
  },
]

export default 断云势增益
