import { 目标集合 } from '@/数据/目标'
import { 计算公式计算类型 } from '../常量'
import { 角色基础属性类型 } from '@/@types/角色'

export const 判断伤害类型 = (计算类型: 计算公式计算类型, 伤害计算类型标记: 计算公式计算类型[]) => {
  return 伤害计算类型标记?.includes(计算类型)
}

export const 获取计算目标信息 = (目标名称) => {
  return 目标集合?.find((item) => item?.名称 === 目标名称) || 目标集合[0]
}

// 调用该方法需要保证在调用方法前，面板的无双和破招属性没有受到全能的影响
export const 获取全能加成面板 = (当前角色面板: 角色基础属性类型): 角色基础属性类型 => {
  return {
    ...当前角色面板,
    破招值: 破招全能值加成(当前角色面板.破招值, 当前角色面板.全能等级),
    无双等级: 无双全能值加成(当前角色面板.无双等级, 当前角色面板.全能等级),
  }
}

export const 破招全能值加成 = (原值, 全能等级) => {
  return (原值 || 0) + Math.floor((全能等级 || 0) * 0.5)
}

export const 无双全能值加成 = (原值, 全能等级) => {
  return (原值 || 0) + Math.floor((全能等级 || 0) * 1.5)
}
