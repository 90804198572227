import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试 from './测试.json'
// import 丛云_命俦 from './丛云_命俦.json'
import 丛云_卢令 from './丛云_卢令.json'
import 考古_卢令 from './考古_卢令.json'
import 朝仪_橙武 from './朝仪_橙武.json'

const 计算循环: 循环数据[] = [丛云_卢令, 考古_卢令, 朝仪_橙武] as 循环数据[]

export default 计算循环
