import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用增益'
import 蛊增益 from './通用增益/蛊增益'

const 连缘蛊增益: 技能增益列表类型[] = [
  ...通用增益,
  ...蛊增益,
  {
    // 19513
    增益名称: '连缘蛊·1',
    增益所在位置: '职业',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 819 / 1024 }],
  },
  {
    // 19513
    增益名称: '连缘蛊·2',
    增益所在位置: '职业',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 1638 / 1024 }],
  },
  {
    // 19513
    增益名称: '连缘蛊·3',
    增益所在位置: '职业',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 2458 / 1024 }],
  },
  {
    // 19513
    增益名称: '连缘蛊·4',
    增益所在位置: '职业',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 3277 / 1024 }],
  },
  // {
  //   增益名称: '连缘蛊非侠',
  //   增益所在位置: '职业',
  //   增益启用: true,
  //   增益类型: '全局启用',
  //   增益集合: [{ 属性: 属性类型.非侠增伤, 值: 256 / 1024 }],
  // },
]

export default 连缘蛊增益
