import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用增益'
import 唤兽招式增益 from './唤兽招式增益'
import 对阵招式增益 from './通用增益/对阵招式增益'

const 狼增益: 技能增益列表类型[] = [
  ...通用增益,
  ...唤兽招式增益,
  ...对阵招式增益,
  {
    // 70188
    // 增益名称: '鹰_绝篇', // 秘籍 命中减疗增加
    增益名称: '无界_通用易伤_20',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 205 / 1024 }],
  },
]

export default 狼增益
