import { 技能基础数据模型, 技能增益列表类型 } from '@/@types/技能'
import { 选中秘籍信息 } from '@/@types/秘籍'

/**
 * 根据当前已选的秘籍、奇穴等修改技能增益
 */
export const 根据秘籍格式化技能基础数据 = (
  技能系数: 技能基础数据模型[],
  当前秘籍信息: 选中秘籍信息
): 技能基础数据模型[] => {
  return 技能系数.map((item) => {
    if (item?.技能等级数据) {
      const 技能等级数据 = item?.技能等级数据
      for (const 等级 in 技能等级数据) {
        技能等级数据[等级].技能增益列表 = 获取技能增益判断(
          { ...item, 技能增益列表: 技能等级数据?.[等级].技能增益列表 },
          当前秘籍信息
        )
      }
      return { ...item, 技能增益列表: 获取技能增益判断(item, 当前秘籍信息), 技能等级数据 }
    } else {
      return { ...item, 技能增益列表: 获取技能增益判断(item, 当前秘籍信息) }
    }
  })
}

export const 获取技能增益判断 = (
  skill: 技能基础数据模型,
  当前秘籍信息: 选中秘籍信息
): 技能增益列表类型[] => {
  const 格式化后增益列表 = (skill.技能增益列表 || [])?.map((增益) => {
    if (增益?.增益所在位置 === '秘籍') {
      const 当前技能秘籍选中列表 =
        当前秘籍信息?.[skill?.技能名称] || 当前秘籍信息?.[skill?.秘籍依赖技能 || ''] || []

      return { ...增益, 增益启用: 增益?.增益启用 || 当前技能秘籍选中列表?.includes(增益?.增益名称) }
    } else {
      return 增益
    }
  })

  return 格式化后增益列表
}
