const 缓存映射枚举 = {
  当前方案名称: `{{心法}}_当前方案名称`,
  全部方案数据: `{{心法}}_全部方案数据`,
  // 方案配套
  角色基础属性: `{{心法}}_角色基础属性`,
  装备信息: `{{心法}}_装备信息`,
  增益启用: `{{心法}}_增益启用`,
  增益数据: `{{心法}}_增益数据`,
  当前奇穴信息: `{{心法}}_当前奇穴信息`,
  当前计算循环名称: `{{心法}}_当前计算循环名称`,
  当前秘籍信息: `{{心法}}_当前秘籍信息`,
  // 其他缓存
  当前输出计算目标名称: `{{心法}}_当前输出计算目标名称`,
  自定义循环: `{{心法}}_自定义循环`,
  // 和心法无关的全局通用信息
  团队增益轴: `团队增益轴`,
  网络延迟: `网络延迟`,
  背景图片显示状态: `背景图片显示状态`,
  日志版本: `日志版本`,
  使用说明: `使用说明`,
  新手引导: `新手引导`,
  收藏装备: `收藏装备`,
}

export type 缓存映射类型 = typeof 缓存映射枚举

// 用于保存在浏览器的各属性键值
export const 获取缓存映射 = (心法): 缓存映射类型 => {
  const res = {}
  Object.keys(缓存映射枚举).forEach((key) => {
    res[key] = 缓存映射枚举[key]?.replace(`{{心法}}`, 心法)
  })
  return res as 缓存映射类型
}
