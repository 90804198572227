import { 团队增益轴类型 } from '@/@types/团队增益'
import { 角色基础属性类型 } from '@/@types/角色'
import { 每秒郭氏帧 } from '@/数据/常量'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'

const { 基础属性 = {} } = 获取当前数据()

export const 角色默认基础属性: 角色基础属性类型 = {
  基础攻击: 0,
  破防等级: 0,
  无双等级: 0,
  会心等级: 0,
  会心效果等级: 0,
  全能等级: 0,
  武器伤害_最小值: 0,
  武器伤害_最大值: 0,
  破招值: 0,
  力道: 44,
  元气: 44,
  身法: 44,
  根骨: 44,
  体质: 45,
  // 基础气血上限: 52285,
  基础气血上限: 160878,
  额外气血上限: 0,
  加速等级: 0,
  ...基础属性,
}

export const 默认团队增益轴: 团队增益轴类型 = {
  仙王蛊鼎: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: 120 * 每秒郭氏帧,
    持续时间: 30 * 每秒郭氏帧,
  },
  寒啸千军: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: 30 * 每秒郭氏帧,
    持续时间: 15 * 每秒郭氏帧,
  },
  弘法: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: 40 * 每秒郭氏帧,
    持续时间: 20 * 每秒郭氏帧,
  },
  朝圣: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: 120 * 每秒郭氏帧, // 点月尽天明 + 流沙渡 平均1分半-2分钟一次 按2分钟算
    持续时间: 8 * 每秒郭氏帧,
  },
  圣浴明心: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: 180 * 每秒郭氏帧, // 点月尽天明 平均2分半-3分钟一次 按3分钟算
    持续时间: 8 * 每秒郭氏帧,
  },
  号令三军: {
    是否启用快照: true,
    初次释放时间: 0,
    平均间隔: -1,
    持续时间: 30 * 每秒郭氏帧,
  },
}
