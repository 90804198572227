import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 戒指装备数据: 装备属性信息模型[] = [
  {
    id: 42439,
    uid: 42439,
    装备名称: '共苍茫·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19800,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7549 },
      { 属性: 属性类型.元气, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1452 },
      { 属性: 属性类型.全会心等级, 值: 1887 },
      { 属性: 属性类型.内功破防等级, 值: 1887 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42438,
    uid: 42438,
    装备名称: '共苍茫·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19800,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7549 },
      { 属性: 属性类型.根骨, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1452 },
      { 属性: 属性类型.内功会心等级, 值: 1887 },
      { 属性: 属性类型.内功破防等级, 值: 1887 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42434,
    uid: 42434,
    装备名称: '共苍茫·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17250,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6577 },
      { 属性: 属性类型.元气, 值: 537 },
      { 属性: 属性类型.内功基础攻击, 值: 1265 },
      { 属性: 属性类型.全会心等级, 值: 1644 },
      { 属性: 属性类型.内功破防等级, 值: 1644 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42433,
    uid: 42433,
    装备名称: '共苍茫·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17250,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6577 },
      { 属性: 属性类型.根骨, 值: 537 },
      { 属性: 属性类型.内功基础攻击, 值: 1265 },
      { 属性: 属性类型.内功会心等级, 值: 1644 },
      { 属性: 属性类型.内功破防等级, 值: 1644 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42429,
    uid: 42429,
    装备名称: '共苍茫·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15500,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5909 },
      { 属性: 属性类型.元气, 值: 482 },
      { 属性: 属性类型.内功基础攻击, 值: 1136 },
      { 属性: 属性类型.全会心等级, 值: 1477 },
      { 属性: 属性类型.内功破防等级, 值: 1477 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42428,
    uid: 42428,
    装备名称: '共苍茫·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15500,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5909 },
      { 属性: 属性类型.根骨, 值: 482 },
      { 属性: 属性类型.内功基础攻击, 值: 1136 },
      { 属性: 属性类型.内功会心等级, 值: 1477 },
      { 属性: 属性类型.内功破防等级, 值: 1477 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42589,
    uid: 42589,
    装备名称: '陶然意·月容戒',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42586,
    uid: 42586,
    装备名称: '陶然意·修茂戒',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42585,
    uid: 42585,
    装备名称: '陶然意·理乾戒',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42581,
    uid: 42581,
    装备名称: '陶然意·来仪戒',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42579,
    uid: 42579,
    装备名称: '陶然意·凡圣戒',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42575,
    uid: 42575,
    装备名称: '陶然意·连筠戒',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42574,
    uid: 42574,
    装备名称: '陶然意·蛇言戒',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42573,
    uid: 42573,
    装备名称: '陶然意·寒河戒',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42571,
    uid: 42571,
    装备名称: '陶然意·清静戒',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42569,
    uid: 42569,
    装备名称: '陶然意·簇锦戒',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42568,
    uid: 42568,
    装备名称: '陶然意·祇树戒',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功破防等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42728,
    uid: 42728,
    装备名称: '希叡戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 3125 },
      { 属性: 属性类型.加速等级, 值: 8725 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41430,
    uid: 41430,
    装备名称: '客行江湖·散秋戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41429,
    uid: 41429,
    装备名称: '客行江湖·馀散戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41370,
    uid: 41370,
    装备名称: '砌冷戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2344 },
      { 属性: 属性类型.内功破防等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41369,
    uid: 41369,
    装备名称: '枌榆戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2344 },
      { 属性: 属性类型.全会心等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41368,
    uid: 41368,
    装备名称: '时裁戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2832 },
      { 属性: 属性类型.内功破防等级, 值: 3232 },
      { 属性: 属性类型.破招值, 值: 3232 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41367,
    uid: 41367,
    装备名称: '对酬戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 3320 },
      { 属性: 属性类型.无双等级, 值: 7110 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41356,
    uid: 41356,
    装备名称: '清槐戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41355,
    uid: 41355,
    装备名称: '孟春戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41344,
    uid: 41344,
    装备名称: '青桑戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41343,
    uid: 41343,
    装备名称: '季春戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41326,
    uid: 41326,
    装备名称: '游山指环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41325,
    uid: 41325,
    装备名称: '思玉指环',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41308,
    uid: 41308,
    装备名称: '漠晖戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41307,
    uid: 41307,
    装备名称: '漠安戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42508,
    uid: 42508,
    装备名称: '陶然意·月容戒',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42505,
    uid: 42505,
    装备名称: '陶然意·修茂戒',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42504,
    uid: 42504,
    装备名称: '陶然意·理乾戒',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42500,
    uid: 42500,
    装备名称: '陶然意·来仪戒',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42498,
    uid: 42498,
    装备名称: '陶然意·凡圣戒',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42494,
    uid: 42494,
    装备名称: '陶然意·连筠戒',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42493,
    uid: 42493,
    装备名称: '陶然意·蛇言戒',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42492,
    uid: 42492,
    装备名称: '陶然意·寒河戒',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42490,
    uid: 42490,
    装备名称: '陶然意·清静戒',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42488,
    uid: 42488,
    装备名称: '陶然意·簇锦戒',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42487,
    uid: 42487,
    装备名称: '陶然意·祇树戒',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功破防等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41424,
    uid: 41424,
    装备名称: '客行江湖·自吟戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41423,
    uid: 41423,
    装备名称: '客行江湖·含虚戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41290,
    uid: 41290,
    装备名称: '疏风戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41289,
    uid: 41289,
    装备名称: '锦官戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41278,
    uid: 41278,
    装备名称: '映宵戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41277,
    uid: 41277,
    装备名称: '云眷戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41260,
    uid: 41260,
    装备名称: '江汜戒指',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41259,
    uid: 41259,
    装备名称: '合苏戒指',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41242,
    uid: 41242,
    装备名称: '壁镜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41241,
    uid: 41241,
    装备名称: '壁观戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41176,
    uid: 41176,
    装备名称: '语甘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.全会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41175,
    uid: 41175,
    装备名称: '语罡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42877,
    uid: 42877,
    装备名称: '昭文戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42876,
    uid: 42876,
    装备名称: '丘墟戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42858,
    uid: 42858,
    装备名称: '斛尘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42857,
    uid: 42857,
    装备名称: '熙春戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42633,
    uid: 42633,
    装备名称: '玉凉戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42632,
    uid: 42632,
    装备名称: '樛枝戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41230,
    uid: 41230,
    装备名称: '昭文戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41229,
    uid: 41229,
    装备名称: '丘墟戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41212,
    uid: 41212,
    装备名称: '玉凉戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41211,
    uid: 41211,
    装备名称: '樛枝戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41194,
    uid: 41194,
    装备名称: '斛尘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41193,
    uid: 41193,
    装备名称: '熙春戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41104,
    uid: 41104,
    装备名称: '弥弦戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41103,
    uid: 41103,
    装备名称: '珀音戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41086,
    uid: 41086,
    装备名称: '霖瑜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41085,
    uid: 41085,
    装备名称: '瑾辞戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42840,
    uid: 42840,
    装备名称: '风沙行·长河',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.元气, 值: 452 },
      { 属性: 属性类型.内功基础攻击, 值: 1142 },
      { 属性: 属性类型.破招值, 值: 3781 },
      { 属性: 属性类型.无双等级, 值: 3024 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42839,
    uid: 42839,
    装备名称: '风沙行·沙影',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20200,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.根骨, 值: 452 },
      { 属性: 属性类型.内功基础攻击, 值: 1142 },
      { 属性: 属性类型.破招值, 值: 3781 },
      { 属性: 属性类型.无双等级, 值: 3024 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41158,
    uid: 41158,
    装备名称: '语甘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.元气, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.全会心等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41157,
    uid: 41157,
    装备名称: '语罡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.根骨, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.内功会心等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41068,
    uid: 41068,
    装备名称: '惜晗戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.元气, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41067,
    uid: 41067,
    装备名称: '濯缨戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.根骨, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41140,
    uid: 41140,
    装备名称: '语甘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.元气, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.全会心等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41139,
    uid: 41139,
    装备名称: '语罡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.根骨, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.内功会心等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41050,
    uid: 41050,
    装备名称: '绢素戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.元气, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.加速等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41049,
    uid: 41049,
    装备名称: '烨霖戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.根骨, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.加速等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41122,
    uid: 41122,
    装备名称: '语甘戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.元气, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.全会心等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41121,
    uid: 41121,
    装备名称: '语罡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.根骨, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.内功会心等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41532,
    uid: 41532,
    装备名称: '轻桡戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41531,
    uid: 41531,
    装备名称: '临流戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41032,
    uid: 41032,
    装备名称: '凝玥戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41031,
    uid: 41031,
    装备名称: '静漪戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [],
  },
]

export default 戒指装备数据
