import { 技能基础数据模型 } from '@/@types/技能'

import { 计算公式计算类型 } from '@/@types/伤害计算'
import 通用增益 from './技能增益/通用增益/通用增益'
import 钩吻断肠增益 from './技能增益/钩吻断肠增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: 102159,
    技能名称: '且待时休·悟',
    技能伤害系数: 571,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102157,
    技能名称: '川乌射罔·悟',
    技能伤害系数: 265,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102158,
    技能名称: '沾衣未妨·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 166,
    技能破招系数: 532 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102164,
    技能名称: '并蒂夺株·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 187,
    技能破招系数: 594 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 101417,
    技能名称: '无方中和',
    基础伤害_基础值: 1,
    技能伤害系数: 231,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 71171_101417,
    技能名称: '中毒(DOT)',
    统计名称: '中毒(DOT)',
    基础伤害_基础值: 1,
    技能伤害系数: 461,
    DOT生效间隔: 16,
    DOT跳数: 6,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 102163,
    技能名称: '青圃着尘·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 323,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 101357,
    技能名称: '钩吻断肠·悟·普通',
    统计名称: '钩吻断肠·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 252,
    技能增益列表: 钩吻断肠增益,
  },
  {
    技能ID: 101358,
    技能名称: '钩吻断肠·悟·追加',
    统计名称: '钩吻断肠·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 308,
    技能增益列表: 钩吻断肠增益,
  },
  {
    技能ID: 101425,
    技能名称: '苍棘缚地·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 576,
    技能增益列表: 通用增益,
  },
  // -------
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
