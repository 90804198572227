import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '刀魂',
        奇穴图片: 'https://icon.jx3box.com/icon/6291.png',
        id: '13317',
        奇穴描述: '擎刀姿态下自身基础外功攻击力提高15%。',
      },
      {
        奇穴名称: '难行',
        奇穴图片: 'https://icon.jx3box.com/icon/6345.png',
        id: '13374',
        奇穴描述: '“盾飞”的最大充能次数提升至5层，且目标被盾牌首次击中时移动速度降低60%，持续6秒。',
      },
      {
        奇穴名称: '连烽',
        奇穴图片: 'https://icon.jx3box.com/icon/6328.png',
        id: '34540',
        奇穴描述: '“盾飞”可对击中的目标周围10尺的其他敌对目标进行弹射。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '登锋',
        奇穴图片: 'https://icon.jx3box.com/icon/22369.png',
        id: '37558',
        奇穴描述: '流血效果伤害提高70%，作用时间间隔缩短1秒，总持续时间保持不变。',
      },
      {
        奇穴名称: '鸣金',
        奇穴图片: 'https://icon.jx3box.com/icon/6315.png',
        id: '18354',
        奇穴描述: '“盾墙”调息时间降低5秒，格挡值最大值增加100点。',
      },
      {
        奇穴名称: '绝返',
        奇穴图片: 'https://icon.jx3box.com/icon/6295.png',
        id: '13090',
        奇穴描述:
          '“斩刀”命中目标后获得持续6秒的“狂绝”效果：若施展“绝刀”未成功击杀目标，则返还消耗的怒气，并额外获得一次在6秒内施展“绝刀”的机会。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '分野',
        奇穴图片: 'https://icon.jx3box.com/icon/6288.png',
        id: '13087',
        奇穴描述:
          '“绝刀”会心几率提高15%，会心效果提高20%，施展“绝刀”使得自身伤害提高5%，持续12秒。',
      },
      {
        奇穴名称: '卷云',
        奇穴图片: 'https://icon.jx3box.com/icon/6316.png',
        id: '13321',
        奇穴描述:
          '“盾刀”每一段招式伤害逐步递增，施展第三段时将卸除目标混元、阳性、阴性、毒性有益气劲各一个。',
      },
      {
        奇穴名称: '万仞',
        奇穴图片: 'https://icon.jx3box.com/icon/6308.png',
        id: '13088',
        奇穴描述: '施展“绝刀”后，会对目标周围6尺范围内额外5个目标造成外功伤害。',
      },
      {
        奇穴名称: '飞瀑',
        奇穴图片: 'https://icon.jx3box.com/icon/6331.png',
        id: '13372',
        奇穴描述: '“盾飞”伤害提高15%，击中目标后自身回复5点怒气，最多回复25点。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '战骨',
        奇穴图片: 'https://icon.jx3box.com/icon/12719.png',
        id: '24098',
        奇穴描述: '“盾毅”调息时间降低10秒，伤害提高40%，可以在擎盾状态下直接施展。',
      },
      {
        奇穴名称: '嗜血',
        奇穴图片: 'https://icon.jx3box.com/icon/6431.png',
        id: '21281',
        奇穴描述: '“绝刀”达到最大怒气消耗时，招式伤害额外提高40%。',
      },
      {
        奇穴名称: '劫生',
        奇穴图片: 'https://icon.jx3box.com/icon/6441.png',
        id: '18978',
        奇穴描述: '“劫刀”施展距离提高2尺，会心几率提高25%，会心效果提高25%。',
      },
      {
        奇穴名称: '北漠',
        奇穴图片: 'https://icon.jx3box.com/icon/6283.png',
        id: '13109',
        奇穴描述: '“盾击”伤害提高70%，施展“盾击”对自身6尺120度扇形范围的额外5个目标也造成伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '锋鸣',
        奇穴图片: 'https://icon.jx3box.com/icon/6326.png',
        id: '22897',
        奇穴描述: '施展“盾飞”使自身外功破防等级提高15%，“盾飞”伤害提高100%，持续30秒。',
      },
      {
        奇穴名称: '残裂',
        奇穴图片: 'https://icon.jx3box.com/icon/6434.png',
        id: '13414',
        奇穴描述: '“盾猛”可将目标击退8尺并撞击沿途最多5个其他目标，使其眩晕3秒。',
      },
      {
        奇穴名称: '残楼',
        奇穴图片: 'https://icon.jx3box.com/icon/6289.png',
        id: '13086',
        奇穴描述:
          '每次施展“劫刀”“斩刀”“绝刀”为当前侠士目标添加一层5%最大气血值的“残楼”治疗吸收盾，吸收盾最多可叠加5层。自身气血值每降低10%，“劫刀”“绝刀”“斩刀”的伤害额外提高7%。',
      },
      {
        奇穴名称: '步临',
        奇穴图片: 'https://icon.jx3box.com/icon/6284.png',
        id: '13111',
        奇穴描述:
          '“盾击”使目标被疗伤成效降低30%，此效果最多可叠加2层，持续12秒。2层时，受到苍云“苍雪刀”套路招式伤害提高5%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '履刃',
        奇穴图片: 'https://icon.jx3box.com/icon/22370.png',
        id: '37559',
        奇穴描述: '“闪刀”命中有自身流血效果的目标使流血效果的剩余伤害提高150%。',
      },
      {
        奇穴名称: '麾远',
        奇穴图片: 'https://icon.jx3box.com/icon/21953.png',
        id: '37239',
        奇穴描述:
          '“斩刀”锁足效果变为眩晕效果，并追加一段无视15%目标化劲的外功伤害，若成功眩晕目标，则返还15点怒气和6秒斩刀调息时间。',
      },
      {
        奇穴名称: '血誓',
        奇穴图片: 'https://icon.jx3box.com/icon/18272.png',
        id: '32618',
        奇穴描述:
          '若自身气血值高于20%，在“血怒”状态下每次施展“苍雪刀”套路下招式，将消耗自身5%的最大气血值。连续两次对同一目标施展“苍雪刀”套路下招式后，第三次命中该目标的“苍雪刀”套路下招式消耗20%的自身当前气血值，将对目标造成一次无视50%化劲等级的外功伤害并附带一层“血誓”效果，持续3秒，效果期间被疗伤成效额外降低30%，可与其他减疗效果叠加。',
      },
      {
        奇穴名称: '雷云',
        奇穴图片: 'https://icon.jx3box.com/icon/6287.png',
        id: '13397',
        奇穴描述: '“盾飞”命中的第一个目标若正在运功，则使该目标2秒内无法运功。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '业火麟光',
        奇穴图片: 'https://icon.jx3box.com/icon/19153.png',
        id: '34912',
        奇穴描述:
          '生成一团业火附于盾上，持续10秒。业火存在期间，每次施展“云城盾”套路下招式将使 “业火”额外爆裂一次，自身怒气若高于45点，使用“盾飞”将返还25秒“盾飞”调息时间，并将业火转化为6层“麟光甲”效果，效果期间施展“苍雪刀”套路下招式附带一次破招伤害与“麟光甲”额外伤害并消耗一层。连续使用三次“苍雪刀”套路下招式将获得额外65点怒气，重置所有“苍雪刀”套路下技能调息时间，该效果每次“麟光甲”期间只能触发一次。',
      },
      {
        奇穴名称: '隐刀',
        奇穴图片: 'https://icon.jx3box.com/icon/6355.png',
        id: '13152',
        奇穴描述:
          '最少消耗5点怒气，最多消耗15点怒气，消耗逐渐增加，闪刀命中附带自身虚弱或流血效果的目标后10秒内可连续施展，隐刀命中附带自身虚弱或流血效果的目标可对其造成100%的武器伤害外加点外功伤害，并冲刺穿过目标，期间自身免疫伤害和控制效果，持续1秒，施展次数越多，怒气消耗越高。',
      },
      {
        奇穴名称: '血刀',
        奇穴图片: 'https://icon.jx3box.com/icon/6440.png',
        id: '13153',
        奇穴描述:
          '消耗10点怒气，“闪刀”命中目标后方可施展，可对15尺范围内最多5个目标造成100%的武器伤害外加点外功伤害，若当前目标附带自身的流血效果，则血刀命中的所有目标都将附带一层流血效果。',
      },
      {
        奇穴名称: '叱威',
        奇穴图片: 'https://icon.jx3box.com/icon/6306.png',
        id: '13278',
        奇穴描述:
          '“盾舞”伤害提高10%，在盾墙姿态下施展时，可触发二段技能，施展“盾舞·强化”击退周围8尺内最多6个目标并造成一次外功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '吓魂',
        奇穴图片: 'https://icon.jx3box.com/icon/6429.png',
        id: '21282',
        奇穴描述:
          '“绝刀”附带8尺冲刺效果，命中自身流血目标，直接造成最大怒气对应的伤害，若目标气血值低于自身，则使目标的基础攻击力下降20%，持续4秒。',
      },
      {
        奇穴名称: '过涯',
        奇穴图片: 'https://icon.jx3box.com/icon/16189.png',
        id: '28490',
        奇穴描述:
          '“盾壁”调息时间降低20秒，持续时间延长20秒，拥有属于自身的伤害吸收盾时会每0.5秒持续对周围3尺的3个目标造成外功伤害。',
      },
      {
        奇穴名称: '肃驾',
        奇穴图片: 'https://icon.jx3box.com/icon/7245.png',
        id: '14840',
        奇穴描述:
          '每消耗15点怒气，回复自身1点格挡值，“分山劲”心法下降低自身“盾立”0.5秒的调息时间。',
      },
      {
        奇穴名称: '恋战',
        奇穴图片: 'https://icon.jx3box.com/icon/6303.png',
        id: '13126',
        奇穴描述: '自身化劲等级降低20%，招式伤害提高20%。',
      },
      {
        奇穴名称: '扶阵',
        奇穴图片: 'https://icon.jx3box.com/icon/14088.png',
        id: '25203',
        奇穴描述:
          '“盾墙”调息时间降低5秒，“盾墙”状态下，可以施展“盾猛”和“盾击”，施展“盾猛”举盾向前快速冲刺15尺。击晕路径上最多5个目标并造成外功伤害。对终点6尺范围最多5个目标造成100%武器伤害外加外功伤害并且击倒5秒，随后解除自身盾墙状态。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '从容',
        奇穴图片: 'https://icon.jx3box.com/icon/6432.png',
        id: '13366',
        奇穴描述: '当自身血量高于60%，则外功基础攻击提高10%，无双等级提高15%。',
      },
      {
        奇穴名称: '赤心',
        奇穴图片: 'https://icon.jx3box.com/icon/6305.png',
        id: '13073',
        奇穴描述: '“血怒”效果期间每消耗或回复1点怒气为自身回复0.1%气血最大值。',
      },
      {
        奇穴名称: '千险',
        奇穴图片: 'https://icon.jx3box.com/icon/6435.png',
        id: '13172',
        奇穴描述:
          '战斗状态下，自身气血低于35%时解除自身所有控制效果并使自身免疫所有控制效果(被拉除外)并使受到的伤害降低10%，气血高于35%后该效果消失。',
      },
      {
        奇穴名称: '血魄',
        奇穴图片: 'https://icon.jx3box.com/icon/6341.png',
        id: '38969',
        奇穴描述: '施展“血怒”，清空自身“斩刀”、“绝刀”调息时间，并获得25点怒气。',
      },
      {
        奇穴名称: '援戈',
        奇穴图片: 'https://icon.jx3box.com/icon/20064.png',
        id: '36058',
        奇穴描述:
          '“盾击”充能时间降低1秒，层数增加一层，每次施展盾击会触发一次破招，若连续三次成功触发该破招伤害将获得3层“援戈”效果，每次施展苍雪刀套路下招式将附带外功伤害并消耗一层。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '愤恨',
        奇穴图片: 'https://icon.jx3box.com/icon/6437.png',
        id: '13304',
        奇穴描述: '消耗5%气血，“血怒”效果提高30%，持续时间增加至25秒。',
      },
      {
        奇穴名称: '劫化生',
        奇穴图片: 'https://icon.jx3box.com/icon/6346.png',
        id: '13395',
        奇穴描述:
          '施展“血怒”后获得的“劫化”效果期间每点身法提高自身2点招架等级，持续时间提升至8秒，调息时间提高至32秒。“血怒”期间施展“劫刀”命中带有自身“流血”效果的侠士目标，则对其造成该持续伤害的两跳伤害。',
      },
      {
        奇穴名称: '激奋',
        奇穴图片: 'https://icon.jx3box.com/icon/6322.png',
        id: '20984',
        奇穴描述:
          '施展“盾壁”将会触发10尺范围“振奋军阵”，处于“军阵”中的5个友方目标不会受到重伤，受到伤害提高10%，基础攻击力提高20%，“振奋军阵”持续8秒。此效果不会在秘境中生效。',
      },
      {
        奇穴名称: '盾抛',
        奇穴图片: 'https://icon.jx3box.com/icon/6296.png',
        id: '13386',
        奇穴描述:
          '盾墙状态下，施展“盾压”后可施展，震碎自身周围刀气障碍，造成外功伤害并将目标及其周围4尺内3个目标抛至自己身后15尺，并使其眩晕2秒。 盾墙状态下施展盾压会使目标锁足2秒。',
      },
      {
        奇穴名称: '惊涌',
        奇穴图片: 'https://icon.jx3box.com/icon/11866.png',
        id: '36205',
        奇穴描述:
          '“血怒”效果无法再叠加，期间自身无双等级提高30%，施展“斩刀”“绝刀”“盾舞”触发的破招伤害提高20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '刀煞',
        奇穴图片: 'https://icon.jx3box.com/icon/11867.png',
        id: '14838',
        奇穴描述: '对击倒、被击僵直目标伤害提高30%。“绝刀”及其破招伤害无视目标100%外功防御等级。',
      },
      {
        奇穴名称: '捍卫',
        奇穴图片: 'https://icon.jx3box.com/icon/6300.png',
        id: '13418',
        奇穴描述:
          '“盾墙”效果期间自身半径8尺范围内最多5个友方目标受到的伤害降低40%，免疫所有控制效果（被拉除外）。',
      },
      {
        奇穴名称: '岁久',
        奇穴图片: 'https://icon.jx3box.com/icon/21955.png',
        id: '37240',
        奇穴描述:
          '施展“盾壁”使自身立即回复自身10%气血最大值，并回复自身30%格挡值。受到任何伤害攻击导致自身气血值低于40%则立刻重置“盾壁”调息时间，该效果最多每3分钟触发一次。',
      },
      {
        奇穴名称: '莫朽',
        奇穴图片: 'https://icon.jx3box.com/icon/21954.png',
        id: '37241',
        奇穴描述:
          '每次施展“苍雪刀”套路下招式会为自己叠加一层“莫朽”效果：每层化解自身受到的4%最大气血值的伤害，连续施展三次“苍雪刀”套路下招式将使自己额外获得3层“莫朽”效果。',
      },
      {
        奇穴名称: '仇非',
        奇穴图片: 'https://icon.jx3box.com/icon/14086.png',
        id: '25212',
        奇穴描述:
          '自身气血值低于1%时，消耗全部怒气，短时不会重伤，持续6秒，期间自身禁疗。结束时若自身怒气大于等于60点，回复自身50%气血值。此效果最多3分30秒触发一次。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '阵云结晦',
        奇穴图片: 'https://icon.jx3box.com/icon/17157.png',
        id: '30769',
        奇穴描述:
          '“擎刀”姿态下施展“血怒”后可施展3段招式，3段招式伤害及其破招伤害均无视目标100%外功防御等级。&lt;br/&gt;一段：对前方6尺矩形范围的最多5个敌对目标造成100%武器伤害外加点外功伤害；&lt;br/&gt;二段：斩对前方8尺扇形范围的最多5个敌对目标造成100%武器伤害外加外功伤害和破招伤害；&lt;br/&gt;三段：冲向20尺内的目标并对其造成100%武器伤害外加点外功伤害和破招伤害。',
      },
      {
        奇穴名称: '矢尽兵穷',
        奇穴图片: 'https://icon.jx3box.com/icon/16191.png',
        id: '29066',
        奇穴描述:
          '施展后在原地插下旗帜，向12尺范围内的敌方玩家发出战斗邀请，施展时每个拥有自身流血效果的目标会使自身获得“在野”效果，使自身获得不受限的加速值加成7.5%，移动速度提高5%，该效果最多作用4次。3秒后在旗帜半径12尺内生成决斗场，范围内自身以及最多5个敌方玩家被疗伤成效额外降低10%，免疫击退和被拉。受到邀请未在场内和离开决斗场的敌方玩家获得“怯战”效果，造成的伤害降低60%，持续12秒。决斗场最多存在15秒，在苍云离开后消失。',
      },
      {
        奇穴名称: '祭血关山',
        奇穴图片: 'https://icon.jx3box.com/icon/17698.png',
        id: '32619',
        奇穴描述:
          '一段招式歃血御城，以自身为中心获得“御城领域”，领域最多持续30秒，领域内使自身基础攻击力增加10%，并使领域内最多3名敌方玩家每秒将获得一层“歃血”外功持续伤害效果。自身气血值每降低20%，御城领域的基础攻击力增加的效果额外提高10%。二段招式吹角关山，收起“御城领域”。10秒内下一个“苍雪刀”套路下招式将根据自身收起“御城领域”时的气血值为目标添加不同时长的“锋寒”不利状态，状态持续期间将吸收目标30%目标最大气血值的所获得的治疗量。',
      },
      {
        奇穴名称: '扬旌沙场',
        奇穴图片: 'https://icon.jx3box.com/icon/7505.png',
        id: '15196',
        奇穴描述:
          '可对敌对侠士目标施展，强迫目标与自身进行对决，清除双方持续治疗效果、受控状态、沉默、阳性、阴性、混元性、毒性、点穴、持续伤害不利效果，气血恢复至最大值。期间不受其他玩家招式影响，持续12秒，决斗时间结束或自身被击败则提前结束决斗（双方气血恢复至决斗之前），目标被击败则直接重伤。',
      },
      {
        奇穴名称: '断马摧城',
        奇穴图片: 'https://icon.jx3box.com/icon/14087.png',
        id: '25213',
        奇穴描述:
          '举盾对自身8尺范围最多5个目标造成一次锤击，造成外功伤害并使其眩晕3秒。当前每拥有10点怒气此次伤害提高7.5%，并使其眩晕时长增加0.25秒。被命中的目标受到惊吓，攻击力下降50%，并在5秒内逐渐恢复。外功伤害&gt;',
      },
    ],
  },
]

export default 奇穴数据
