import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '翼轸·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101473.png',
        id: '101473',
        奇穴描述:
          '持有卦象期间：&lt;br/&gt;放置【灯魂】可使范围内敌方{锁足}，持续2秒；&lt;br/&gt;自身成功使敌方{眩晕}时，造成的伤害提高20%，持续5秒。',
      },
      {
        奇穴名称: '辰星·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101474.png',
        id: '101474',
        奇穴描述:
          '自身或目标处于{局}或{连局}中时，【傍身招式】和【兵主逆·悟】命中时造成1次额外混元性内功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '星汉 ·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101475.png',
        id: '101475',
        奇穴描述: '持有卦象或场上有自身【灯魂】时，造成的伤害提高10%，受到的伤害降低10%。',
      },
      {
        奇穴名称: '堙灭·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101476.png',
        id: '101476',
        奇穴描述: '每当自身【灯魂】消失时，自身造成的伤害提高5%，最多提高15%，持续30秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '七宿·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101477.png',
        id: '101477',
        奇穴描述:
          '处于{局}或{连局}之中的目标受到自身{祝由·火离·悟}或【杀星在尾·悟】伤害时，{减疗}50%，持续3秒。',
      },
      {
        奇穴名称: '灵灯·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101478.png',
        id: '101478',
        奇穴描述:
          '施展【绝技招式】使自身造成的会心率和会心效果提高10%，持续25秒，施展【傍身招式】可延长1秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '鬼星开穴·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101454.png',
        id: '101454',
        奇穴描述: '立即引爆场上所有【灯魂】，对{局}和{连局}内敌方造成伤害并{定身}。',
      },
      {
        奇穴名称: '列宿游·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101455.png',
        id: '101455',
        奇穴描述:
          '对敌方造成伤害，施展时每存在1个自身【灯魂】，自身获得1层{明灯·悟}，持续60秒。{明灯·悟}叠加至9层以后再次获得时，使本招式调息时间降低5秒。',
      },
    ],
  },
]

export default 奇穴数据
