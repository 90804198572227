import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 40398,
    uid: 40398,
    装备名称: '陶然意·月容囊',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40395,
    uid: 40395,
    装备名称: '陶然意·修茂囊',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.根骨, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40394,
    uid: 40394,
    装备名称: '陶然意·理乾囊',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40390,
    uid: 40390,
    装备名称: '陶然意·来仪囊',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.根骨, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.加速等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40388,
    uid: 40388,
    装备名称: '陶然意·凡圣囊',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40384,
    uid: 40384,
    装备名称: '陶然意·连筠囊',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40383,
    uid: 40383,
    装备名称: '陶然意·蛇言囊',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.根骨, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40382,
    uid: 40382,
    装备名称: '陶然意·寒河囊',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.根骨, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40380,
    uid: 40380,
    装备名称: '陶然意·清静囊',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.根骨, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40378,
    uid: 40378,
    装备名称: '陶然意·簇锦囊',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40377,
    uid: 40377,
    装备名称: '陶然意·祇树囊',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8663 },
      { 属性: 属性类型.元气, 值: 656 },
      { 属性: 属性类型.内功基础攻击, 值: 1190 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 39359,
    uid: 39359,
    装备名称: '迟玉音',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39358,
    uid: 39358,
    装备名称: '洒宸襟',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39287,
    uid: 39287,
    装备名称: '青桑囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.全会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39286,
    uid: 39286,
    装备名称: '季春囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.内功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39247,
    uid: 39247,
    装备名称: '游山箭囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 39246,
    uid: 39246,
    装备名称: '思玉箭囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 39143,
    uid: 39143,
    装备名称: '漠晖囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.全会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39142,
    uid: 39142,
    装备名称: '漠安囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.内功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40881,
    uid: 40881,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 3346 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.全会心效果等级, 值: 2864 },
      { 属性: 属性类型.破招值, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40880,
    uid: 40880,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 3346 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.全会心效果等级, 值: 2864 },
      { 属性: 属性类型.无双等级, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40879,
    uid: 40879,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 2769 },
      { 属性: 属性类型.内功破防等级, 值: 8782 },
      { 属性: 属性类型.无双等级, 值: 4582 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39434,
    uid: 39434,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 3346 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.全会心效果等级, 值: 2864 },
      { 属性: 属性类型.破招值, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39433,
    uid: 39433,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 3346 },
      { 属性: 属性类型.全会心等级, 值: 5727 },
      { 属性: 属性类型.全会心效果等级, 值: 2864 },
      { 属性: 属性类型.无双等级, 值: 2864 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39432,
    uid: 39432,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8400 },
      { 属性: 属性类型.内功基础攻击, 值: 2769 },
      { 属性: 属性类型.内功破防等级, 值: 8782 },
      { 属性: 属性类型.无双等级, 值: 4582 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40875,
    uid: 40875,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3215 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.全会心效果等级, 值: 2751 },
      { 属性: 属性类型.无双等级, 值: 2751 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40874,
    uid: 40874,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3215 },
      { 属性: 属性类型.全会心等级, 值: 3668 },
      { 属性: 属性类型.破招值, 值: 3668 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40873,
    uid: 40873,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3769 },
      { 属性: 属性类型.无双等级, 值: 8070 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39428,
    uid: 39428,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3215 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.全会心效果等级, 值: 2751 },
      { 属性: 属性类型.无双等级, 值: 2751 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39427,
    uid: 39427,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3215 },
      { 属性: 属性类型.全会心等级, 值: 3668 },
      { 属性: 属性类型.破招值, 值: 3668 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39426,
    uid: 39426,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简暗器特效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.内功基础攻击, 值: 3769 },
      { 属性: 属性类型.无双等级, 值: 8070 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40869,
    uid: 40869,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 3083 },
      { 属性: 属性类型.全会心等级, 值: 3519 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 40868,
    uid: 40868,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 3083 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40867,
    uid: 40867,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 2552 },
      { 属性: 属性类型.全会心等级, 值: 8093 },
      { 属性: 属性类型.无双等级, 值: 4222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 39422,
    uid: 39422,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 3083 },
      { 属性: 属性类型.全会心等级, 值: 3519 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 39421,
    uid: 39421,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 3083 },
      { 属性: 属性类型.内功破防等级, 值: 3519 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39420,
    uid: 39420,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7741 },
      { 属性: 属性类型.内功基础攻击, 值: 2552 },
      { 属性: 属性类型.全会心等级, 值: 8093 },
      { 属性: 属性类型.无双等级, 值: 4222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 40343,
    uid: 40343,
    装备名称: '陶然意·月容囊',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40340,
    uid: 40340,
    装备名称: '陶然意·修茂囊',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40339,
    uid: 40339,
    装备名称: '陶然意·理乾囊',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40335,
    uid: 40335,
    装备名称: '陶然意·来仪囊',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.加速等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40333,
    uid: 40333,
    装备名称: '陶然意·凡圣囊',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40329,
    uid: 40329,
    装备名称: '陶然意·连筠囊',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40328,
    uid: 40328,
    装备名称: '陶然意·蛇言囊',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40327,
    uid: 40327,
    装备名称: '陶然意·寒河囊',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40325,
    uid: 40325,
    装备名称: '陶然意·清静囊',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40323,
    uid: 40323,
    装备名称: '陶然意·簇锦囊',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40322,
    uid: 40322,
    装备名称: '陶然意·祇树囊',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7444 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1022 },
      { 属性: 属性类型.内功破防等级, 值: 5076 },
      { 属性: 属性类型.全能等级, 值: 2030 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40863,
    uid: 40863,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2952 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.全会心效果等级, 值: 2527 },
      { 属性: 属性类型.破招值, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40862,
    uid: 40862,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2952 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.全会心效果等级, 值: 2527 },
      { 属性: 属性类型.无双等级, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40861,
    uid: 40861,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2443 },
      { 属性: 属性类型.内功破防等级, 值: 7748 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40830,
    uid: 40830,
    装备名称: '青志囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40829,
    uid: 40829,
    装备名称: '虹雨囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40059,
    uid: 40059,
    装备名称: '孤山寒月·忧生囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40058,
    uid: 40058,
    装备名称: '孤山寒月·风吟囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.内功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39416,
    uid: 39416,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2952 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.全会心效果等级, 值: 2527 },
      { 属性: 属性类型.破招值, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39415,
    uid: 39415,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2952 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.全会心效果等级, 值: 2527 },
      { 属性: 属性类型.无双等级, 值: 2527 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39414,
    uid: 39414,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.内功基础攻击, 值: 2443 },
      { 属性: 属性类型.内功破防等级, 值: 7748 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39103,
    uid: 39103,
    装备名称: '映宵囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39102,
    uid: 39102,
    装备名称: '云眷囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.内功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39063,
    uid: 39063,
    装备名称: '江汜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 39062,
    uid: 39062,
    装备名称: '合苏囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40857,
    uid: 40857,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 2834 },
      { 属性: 属性类型.全会心等级, 值: 4851 },
      { 属性: 属性类型.全会心效果等级, 值: 2426 },
      { 属性: 属性类型.无双等级, 值: 2426 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40856,
    uid: 40856,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 2834 },
      { 属性: 属性类型.全会心等级, 值: 3234 },
      { 属性: 属性类型.破招值, 值: 3234 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40855,
    uid: 40855,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 3323 },
      { 属性: 属性类型.无双等级, 值: 7115 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39410,
    uid: 39410,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 2834 },
      { 属性: 属性类型.全会心等级, 值: 4851 },
      { 属性: 属性类型.全会心效果等级, 值: 2426 },
      { 属性: 属性类型.无双等级, 值: 2426 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39409,
    uid: 39409,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 2834 },
      { 属性: 属性类型.全会心等级, 值: 3234 },
      { 属性: 属性类型.破招值, 值: 3234 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39408,
    uid: 39408,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7115 },
      { 属性: 属性类型.内功基础攻击, 值: 3323 },
      { 属性: 属性类型.无双等级, 值: 7115 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38961,
    uid: 38961,
    装备名称: '壁镜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1425 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38960,
    uid: 38960,
    装备名称: '壁观囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1425 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40851,
    uid: 40851,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2716 },
      { 属性: 属性类型.全会心等级, 值: 3099 },
      { 属性: 属性类型.内功破防等级, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 40850,
    uid: 40850,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2716 },
      { 属性: 属性类型.内功破防等级, 值: 3099 },
      { 属性: 属性类型.破招值, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40849,
    uid: 40849,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2248 },
      { 属性: 属性类型.全会心等级, 值: 7129 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 39404,
    uid: 39404,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2716 },
      { 属性: 属性类型.全会心等级, 值: 3099 },
      { 属性: 属性类型.内功破防等级, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 39403,
    uid: 39403,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2716 },
      { 属性: 属性类型.内功破防等级, 值: 3099 },
      { 属性: 属性类型.破招值, 值: 3099 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39402,
    uid: 39402,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.内功基础攻击, 值: 2248 },
      { 属性: 属性类型.全会心等级, 值: 7129 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 38841,
    uid: 38841,
    装备名称: '语晨囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.元气, 值: 556 },
      { 属性: 属性类型.内功基础攻击, 值: 1405 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38840,
    uid: 38840,
    装备名称: '语初囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.根骨, 值: 556 },
      { 属性: 属性类型.内功基础攻击, 值: 1405 },
      { 属性: 属性类型.内功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40931,
    uid: 40931,
    装备名称: '昭文囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 40930,
    uid: 40930,
    装备名称: '丘墟囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40916,
    uid: 40916,
    装备名称: '玉凉囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 40915,
    uid: 40915,
    装备名称: '樛枝囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 40577,
    uid: 40577,
    装备名称: '斛尘囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40576,
    uid: 40576,
    装备名称: '熙春囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38923,
    uid: 38923,
    装备名称: '昭文囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38922,
    uid: 38922,
    装备名称: '丘墟囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38885,
    uid: 38885,
    装备名称: '玉凉囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38884,
    uid: 38884,
    装备名称: '樛枝囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38847,
    uid: 38847,
    装备名称: '斛尘囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38846,
    uid: 38846,
    装备名称: '熙春囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38785,
    uid: 38785,
    装备名称: '弥弦囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.全会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38784,
    uid: 38784,
    装备名称: '珀音囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38747,
    uid: 38747,
    装备名称: '霖瑜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38746,
    uid: 38746,
    装备名称: '瑾辞囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 40845,
    uid: 40845,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2598 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.全会心效果等级, 值: 2223 },
      { 属性: 属性类型.破招值, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40844,
    uid: 40844,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2598 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.全会心效果等级, 值: 2223 },
      { 属性: 属性类型.无双等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 40843,
    uid: 40843,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2150 },
      { 属性: 属性类型.内功破防等级, 值: 6819 },
      { 属性: 属性类型.无双等级, 值: 3558 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 39398,
    uid: 39398,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2598 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.全会心效果等级, 值: 2223 },
      { 属性: 属性类型.破招值, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39397,
    uid: 39397,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2598 },
      { 属性: 属性类型.全会心等级, 值: 4447 },
      { 属性: 属性类型.全会心效果等级, 值: 2223 },
      { 属性: 属性类型.无双等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39396,
    uid: 39396,
    装备名称: '无修囊·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6522 },
      { 属性: 属性类型.内功基础攻击, 值: 2150 },
      { 属性: 属性类型.内功破防等级, 值: 6819 },
      { 属性: 属性类型.无双等级, 值: 3558 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38835,
    uid: 38835,
    装备名称: '语晨囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6390 },
      { 属性: 属性类型.元气, 值: 521 },
      { 属性: 属性类型.内功基础攻击, 值: 1317 },
      { 属性: 属性类型.全会心等级, 值: 4357 },
      { 属性: 属性类型.无双等级, 值: 3486 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38834,
    uid: 38834,
    装备名称: '语初囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6390 },
      { 属性: 属性类型.根骨, 值: 521 },
      { 属性: 属性类型.内功基础攻击, 值: 1317 },
      { 属性: 属性类型.内功会心等级, 值: 4357 },
      { 属性: 属性类型.无双等级, 值: 3486 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38709,
    uid: 38709,
    装备名称: '惜晗囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6193 },
      { 属性: 属性类型.元气, 值: 505 },
      { 属性: 属性类型.内功基础攻击, 值: 1276 },
      { 属性: 属性类型.全会心等级, 值: 4222 },
      { 属性: 属性类型.无双等级, 值: 3378 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38708,
    uid: 38708,
    装备名称: '濯缨囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6193 },
      { 属性: 属性类型.根骨, 值: 505 },
      { 属性: 属性类型.内功基础攻击, 值: 1276 },
      { 属性: 属性类型.内功会心等级, 值: 4222 },
      { 属性: 属性类型.无双等级, 值: 3378 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38829,
    uid: 38829,
    装备名称: '语晨囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5962 },
      { 属性: 属性类型.元气, 值: 486 },
      { 属性: 属性类型.内功基础攻击, 值: 1228 },
      { 属性: 属性类型.全会心等级, 值: 4065 },
      { 属性: 属性类型.无双等级, 值: 3252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38828,
    uid: 38828,
    装备名称: '语初囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5962 },
      { 属性: 属性类型.根骨, 值: 486 },
      { 属性: 属性类型.内功基础攻击, 值: 1228 },
      { 属性: 属性类型.内功会心等级, 值: 4065 },
      { 属性: 属性类型.无双等级, 值: 3252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38671,
    uid: 38671,
    装备名称: '绢素囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5797 },
      { 属性: 属性类型.元气, 值: 473 },
      { 属性: 属性类型.内功基础攻击, 值: 1194 },
      { 属性: 属性类型.加速等级, 值: 3953 },
      { 属性: 属性类型.无双等级, 值: 3162 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38670,
    uid: 38670,
    装备名称: '烨霖囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5797 },
      { 属性: 属性类型.根骨, 值: 473 },
      { 属性: 属性类型.内功基础攻击, 值: 1194 },
      { 属性: 属性类型.加速等级, 值: 3953 },
      { 属性: 属性类型.无双等级, 值: 3162 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38823,
    uid: 38823,
    装备名称: '语晨囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5534 },
      { 属性: 属性类型.元气, 值: 451 },
      { 属性: 属性类型.内功基础攻击, 值: 1140 },
      { 属性: 属性类型.全会心等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3019 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38822,
    uid: 38822,
    装备名称: '语初囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5534 },
      { 属性: 属性类型.根骨, 值: 451 },
      { 属性: 属性类型.内功基础攻击, 值: 1140 },
      { 属性: 属性类型.内功会心等级, 值: 3773 },
      { 属性: 属性类型.无双等级, 值: 3019 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39471,
    uid: 39471,
    装备名称: '轻桡囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.元气, 值: 443 },
      { 属性: 属性类型.内功基础攻击, 值: 1120 },
      { 属性: 属性类型.全会心等级, 值: 3706 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39470,
    uid: 39470,
    装备名称: '临流囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.根骨, 值: 443 },
      { 属性: 属性类型.内功基础攻击, 值: 1120 },
      { 属性: 属性类型.内功会心等级, 值: 3706 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38633,
    uid: 38633,
    装备名称: '凝玥囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.元气, 值: 412 },
      { 属性: 属性类型.内功基础攻击, 值: 747 },
      { 属性: 属性类型.全会心等级, 值: 3706 },
      { 属性: 属性类型.全能等级, 值: 1482 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38632,
    uid: 38632,
    装备名称: '静漪囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.根骨, 值: 412 },
      { 属性: 属性类型.内功基础攻击, 值: 747 },
      { 属性: 属性类型.内功会心等级, 值: 3706 },
      { 属性: 属性类型.全能等级, 值: 1482 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
]

export default 暗器装备数据
