import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '正夏',
        奇穴图片: 'https://icon.jx3box.com/icon/14020.png',
        id: '24925',
        奇穴描述: '“三星临”伤害和疗伤成效提高10%。',
      },
      {
        奇穴名称: '明心',
        奇穴图片: 'https://icon.jx3box.com/icon/14016.png',
        id: '24930',
        奇穴描述: '“三星临”的会心提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '水盈',
        奇穴图片: 'https://icon.jx3box.com/icon/14163.png',
        id: '24936',
        奇穴描述:
          '“起卦”占得“水坎”卦象则恢复自身30%内力最大值。自身内力值低于20%时必占得“水坎”卦象。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '望旗',
        奇穴图片: 'https://icon.jx3box.com/icon/14017.png',
        id: '24934',
        奇穴描述: '“兵主逆”伤害和疗伤成效提高10%。',
      },
      {
        奇穴名称: '度冥',
        奇穴图片: 'https://icon.jx3box.com/icon/19197.png',
        id: '34680',
        奇穴描述:
          '施展“纵横三才”移动“灯魂”到目标点时，若目标点周围有自身“灯魂”则对6尺范围内最多6个目标造成伤害。',
      },
      {
        奇穴名称: '天网',
        奇穴图片: 'https://icon.jx3box.com/icon/17190.png',
        id: '24932',
        奇穴描述:
          '“灯魂”每秒持续对周围6尺最多6个目标造成混元性内功伤害。最多同时受到一个“灯魂”造成的伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '顺祝',
        奇穴图片: 'https://icon.jx3box.com/icon/14036.png',
        id: '25034',
        奇穴描述:
          '“兵主逆”可以在移动中施展，运功不会被打退，每跳会延长3秒自身卦象时间，最多延长9秒。',
      },
      {
        奇穴名称: '燃灯',
        奇穴图片: 'https://icon.jx3box.com/icon/18320.png',
        id: '32795',
        奇穴描述:
          '施展“祝由”主动招式5秒内，若场上存在“灯魂”，则可以消耗当前剩余持续时间最短的“灯魂”，额外释放一次“祝由”效果，并返还12秒“奇门飞宫”调息时间。',
      },
      {
        奇穴名称: '连蜷',
        奇穴图片: 'https://icon.jx3box.com/icon/14043.png',
        id: '24951',
        奇穴描述: '“踏星行”被命中几率降低至80%，持续时间提高1秒，回复90点星运值。',
      },
      {
        奇穴名称: '星迹',
        奇穴图片: 'https://icon.jx3box.com/icon/18323.png',
        id: '32790',
        奇穴描述:
          '施展“踏星行”时，若存在连局，则“灯魂”存在时间延长20秒，并且在“踏星行”结束时自身位置按一定角度再次生成连局。期间灯魂不会因为距离消失。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '枭神',
        奇穴图片: 'https://icon.jx3box.com/icon/14054.png',
        id: '25071',
        奇穴描述:
          '非战斗状态下施展“变卦”不再消耗星运，施展“祝由·火离”则使自身会心会效提高10%，持续40秒。',
      },
      {
        奇穴名称: '列宿游',
        奇穴图片: 'https://icon.jx3box.com/icon/17712.png',
        id: '32791',
        奇穴描述:
          '起卦后有50%几率施展，对目标造成点混元性内功伤害，并使非治疗心法幻化5秒，幻化期间目标无法受到治疗和跳跃，不受控制影响，幻化期间受到当前血量30%的伤害后会提前解除该效果。',
      },
      {
        奇穴名称: '龙回首',
        奇穴图片: 'https://icon.jx3box.com/icon/14023.png',
        id: '24994',
        奇穴描述:
          '“鬼星开穴”变成2层充能招式，充能时间15秒。引爆“连局”时对当前目标额外造成一次伤害。',
      },
      {
        奇穴名称: '神皆寂',
        奇穴图片: 'https://icon.jx3box.com/icon/14165.png',
        id: '25011',
        奇穴描述:
          '打断目标运功，对目标造成点混元性内功伤害，若目标处于“连局”内，使其封内7秒，目标离开“连局”则封内效果提前消失，若目标不处于“连局”中则封内1秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '重山',
        奇穴图片: 'https://icon.jx3box.com/icon/14040.png',
        id: '24983',
        奇穴描述: '卦象“山艮”持续期间，“九字诀”套路伤害招式额外伤害提高至15%。',
      },
      {
        奇穴名称: '神占',
        奇穴图片: 'https://icon.jx3box.com/icon/14162.png',
        id: '25023',
        奇穴描述:
          '施展“起卦”有几率获得“神占·火离”“神占·山艮”“神占·水坎”其中之一效果。若当前卦象与“神占”一致，则自身“三星临”无视目标40%化劲等级且“祝由”效果将变为“神占”效果。&lt;br/&gt;神占·火离：目标“祝由·火离”持续期间，被自身“天斗旋”、“兵主逆”命中，则立刻触发一次“卦象·火离”伤害。&lt;br/&gt;神占·山艮：击倒目标4秒。&lt;br/&gt;神占·水坎：使友方目标4秒内不会受到伤害，若友方目标施展伤害招式，则效果剩余时间变成50%减伤效果。',
      },
      {
        奇穴名称: '地遁',
        奇穴图片: 'https://icon.jx3box.com/icon/14047.png',
        id: '25025',
        奇穴描述: '施展“鬼星开穴”引爆“连局”时返还30点星运值。',
      },
      {
        奇穴名称: '星合',
        奇穴图片: 'https://icon.jx3box.com/icon/13874.png',
        id: '24986',
        奇穴描述:
          '“往者定”定身时间提高2秒，对自身“连局”中目标施展时，立刻触发定身效果。战斗中施展“起卦”4次后可在4秒内额外释放一次“往者定”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '鬼遁',
        奇穴图片: 'https://icon.jx3box.com/icon/14046.png',
        id: '25072',
        奇穴描述:
          '“鬼星开穴”成功引爆“灯魂”后则提高自身30%混元内功基础攻击。每成功引爆1个“灯魂”则获得15秒持续时间，持续时间不超过45秒，“鬼遁”持续时间若小于12秒，则施展“鬼星开穴”不消耗星运。',
      },
      {
        奇穴名称: '神遁',
        奇穴图片: 'https://icon.jx3box.com/icon/14052.png',
        id: '25028',
        奇穴描述:
          '“奇门飞宫”所形成的“局”持续时间提高20秒，自身处于“连局”时免疫控制效果（被推被拉除外）。',
      },
      {
        奇穴名称: '风遁',
        奇穴图片: 'https://icon.jx3box.com/icon/14034.png',
        id: '25367',
        奇穴描述:
          '施展“鬼星开穴”引爆灯魂，灯魂剩余时间的40%将返还为“奇门飞宫”的调息时间，并在8秒内免疫目标控制（被推被拉除外）。',
      },
      {
        奇穴名称: '堪卜',
        奇穴图片: 'https://icon.jx3box.com/icon/16230.png',
        id: '25137',
        奇穴描述: '“起卦”调息时间降低8秒，免疫控制效果提高2秒，“起卦”效果期间移动速度提高20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '祝祷',
        奇穴图片: 'https://icon.jx3box.com/icon/14030.png',
        id: '25022',
        奇穴描述: '“星运”大于30点时，自身伤害和治疗效果提高10%。',
      },
      {
        奇穴名称: '追叙',
        奇穴图片: 'https://icon.jx3box.com/icon/17189.png',
        id: '37456',
        奇穴描述:
          '施展“鬼星开穴”引爆连局后，4秒内施展“变卦”使“鬼星开穴”引爆的“魂灯”位置及状态返回引爆前，刷新持续时间至22秒，并返还80%“鬼星开穴”调息时间。',
      },
      {
        奇穴名称: '亘天',
        奇穴图片: 'https://icon.jx3box.com/icon/14161.png',
        id: '25368',
        奇穴描述:
          '“天斗旋”变为3层8秒充能招式，运功时间降低0.5秒，回复星运效果提高50%。对目标额外造成一次“亘天”伤害，该伤害受到天斗旋秘籍加成。',
      },
      {
        奇穴名称: '司命',
        奇穴图片: 'https://icon.jx3box.com/icon/14050.png',
        id: '24965',
        奇穴描述:
          '“天人合一”状态后释放的第一个治疗招式会使目标受到的伤害降低60%，持续6秒。受到伤害降低效果期间目标气血值首次低于60%时，降低自身“天人合一”10秒调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '神元',
        奇穴图片: 'https://icon.jx3box.com/icon/14035.png',
        id: '25066',
        奇穴描述: '体质和元气提高10%。',
      },
      {
        奇穴名称: '连断',
        奇穴图片: 'https://icon.jx3box.com/icon/14025.png',
        id: '25378',
        奇穴描述:
          '“变卦”变为2层充能招式，调息时间提高19秒，可重置自身卦象祝由效果，施展“鬼星开穴”后6秒内下一个“变卦”星运消耗降低20点。',
      },
      {
        奇穴名称: '趋时',
        奇穴图片: 'https://icon.jx3box.com/icon/18317.png',
        id: '36191',
        奇穴描述:
          '“卦象·山艮”状态下“鬼星开穴”命中连局内的目标时，若目标基础调息时间正在调息状态，则延长基础调息时间2秒，返还自身3秒“鬼星开穴”调息时间。',
      },
      {
        奇穴名称: '相蚀',
        奇穴图片: 'https://icon.jx3box.com/icon/14160.png',
        id: '24949',
        奇穴描述:
          '“鬼星开穴”成功引爆“灯魂”则卸除“连局”内的敌对目标混元、阴性、阳性、毒性有益气劲各一个。',
      },
      {
        奇穴名称: '知微',
        奇穴图片: 'https://icon.jx3box.com/icon/14058.png',
        id: '25382',
        奇穴描述:
          '施展“奇门飞宫”不在目标点生成灯魂，而是出现在自身身后5尺，“纵横三才”会使被命中的目标眩晕2秒，并恢复自身35点星运。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '荧入白',
        奇穴图片: 'https://icon.jx3box.com/icon/14031.png',
        id: '25085',
        奇穴描述: '卦象持续期间自身无视目标50%内功防御等级，威胁值降低50%。',
      },
      {
        奇穴名称: '移魂',
        奇穴图片: 'https://icon.jx3box.com/icon/14056.png',
        id: '25062',
        奇穴描述:
          '“斗转星移”时若“灯魂”存在，施展时将由距离目标最近的“灯魂”与目标交换位置，并返还“斗转星移”10秒调息时间。',
      },
      {
        奇穴名称: '踏斗',
        奇穴图片: 'https://icon.jx3box.com/icon/14019.png',
        id: '25026',
        奇穴描述:
          '同时只会存在一个“灯魂”，施展“纵横三才”时距离目标点越远伤害越高，最远20尺造成混元性内功伤害。',
      },
      {
        奇穴名称: '休囚',
        奇穴图片: 'https://icon.jx3box.com/icon/14042.png',
        id: '25074',
        奇穴描述: '“祝由·火离”卦象持续期间，目标受治疗成效降低50%，持续15秒。',
      },
      {
        奇穴名称: '延年',
        奇穴图片: 'https://icon.jx3box.com/icon/14038.png',
        id: '25061',
        奇穴描述:
          '“斗转星移”可对友方侠士目标施展。“斗转星移”与目标交换位置后，自身将获得化解气血最大值30%的护盾，持续6秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '征凶',
        奇穴图片: 'https://icon.jx3box.com/icon/14055.png',
        id: '25379',
        奇穴描述:
          '“变卦”时，“水坎”卦象必定占得“火离”卦象，“火离”卦象必定占得“山艮”卦象，“山艮”卦象必定占得“火离”卦象。',
      },
      {
        奇穴名称: '坍缩',
        奇穴图片: 'https://icon.jx3box.com/icon/18321.png',
        id: '32794',
        奇穴描述:
          '“鸿蒙天禁”改变为可移动施展的蓄力技能，对自身周围最多三个敌方目标生效。初始生效范围为6尺，每蓄力0.5秒增加2尺生效范围，最多可蓄力1.5秒。',
      },
      {
        奇穴名称: '天钺',
        奇穴图片: 'https://icon.jx3box.com/icon/14022.png',
        id: '25087',
        奇穴描述:
          '“鸿蒙天禁”运功时间降低0.75秒，可在跳跃中施展。可对团队成员使用，并卸除掉“阴性不利效果”“阳性不利效果”“混元不利效果”“毒不利效果”“持续伤害不利效果”各2个。“鸿蒙天禁”调息时间降低10秒。',
      },
      {
        奇穴名称: '龙马出河',
        奇穴图片: 'https://icon.jx3box.com/icon/14028.png',
        id: '25114',
        奇穴描述:
          '在自身位置，产生“龙马出河”图祥，持续期间8尺内5个友方目标受到的伤害降低30%，且每秒卸除一次不可叠加的被疗伤成效降低的效果，“龙马出河”持续24秒，持续期间可提前被摧毁。被摧毁后生成“龙马出河·残阵”，自身位于“龙马出河·残阵”上时每秒向其输送10点星运，输送80点星运后可重新召唤“龙马出河”。',
      },
      {
        奇穴名称: '荧惑守心',
        奇穴图片: 'https://icon.jx3box.com/icon/19193.png',
        id: '34681',
        奇穴描述:
          '对目标造成伤害，并获得4秒“荧惑守心”效果：结束时检查目标是否有减伤，护盾，持续治疗效果。“荧惑守心”效果结束5秒内可对另一目标施展二段招式“移祸”：“荧惑守心”若记录过减伤，护盾，持续治疗效果，则使目标8秒内无法获得对应记录类型的减伤，护盾，持续治疗效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '擎羊',
        奇穴图片: 'https://icon.jx3box.com/icon/14029.png',
        id: '25088',
        奇穴描述: '施展“鬼星开穴”时，自身每存在1个“灯魂”则“鬼星开穴”伤害额外提高15%。',
      },
      {
        奇穴名称: '灵器',
        奇穴图片: 'https://icon.jx3box.com/icon/14037.png',
        id: '25173',
        奇穴描述:
          '施展“三星临”“兵主逆”“天斗旋”命中目标时，自身或目标处于“连局”中则触发“灵器”效果，造成混元性内功伤害。',
      },
      {
        奇穴名称: '焚如',
        奇穴图片: 'https://icon.jx3box.com/icon/14053.png',
        id: '25030',
        奇穴描述: '“祝由·火离”不再溅射伤害其他目标，但对当前目标伤害提高70%。',
      },
      {
        奇穴名称: '华盖',
        奇穴图片: 'https://icon.jx3box.com/icon/14015.png',
        id: '25148',
        奇穴描述:
          '“天人合一”转换比例提高100%，施展“天人合一”后自身不受打退、打断，不受无法施展内功招式和缴械效果的影响，持续8秒。该效果在施展“鸿蒙天禁”时消失。',
      },
      {
        奇穴名称: '覆舟',
        奇穴图片: 'https://icon.jx3box.com/icon/14021.png',
        id: '25067',
        奇穴描述: '“祝由·水坎”减伤比例提高100%，反弹伤害提高100%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '增卜',
        奇穴图片: 'https://icon.jx3box.com/icon/14048.png',
        id: '25086',
        奇穴描述:
          '处于自身“祝由·火离”状态下的敌对目标，每次受到自身“兵主逆”招式攻击，则“祝由·火离”对其伤害提高14%，最多70%，“祝由·火离”状态结束后每2秒衰减7%。',
      },
      {
        奇穴名称: '镇星入舆',
        奇穴图片: 'https://icon.jx3box.com/icon/18319.png',
        id: '37505',
        奇穴描述:
          '强化1个“灯魂”与自身链接，30秒内自身施展“三星临”“兵主逆”“天斗旋”时链接在自身的“灯魂”也会施展同样的招式攻击敌方，每个招式“灯魂”只会施展一次。可施展二段招式，若存在“连局”则“连局”环绕非侠士目标。被动效果：自身“山艮”状态下对“祝由·火离”目标施展“九字诀”套路招式时造成额外伤害。',
      },
      {
        奇穴名称: '洞彻九宫',
        奇穴图片: 'https://icon.jx3box.com/icon/14057.png',
        id: '25149',
        奇穴描述:
          '“奇门飞宫”“纵横三才”施展距离提高5尺，“魂灯”超过自身40尺才会消失。施展“鬼星开穴”命中连局内的侠士目标后标记至多两个敌方侠士4秒，成功标记两名敌方侠士时，则和自身连接成一个三角形。连接期间角度最大的侠士每秒受到点混元性内功伤害，4秒结束后角度最大的侠士将额外受到点混元性内功伤害，链接超过35尺则提前结束。',
      },
      {
        奇穴名称: '杀星在尾',
        奇穴图片: 'https://icon.jx3box.com/icon/14059.png',
        id: '25176',
        奇穴描述:
          '每秒对自身8尺内随机位置召唤流星雨，流星雨会坠落至该位置对4尺范围内最多5个目标造成混元性内功伤害，若当前目标处于“连局”之中则流星雨集中坠落至目标位置，流星雨持续4秒，命中无法施展内功或外功招式的目标延长一秒流星雨持续时间。',
      },
      {
        奇穴名称: '连极阵',
        奇穴图片: 'https://icon.jx3box.com/icon/14164.png',
        id: '25383',
        奇穴描述:
          '标记目标10秒，标记期间随机使一个“灯魂”自动撞击标记目标，撞击结束后回到自身位置，持续期间所有招式施展距离提高5尺，“灯魂”不会因为超出距离而消失。',
      },
    ],
  },
]

export default 奇穴数据
