export default {
  装备基础属性: {
    基础攻击: 32116,
    破防等级: 37122,
    无双等级: 70363,
    会心等级: 30031,
    会心效果等级: 0,
    全能等级: 0,
    武器伤害_最小值: 8010,
    武器伤害_最大值: 11189,
    破招值: 0,
    力道: 9688,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 106512,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 19412,
    装分: 376644,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100868,
      装备部位: '帽子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100838,
      装备部位: '衣服',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100886,
      装备部位: '腰带',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 104113,
      装备部位: '护腕',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 104131,
      装备部位: '下装',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100820,
      装备部位: '鞋子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41201,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41207,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41231,
      装备部位: '戒指',
      附魔: '力道+268',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41231,
      装备部位: '戒指',
      附魔: '力道+268',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 38886,
      装备部位: '暗器',
      附魔: '加速+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 38957,
      装备部位: '武器',
      附魔: '武伤+856',
    },
  ],
  五彩石: '彩·无双·锐刃·狂攻(陆)',
  装备增益: {},
}
