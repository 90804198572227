export default {
  装备基础属性: {
    基础攻击: 52037,
    破防等级: 64273,
    无双等级: 85684,
    会心等级: 20219,
    会心效果等级: 834,
    全能等级: 0,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    破招值: 26274,
    力道: 44,
    元气: 44,
    身法: 44,
    根骨: 7872,
    体质: 134672,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
    装分: 506419,
  },
  装备镶嵌附魔属性: {
    基础攻击: 11667,
    破防等级: 3336,
    无双等级: 7094,
    会心等级: 2502,
    会心效果等级: 834,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 834,
    力道: 44,
    元气: 44,
    身法: 44,
    根骨: 256,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
  },
  装备列表: [
    {
      当前精炼等级: 6,
      装备部位: '帽子',
      附魔: '攻击+633',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSpiritBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101436,
    },
    {
      当前精炼等级: 6,
      装备部位: '衣服',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101467,
      附魔: '无双+624',
    },
    {
      当前精炼等级: 6,
      装备部位: '腰带',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101374,
      附魔: '无双+624',
    },
    {
      当前精炼等级: 6,
      装备部位: '护腕',
      附魔: '无双+2089',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101575,
    },
    {
      当前精炼等级: 6,
      装备部位: '下装',
      附魔: '无双+2089',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101583,
    },
    {
      当前精炼等级: 6,
      装备部位: '鞋子',
      附魔: '攻击+633',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      id: 101405,
    },
    {
      当前精炼等级: 6,
      装备部位: '项链',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 41411,
      附魔: '体质+361',
    },
    {
      当前精炼等级: 6,
      装备部位: '腰坠',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      id: 41361,
      附魔: '体质+361',
    },
    {
      当前精炼等级: 6,
      装备部位: '戒指',
      附魔: '攻击+633',
      镶嵌孔数组: [],
      id: 41368,
    },
    {
      当前精炼等级: 6,
      装备部位: '戒指',
      附魔: '攻击+633',
      镶嵌孔数组: [],
      id: 41368,
    },
    {
      当前精炼等级: 6,
      装备部位: '暗器',
      附魔: '加速+2089',
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      id: 39358,
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSpiritBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39336,
      装备部位: '武器',
      附魔: '攻击+633',
    },
  ],
  五彩石: '彩·灭气·激流·无双(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 1,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
