import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '虎踞',
        奇穴图片: 'https://icon.jx3box.com/icon/8536.png',
        id: '16692',
        奇穴描述: '“项王击鼎”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '龙息',
        奇穴图片: 'https://icon.jx3box.com/icon/8545.png',
        id: '16691',
        奇穴描述:
          '“擒龙六斩”变为六层透支招式，施展距离变为6尺，施展后返还5秒调息时间且自身不再进入僵直状态，招式不再附带冲刺和控制效果且占用1.5秒公共调息时间。',
      },
      {
        奇穴名称: '孤漠',
        奇穴图片: 'https://icon.jx3box.com/icon/8523.png',
        id: '17045',
        奇穴描述:
          '“龙骧虎步”会心几率提高15%，施展后若不会心则回复狂意、刀魂、气劲各7点，会心后回复狂意、刀魂、气劲提高至15点。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '归酣',
        奇穴图片: 'https://icon.jx3box.com/icon/8639.png',
        id: '16847',
        奇穴描述:
          '“上将军印”调息时间降低7.5秒，消耗的狂意减少5点，每段伤害命中目标则使自身调息时间降低0.5秒，此效果最多作用5次。',
      },
      {
        奇穴名称: '沧雪',
        奇穴图片: 'https://icon.jx3box.com/icon/8527.png',
        id: '16777',
        奇穴描述: '“项王击鼎”三段触发的持续范围伤害每跳伤害递增10%。',
      },
      {
        奇穴名称: '碎影',
        奇穴图片: 'https://icon.jx3box.com/icon/8519.png',
        id: '16816',
        奇穴描述:
          '“闹须弥”释放后获得一次“碎江天”施展机会。“碎江天”命中“闹须弥”目标时额外造成外功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '冥鼔',
        奇穴图片: 'https://icon.jx3box.com/icon/8507.png',
        id: '26904',
        奇穴描述:
          '“项王击鼎”“破釜沉舟”命中目标数量降低为3个，伤害提高20%且施展后30秒内无视目标50%的外功防御，“项王击鼎”第三段招式持续攻击的频率提升25%。',
      },
      {
        奇穴名称: '阳关',
        奇穴图片: 'https://icon.jx3box.com/icon/8532.png',
        id: '17042',
        奇穴描述: '“上将军印”伤害提高15%，无视目标20%的外功防御。',
      },
      {
        奇穴名称: '照野',
        奇穴图片: 'https://icon.jx3box.com/icon/13366.png',
        id: '21264',
        奇穴描述:
          '“西楚悲歌”的调息时间降低10秒，其内附带“坚壁清野”残留伤害效果，“悲歌”的重力效果增强并使目标无法跳跃。“破釜沉舟”命中被自身“悲歌”效果作用的目标，会使目标在“西楚悲歌”结束后3秒内持续受到该效果影响。',
      },
      {
        奇穴名称: '疏狂',
        奇穴图片: 'https://icon.jx3box.com/icon/8638.png',
        id: '17054',
        奇穴描述:
          '主动施展“西楚悲歌”时，不再生成刀气障碍，伤害范围扩大至12尺，范围内小队成员基础攻击力提高30%，持续12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '星火',
        奇穴图片: 'https://icon.jx3box.com/icon/8641.png',
        id: '16728',
        奇穴描述: '体质和力道提高10%，无双等级提高30%。',
      },
      {
        奇穴名称: '霜天',
        奇穴图片: 'https://icon.jx3box.com/icon/8542.png',
        id: '16799',
        奇穴描述: '“上将军印”连续命中的目标每次受到的伤害提高15%。',
      },
      {
        奇穴名称: '余声',
        奇穴图片: 'https://icon.jx3box.com/icon/17156.png',
        id: '30526',
        奇穴描述:
          '施展“项王击鼎”第三段持续攻击后会替换“项王击鼎”招式，在持续攻击期间和完整运功后均可施展，消耗5点狂意，施展会结束持续攻击状态，对周围8尺最多5个目标造成100%武器伤害外加外功伤害并使目标获得“余声”效果：眩晕3秒且受到的伤害提高10%，自身获得“尘身”效果。',
      },
      {
        奇穴名称: '重激',
        奇穴图片: 'https://icon.jx3box.com/icon/8540.png',
        id: '16765',
        奇穴描述:
          '“割据秦宫”调息时间降低3秒，每段命中目标，使5秒内“破釜沉舟”伤害分别提高10%、20%、30%。第三段命中目标时，在自身前方6尺生成“封渊震煞”刀气障碍，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '砺锋',
        奇穴图片: 'https://icon.jx3box.com/icon/8575.png',
        id: '26735',
        奇穴描述: '切换至“雪絮金屏”体态，使得自身下两个“醉斩白蛇”伤害提高30%。',
      },
      {
        奇穴名称: '御侮',
        奇穴图片: 'https://icon.jx3box.com/icon/20062.png',
        id: '16722',
        奇穴描述:
          '“割据秦宫”一段招式会使目标定身3秒，每段命中目标使自身体质10秒内提高20%，最多60%。',
      },
      {
        奇穴名称: '击瑕',
        奇穴图片: 'https://icon.jx3box.com/icon/8509.png',
        id: '16724',
        奇穴描述:
          '“秀明尘身”套路下招式消耗狂意降低20%，命中锁足、定身、眩晕、倒地目标伤害提高20%。',
      },
      {
        奇穴名称: '吞楚',
        奇穴图片: 'https://icon.jx3box.com/icon/8512.png',
        id: '16726',
        奇穴描述: '“碎江天”使目标受到的疗伤成效降低50%，持续12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '见尘',
        奇穴图片: 'https://icon.jx3box.com/icon/18266.png',
        id: '32857',
        奇穴描述:
          '“上将军印”命中有自身“闹须弥”效果的目标后，会生成灵体在目标身后施展“上将军印·见尘”攻击该目标，对目标造成点伤害。“上将军印·见尘”若累计命中目标3次，则使目标定身2秒。',
      },
      {
        奇穴名称: '行泽',
        奇穴图片: 'https://icon.jx3box.com/icon/8537.png',
        id: '16781',
        奇穴描述:
          '“醉斩白蛇”消耗的气劲增加15点，命中目标并使目标无法施展轻功、移动速度降低20%，持续2秒。',
      },
      {
        奇穴名称: '临江',
        奇穴图片: 'https://icon.jx3box.com/icon/11906.png',
        id: '37982',
        奇穴描述:
          '“上将军印”变为瞬发招式，召唤灵体对目标造成伤害，5秒内可施展2段招式对前方最多5个目标目标造成点外功伤害和（0.2016*破招系数*破招值）点破招伤害。',
      },
      {
        奇穴名称: '寒衣',
        奇穴图片: 'https://icon.jx3box.com/icon/8517.png',
        id: '16846',
        奇穴描述:
          '“楚河汉界”和“封渊震煞”血量增加50%，存在期间自身免疫持续性伤害气劲且受到的50%伤害由刀气障碍承担。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '斩纷',
        奇穴图片: 'https://icon.jx3box.com/icon/8522.png',
        id: '16733',
        奇穴描述:
          '“醉斩白蛇”施展后立刻对目标造成伤害，并提高自身的15%外功基础攻击力，持续16秒，最多持续至32秒。',
      },
      {
        奇穴名称: '百战',
        奇穴图片: 'https://icon.jx3box.com/icon/8453.png',
        id: '18625',
        奇穴描述:
          '“破釜沉舟”招式范围变成360°，每次施展“破釜沉舟”命中目标使“项王击鼎”三段触发的持续群体伤害持续时间增加1秒，“项王击鼎”第三段持续效果最多持续6.5秒。并激活一段“项王击鼎”，第三段除外。',
      },
      {
        奇穴名称: '分疆',
        奇穴图片: 'https://icon.jx3box.com/icon/8516.png',
        id: '17047',
        奇穴描述:
          '“上将军印”能额外劈出2刀伤害且攻击频率提高33%，招式无视目标10%化劲等级，命中目标数量降低至3个。',
      },
      {
        奇穴名称: '空痕',
        奇穴图片: 'https://icon.jx3box.com/icon/8644.png',
        id: '21753',
        奇穴描述:
          '“临渊蹈河”变为两层透支招式，透支调息时间20秒。施展后立即回复5%气血值，沿刀墙奔跑过程中受到伤害降低由60%提升至80%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '化蛟',
        奇穴图片: 'https://icon.jx3box.com/icon/8642.png',
        id: '16779',
        奇穴描述:
          '“醉斩白蛇”增加3跳且完整运功后附带一段破招伤害，调息时间提高14秒，可透支一次，运功时间降低25%且每跳伤害递增25%。',
      },
      {
        奇穴名称: '垣墉',
        奇穴图片: 'https://icon.jx3box.com/icon/20063.png',
        id: '16730',
        奇穴描述:
          '自身生成的刀气障碍遭受敌对目标攻击则立刻对周围10尺5个目标造成点外功伤害，若受到5次攻击则墙被打破，对周围10尺5个目标造成点外功伤害。',
      },
      {
        奇穴名称: '震霄',
        奇穴图片: 'https://icon.jx3box.com/icon/8549.png',
        id: '30920',
        奇穴描述:
          '在“西楚悲歌”范围内施展“上将军印”，每跳会在区域内降下落雷随机攻击区域内的敌对侠士目标造成外功伤害。“上将军印”每次命中自身刀气障碍时，会使之产生震动对其周围8尺最多3个目标造成外功伤害。',
      },
      {
        奇穴名称: '绝河',
        奇穴图片: 'https://icon.jx3box.com/icon/19152.png',
        id: '34677',
        奇穴描述:
          '“破釜沉舟”招式有效距离提高2尺。自身血量高于50%时命中侠士目标，则额外消耗自身3%气血值对目标造成等量的真实伤害。',
      },
      {
        奇穴名称: '掠关',
        奇穴图片: 'https://icon.jx3box.com/icon/13367.png',
        id: '38541',
        奇穴描述:
          '“项王击鼎”伤害提高50%且前三段范围提升至360°。第一、二、三段公共调息时间与获得的“尘身”时间提升至2.5秒且该“尘身”期间自身不受缴械、沉默影响，造成伤害时使目标僵直，并标记目标使其在10秒内“蹑云逐月”冲刺速度降低80%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '楚歌',
        奇穴图片: 'https://icon.jx3box.com/icon/8643.png',
        id: '38535',
        奇穴描述:
          '“上将军印”施展后使自身15秒内施展“破釜沉舟”附带“楚歌”外功伤害，并增加自身造成的“楚歌”伤害，最多增加4次。切换体态后效果消失。',
      },
      {
        奇穴名称: '逐鹿',
        奇穴图片: 'https://icon.jx3box.com/icon/8539.png',
        id: '16748',
        奇穴描述:
          '“项王击鼎”“割据秦宫”每段命中目标使“项王击鼎”三段触发的持续群体伤害持续时间增加1秒，“项王击鼎”第三段持续效果最多持续6.5秒。',
      },
      {
        奇穴名称: '易水',
        奇穴图片: 'https://icon.jx3box.com/icon/8531.png',
        id: '16958',
        奇穴描述: '“西楚悲歌”施展后解除自身受控状态并使范围内小队成员受到的伤害降低30%。',
      },
      {
        奇穴名称: '秋瑟',
        奇穴图片: 'https://icon.jx3box.com/icon/8533.png',
        id: '16739',
        奇穴描述:
          '“散流霞”施展后立刻卸除自身阳性、阴性、混元、毒性、点穴、持续伤害不利效果各两个，效果期间施展技能不会解除无法选中状态。',
      },
      {
        奇穴名称: '惊鸿',
        奇穴图片: 'https://icon.jx3box.com/icon/14062.png',
        id: '25256',
        奇穴描述:
          '“破釜沉舟”调息时间降低3秒，施展后摧毁敌对刀气障碍效果，每命中1个刀气障碍则返还4秒“破釜沉舟”调息时间和0.25秒公共调息时间（最多0.5秒）。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '绝期',
        奇穴图片: 'https://icon.jx3box.com/icon/8518.png',
        id: '17056',
        奇穴描述:
          '“闹须弥”流血效果伤害提高70%且自身伤害招式命中目标后有30%的几率刷新流血效果持续时间并造成点外功伤害。',
      },
      {
        奇穴名称: '息声',
        奇穴图片: 'https://icon.jx3box.com/icon/8520.png',
        id: '16705',
        奇穴描述:
          '“雷走风切”会心几率提高20%，会心效果提高20%，命中运功状态的目标无法运功时间延长2秒。',
      },
      {
        奇穴名称: '曳鼎',
        奇穴图片: 'https://icon.jx3box.com/icon/8544.png',
        id: '16957',
        奇穴描述: '“楚河汉界”变为2层充能招式，充能时间45秒。',
      },
      {
        奇穴名称: '流影',
        奇穴图片: 'https://icon.jx3box.com/icon/8528.png',
        id: '16734',
        奇穴描述:
          '“碎江天”消耗的刀魂增加5点，“碎江天”施展后额外多获得一层“松烟”效果，使自身闪避几率提高100%，持续2秒。',
      },
      {
        奇穴名称: '扬刀激尘',
        奇穴图片: 'https://icon.jx3box.com/icon/18264.png',
        id: '33123',
        奇穴描述:
          '被动效果：“秀明尘身”体态下招式施展范围增加1尺，控制时间增加1秒。&lt;br/&gt;主动效果：该招式视作“秀明尘身”套路下招式，该体态下成功控制目标或“上将军印”累计命中目标5次后可在尘身体态下施展，消耗10点狂意，对前方20尺范围最多5个目标造成外功伤害附带200%武器伤害，路径上附带5秒“坚壁清野”伤害残留效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '冷川',
        奇穴图片: 'https://icon.jx3box.com/icon/8526.png',
        id: '16977',
        奇穴描述: '体态能量上限提高20点，战斗状态下非当前体态能量回复速度提高300%。',
      },
      {
        奇穴名称: '裁骨',
        奇穴图片: 'https://icon.jx3box.com/icon/8635.png',
        id: '16911',
        奇穴描述:
          '“楚河汉界”“封渊震煞”刀气障碍会将触碰到的敌方目标击退并眩晕2秒，最多对10个目标生效。',
      },
      {
        奇穴名称: '重烟',
        奇穴图片: 'https://icon.jx3box.com/icon/8636.png',
        id: '16893',
        奇穴描述: '“秀明尘身”体态下自身所有招式调息速度提高30%。',
      },
      {
        奇穴名称: '霜轮',
        奇穴图片: 'https://icon.jx3box.com/icon/8640.png',
        id: '25254',
        奇穴描述:
          '“松烟竹雾”体态下移速效果额外增加10%，受到的锁足、定身、眩晕控制效果持续时间降低50%且每秒恢复自身0.4%自身气血最大值。',
      },
      {
        奇穴名称: '含风',
        奇穴图片: 'https://icon.jx3box.com/icon/8513.png',
        id: '25633',
        奇穴描述:
          '“刀啸风吟”施展后使自身“雪絮金屏”套路招式伤害提高10%，气劲消耗降低20%，持续24秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '心镜',
        奇穴图片: 'https://icon.jx3box.com/icon/8553.png',
        id: '16912',
        奇穴描述:
          '“坚壁清野”刀气残留时间增加5秒，对目标造成5次“坚壁清野”伤害后使目标定身3秒。“雪絮金屏”体态下施展伤害招式命中处于自身“坚壁清野”区域内的敌对目标，招式会心几率提高20%，会心效果提高20%。',
      },
      {
        奇穴名称: '鸣震九霄',
        奇穴图片: 'https://icon.jx3box.com/icon/16188.png',
        id: '28680',
        奇穴描述:
          '3层透支招式，需要在“项王击鼎”三段持续伤害过程中施展，第一次施展会将周围10尺内5个敌对目标向自身短暂吸引，第二次施展会向前冲刺并吸引目标，第三次施展会向前冲刺更远并吸引目标。每次施展可获得25%减伤持续8秒，可叠加3层。',
      },
      {
        奇穴名称: '斩狂枭',
        奇穴图片: 'https://icon.jx3box.com/icon/8555.png',
        id: '21858',
        奇穴描述:
          '“秀明尘身”体态下方可施展。使自身15秒伤害增加30%，招式狂意消耗增加10点，施展“破釜沉舟”重置“破釜沉舟”调息时间。离开“秀明尘身”体态后效果消失。',
      },
      {
        奇穴名称: '渊冰',
        奇穴图片: 'https://icon.jx3box.com/icon/8637.png',
        id: '25639',
        奇穴描述:
          '施展“楚河汉界”后3秒内“刀啸风吟”变为最多蓄力1.5秒的招式，命中目标使目标定身且无法使用轻功3秒。蓄力1至1.25秒时施放，刀气会吸收自身“楚河汉界”并造成最高外功伤害，自身冲向目标并在目标周围形成半径3尺的圆形刀气障碍，敌方玩家目标无法穿越，持续4秒。该刀气障碍视为“西楚悲歌”。',
      },
      {
        奇穴名称: '横断山河',
        奇穴图片: 'https://icon.jx3box.com/icon/11907.png',
        id: '25095',
        奇穴描述:
          '金屏体态下可以施展，消耗15点气劲，自身免疫控制（击退和被拉除外），持续3秒，冲向目标，造成外功伤害并击飞目标，使目标无法施展轻功，持续3秒，若目标被击飞则降低自身0.5秒基础调息时间，可以施展二段招式冲刺至目标身边造成外功伤害，若目标高于8尺还将被击落，自身随即跳跃落地，再对目标造成外功伤害并在自身前方6尺生成“封渊震煞”刀气障碍，持续4秒。',
      },
    ],
  },
]

export default 奇穴数据
