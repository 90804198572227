// import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 避实击虚增益: 技能增益列表类型[] = [
  ...通用增益,
  // {
  //   增益名称: '斩颓·破绽二',
  //   增益类型: '全局启用',
  //   增益所在位置: '奇穴',
  //   增益集合: [{ 属性: 属性类型.通用增伤, 值: 0.244 }],
  // },
  // {
  //   增益名称: '斩颓·破绽三',
  //   增益类型: '全局启用',
  //   增益所在位置: '奇穴',
  //   增益集合: [{ 属性: 属性类型.通用增伤, 值: 0.488 }],
  // },
  // {
  //   增益名称: '斩颓·破绽四',
  //   增益类型: '全局启用',
  //   增益所在位置: '奇穴',
  //   增益集合: [{ 属性: 属性类型.通用增伤, 值: 0.732 }],
  // },
]

export default 避实击虚增益
