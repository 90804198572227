import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '中峙',
        奇穴图片: 'https://icon.jx3box.com/icon/17728.png',
        id: '32449',
        奇穴描述: '“行云势”伤害提高10%。',
      },
      {
        奇穴名称: '渊冲',
        奇穴图片: 'https://icon.jx3box.com/icon/17729.png',
        id: '32450',
        奇穴描述: '“行云势”会心提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '顾渺',
        奇穴图片: 'https://icon.jx3box.com/icon/17730.png',
        id: '32451',
        奇穴描述: '“留客雨”命中有自身破绽的目标，有40%几率造成4秒锁足效果，每层破绽使概率增加10%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '戗风',
        奇穴图片: 'https://icon.jx3box.com/icon/17755.png',
        id: '32580',
        奇穴描述: '“破浪三式”套路招式伤害提高8%，吸收“身形”后8秒内额外提高8%。',
      },
      {
        奇穴名称: '袭伐',
        奇穴图片: 'https://icon.jx3box.com/icon/17732.png',
        id: '32454',
        奇穴描述: '“停云势”命中目标将定身其4秒，“横云断浪”命中目标将眩晕其4秒。',
      },
      {
        奇穴名称: '霆骇',
        奇穴图片: 'https://icon.jx3box.com/icon/17733.png',
        id: '32455',
        奇穴描述: '“识破”状态下施展“决云势”命中目标后使其无法施展轻功，持续3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '雨积',
        奇穴图片: 'https://icon.jx3box.com/icon/17734.png',
        id: '32456',
        奇穴描述: '施展“留客雨”后下一个“停云势”或“行云势”招式伤害提高15%。',
      },
      {
        奇穴名称: '溃延',
        奇穴图片: 'https://icon.jx3box.com/icon/18244.png',
        id: '32464',
        奇穴描述: '“身形”存在时间延长6秒，触碰“身形”时降低11秒“决云势”调息时间。',
      },
      {
        奇穴名称: '簇尘',
        奇穴图片: 'https://icon.jx3box.com/icon/17736.png',
        id: '32458',
        奇穴描述:
          '“身形”出现位置将位于自身6尺处，目标施展招式触发“身形”的概率提高至50%。且“身形”不再是一个目标8秒只会出现一次，而是自身每4秒最多观察到一个“身形”。',
      },
      {
        奇穴名称: '雾灭',
        奇穴图片: 'https://icon.jx3box.com/icon/17737.png',
        id: '32459',
        奇穴描述: '每次触碰到“身形”，对应目标化劲等级降低10%，自身化劲等级提高10%，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '放皓',
        奇穴图片: 'https://icon.jx3box.com/icon/17749.png',
        id: '32490',
        奇穴描述: '“沧浪三叠”每段会心递增10%，会心效果递增10%。',
      },
      {
        奇穴名称: '殊际',
        奇穴图片: 'https://icon.jx3box.com/icon/18242.png',
        id: '32577',
        奇穴描述:
          '每次成功控制目标会在目标6尺范围内出现一个身形，目标被自身定身、眩晕、击倒期间受到的伤害提高20%。',
      },
      {
        奇穴名称: '靖步',
        奇穴图片: 'https://icon.jx3box.com/icon/17743.png',
        id: '32494',
        奇穴描述:
          '“定风凝云”持续期间受到的伤害降低80%且不受被推、被拉影响，每秒回复自身3%气血最大值，提前结束则获取剩余恢复值。',
      },
      {
        奇穴名称: '遏云势',
        奇穴图片: 'https://icon.jx3box.com/icon/17741.png',
        id: '32465',
        奇穴描述:
          '打断目标运功并造成点外功伤害，若目标正在运功，自身获得20点锐意并使目标不受治疗影响，持续3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '颂阵',
        奇穴图片: 'https://icon.jx3box.com/icon/17766.png',
        id: '32588',
        奇穴描述:
          '“洗兵雨”施展距离提高2尺且击飞武器的位置提高至目标8尺位置。可以拾取目标被自身击落的武器，捡起对方武器后，对方受到的缴械时间增加2秒，自身6秒内武器伤害提高200%。',
      },
      {
        奇穴名称: '电逝',
        奇穴图片: 'https://icon.jx3box.com/icon/18243.png',
        id: '32492',
        奇穴描述:
          '“灭影追风”不再降低化劲，期间施展“行云势”只会触发第三段效果，若命中侠士目标则立刻恢复自身5%气血最大值。',
      },
      {
        奇穴名称: '伏瀑',
        奇穴图片: 'https://icon.jx3box.com/icon/17760.png',
        id: '32495',
        奇穴描述: '“触石雨”命中已经被击倒的目标伤害提高60%，并击倒周围6尺内的3个目标。',
      },
      {
        奇穴名称: '威声',
        奇穴图片: 'https://icon.jx3box.com/icon/17762.png',
        id: '33027',
        奇穴描述:
          '施展“灭影追风”立刻进入“双手持武器”状态，且2秒内下一次施展“孤锋破浪”不会回到“单持武器”状态。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '击懈',
        奇穴图片: 'https://icon.jx3box.com/icon/17746.png',
        id: '32497',
        奇穴描述:
          '“留客雨”命中非侠士目标降低“决云势”10秒调息时间，自身没有“识破”效果时施展“决云势”命中非侠士目标将立刻给其添加一层“破绽”效果。',
      },
      {
        奇穴名称: '碣石',
        奇穴图片: 'https://icon.jx3box.com/icon/17747.png',
        id: '32498',
        奇穴描述:
          '“游风飘踪”效果期间受到的伤害降低8%，施展时将把所有受控状态(击退、被拉、减速)除外都视为“击倒”状态转移出去。',
      },
      {
        奇穴名称: '观衅',
        奇穴图片: 'https://icon.jx3box.com/icon/17751.png',
        id: '32499',
        奇穴描述: '若目标产生“身形”则会主动吸附。',
      },
      {
        奇穴名称: '承磊',
        奇穴图片: 'https://icon.jx3box.com/icon/18231.png',
        id: '32500',
        奇穴描述: '“破绽”及“横云断浪”流血效果最大可叠加至6层。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '滔天',
        奇穴图片: 'https://icon.jx3box.com/icon/17750.png',
        id: '32502',
        奇穴描述:
          '“沧浪三叠”变为单体招式，对拥有自身“破绽”的目标施展时将立刻打出三段效果，但将消耗目标一层破绽。',
      },
      {
        奇穴名称: '镇机',
        奇穴图片: 'https://icon.jx3box.com/icon/17735.png',
        id: '32457',
        奇穴描述: '目标每拥有一层破绽，“断云势”对其伤害提高12%。',
      },
      {
        奇穴名称: '界破',
        奇穴图片: 'https://icon.jx3box.com/icon/17757.png',
        id: '32512',
        奇穴描述:
          '施展“孤锋破浪”会在原地残留刀气，2秒后会引爆刀气对6尺范围内最多5个目标造成点外功伤害。“孤锋破浪”命中侠士目标后，使其体质降低10%。',
      },
      {
        奇穴名称: '横断',
        奇穴图片: 'https://icon.jx3box.com/icon/17756.png',
        id: '34688',
        奇穴描述: '“停云势”“留客雨”会刷新目标身上属于自身的“横云断浪”流血效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '长溯',
        奇穴图片: 'https://icon.jx3box.com/icon/18247.png',
        id: '32508',
        奇穴描述:
          '“沧浪三叠”每次命中非侠士目标会给其添加1层“长溯”效果，“孤锋破浪”命中有“长溯”的目标，每层“长溯”将额外造成一次“孤锋破浪”伤害，“长溯”持续30秒，最多叠加3层。',
      },
      {
        奇穴名称: '鸣锋',
        奇穴图片: 'https://icon.jx3box.com/icon/18246.png',
        id: '34722',
        奇穴描述:
          '自身“破浪三式”套路招式命中有自身“横云断浪”流血效果的目标会造成外功伤害，每层流血效果使该伤害提高13%。',
      },
      {
        奇穴名称: '摧朽',
        奇穴图片: 'https://icon.jx3box.com/icon/18232.png',
        id: '32582',
        奇穴描述: '施展“定风凝云”时缓慢将20尺内当前观察的目标吸引至自己身边。',
      },
      {
        奇穴名称: '识意',
        奇穴图片: 'https://icon.jx3box.com/icon/17765.png',
        id: '32587',
        奇穴描述: '“灭影追风”结束时若自身未重伤，则会将受到的伤害转换为伤害吸收盾，持续6秒。',
      },
      {
        奇穴名称: '敛摄',
        奇穴图片: 'https://icon.jx3box.com/icon/18235.png',
        id: '32606',
        奇穴描述: '“横云断浪”变成2层充能招式，充能时间12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '涣衍',
        奇穴图片: 'https://icon.jx3box.com/icon/17756.png',
        id: '32511',
        奇穴描述: '“横云断浪”调息时间降低3秒，附带“流血”效果持续时间提高12秒。',
      },
      {
        奇穴名称: '势钧',
        奇穴图片: 'https://icon.jx3box.com/icon/18241.png',
        id: '32610',
        奇穴描述:
          '“横云断浪”命中目标获得生命值15%的伤害吸收盾，持续5秒。若目标有伤害吸收盾，获得的盾量翻倍。',
      },
      {
        奇穴名称: '驰心',
        奇穴图片: 'https://icon.jx3box.com/icon/17745.png',
        id: '32607',
        奇穴描述:
          '“驰风八步”调息时间降低5秒，二段招式会冲刺向目标并对路径上最多5个敌对目标造成点外功伤害和2秒定身效果。',
      },
      {
        奇穴名称: '周流',
        奇穴图片: 'https://icon.jx3box.com/icon/18237.png',
        id: '32608',
        奇穴描述: '“识破”效果持续期间“决云势”命中目标额外获得15点“锐意”。',
      },
      {
        奇穴名称: '驭耀',
        奇穴图片: 'https://icon.jx3box.com/icon/18236.png',
        id: '32609',
        奇穴描述: '“行云势”命中目标额外获得2点“锐意”，“停云势”命中目标额外获得10点“锐意”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '连亘',
        奇穴图片: 'https://icon.jx3box.com/icon/20070.png',
        id: '36034',
        奇穴描述:
          '连续施展“流云势法”套路招式命中同一目标3次，额外获得20点锐意，并使目标获得6秒“连亘”效果：命中降低100%，招式未命中后消除，最多可叠加2层。',
      },
      {
        奇穴名称: '急潮',
        奇穴图片: 'https://icon.jx3box.com/icon/17748.png',
        id: '32491',
        奇穴描述: '“破浪三式”套路招式作用范围提高2尺，施展期间自身受到的范围伤害降低75%。',
      },
      {
        奇穴名称: '涤瑕',
        奇穴图片: 'https://icon.jx3box.com/icon/17758.png',
        id: '32513',
        奇穴描述:
          '“横云断浪”每层流血伤害额外提高10%。若有该“流血”的敌对目标在该效果持续期间释放过伤害招式，则会在“流血”结束时叠加与其当前“破绽”相等层数的“流血”效果。',
      },
      {
        奇穴名称: '强膂',
        奇穴图片: 'https://icon.jx3box.com/icon/18240.png',
        id: '32578',
        奇穴描述: '力道和移动速度提高10%。',
      },
      {
        奇穴名称: '辞霈',
        奇穴图片: 'https://icon.jx3box.com/icon/17761.png',
        id: '32579',
        奇穴描述: '“停云势”命中目标则使其受到的治疗及获得的护盾效果降低50%，持续6秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '聚疏',
        奇穴图片: 'https://icon.jx3box.com/icon/17731.png',
        id: '32452',
        奇穴描述:
          '“望云势”反击触发时间降低0.75秒，反击时不再需要目标，且会对6尺内3个敌对目标造成伤害。',
      },
      {
        奇穴名称: '流岚',
        奇穴图片: 'https://icon.jx3box.com/icon/17742.png',
        id: '32493',
        奇穴描述:
          '“游风飘踪”施展后立刻在自身左、右、后方位8尺各产生1个持续20秒的“身形”，每触碰到1个“身形”则获得20秒“流岚”效果，“流岚”效果持续期间无视目标25%内外防御，该效果最多叠加至60秒。',
      },
      {
        奇穴名称: '斩颓',
        奇穴图片: 'https://icon.jx3box.com/icon/17754.png',
        id: '32509',
        奇穴描述: '“破绽”叠加的层数越多，目标受到的额外伤害越高。',
      },
      {
        奇穴名称: '泯息',
        奇穴图片: 'https://icon.jx3box.com/icon/18238.png',
        id: '32611',
        奇穴描述: '“行云势”命中目标则降低“洗兵雨”1秒调息时间，“触石雨”2秒调息时间。',
      },
      {
        奇穴名称: '亘绝',
        奇穴图片: 'https://icon.jx3box.com/icon/18234.png',
        id: '32613',
        奇穴描述: '“驰风八步”变为充能招式，施展后自身御劲提高100%，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '截辕',
        奇穴图片: 'https://icon.jx3box.com/icon/17759.png',
        id: '32586',
        奇穴描述:
          '施展“孤锋破浪”后将在前方4尺残留6秒斩击刀气，刀气快速向前移动，每0.5秒对半径8尺内的5个敌对目标造成点外功伤害，并添加12秒持续伤害效果，累计攻击20个目标，则刀气被引爆，对半径10尺内10个目标造成点外功伤害，若目标为侠士，则使其下8秒内受到的范围型招式伤害提高35%，刀气每重伤一名敌人，自身则获得35点锐意，该刀气只会存在一道。',
      },
      {
        奇穴名称: '踏罡',
        奇穴图片: 'https://icon.jx3box.com/icon/17738.png',
        id: '32496',
        奇穴描述: '“驰风八步” 每段结束后，自身移动速度提高50%，持续8秒。',
      },
      {
        奇穴名称: '潋风',
        奇穴图片: 'https://icon.jx3box.com/icon/20071.png',
        id: '36035',
        奇穴描述:
          '施展“决云势”不会重置调息时间，自身获得5秒“潋风”气劲，期间主动施展“流云势法”套路伤害招式命中目标会额外获得20点锐意，并额外触发点外功伤害。',
      },
      {
        奇穴名称: '泽风同承',
        奇穴图片: 'https://icon.jx3box.com/icon/17767.png',
        id: '32665',
        奇穴描述:
          '链接2个有自身破绽且离你最近的的侠士目标，链接期间你攻击其中一个目标，另一个目标也将受到伤害（每层破绽24%，最多48%），链接生效范围为30尺，持续8秒。',
      },
      {
        奇穴名称: '怒锋倾涛',
        奇穴图片: 'https://icon.jx3box.com/icon/17768.png',
        id: '32614',
        奇穴描述:
          '需在“双持武器”状态中施展，对目标造成200%的武器伤害外加点外功伤害，若目标为侠士且存在自身“破绽”则每个“破绽”追加已损失生命值6%真实伤害。真实伤害必定命中且不受减伤、吸收盾影响。',
      },
    ],
  },
]

export default 奇穴数据
