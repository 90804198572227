import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

export const 获取快照增益 = (功法) => {
  const 水特效 = 功法 === '外功' ? 111 * 10 : 134 * 10 // 130级暂未更新
  const 水特效_英雄 = 功法 === '外功' ? 153 * 10 : 171 * 10

  const 快照增益: 技能增益列表类型[] = [
    ...团队快照增益列表,
    {
      增益名称: '水特效_快照',
      增益所在位置: '装备',
      依赖装备增益: '水特效武器',
      快照类型: '水特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全基础攻击, 值: 水特效 }],
    },
    {
      增益名称: '水特效_英雄_快照',
      增益所在位置: '装备',
      依赖装备增益: '水特效武器_英雄',
      快照类型: '水特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全基础攻击, 值: 水特效_英雄 }],
    },
    {
      增益名称: '风特效_快照',
      增益所在位置: '装备',
      依赖装备增益: '风特效腰坠',
      快照类型: '风特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全破防等级, 值: 23988 }], // 130级1赛季无普通特效腰坠
    },
    {
      增益名称: '风特效_英雄_快照',
      增益所在位置: '装备',
      依赖装备增益: '风特效腰坠_英雄',
      快照类型: '风特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全破防等级, 值: 23988 }], // 26600拍卖腰坠 25404
    },
    {
      增益名称: '大附魔_伤腰_快照',
      增益所在位置: '装备',
      依赖装备增益: '大附魔_伤腰',
      快照类型: '大附魔_伤腰',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 10 / 1024 }],
    },
    {
      增益名称: '大附魔_伤腰_快照·2',
      增益所在位置: '装备',
      依赖装备增益: '大附魔_伤腰',
      快照类型: '大附魔_伤腰',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
    },
  ]
  return 快照增益
}

const 团队快照增益列表: 技能增益列表类型[] = [
  {
    增益名称: '弘法_快照',
    增益所在位置: '团队增益',
    依赖团队增益: '弘法',
    快照类型: '弘法',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 660 }],
  },
  {
    增益名称: '朝圣_快照',
    增益所在位置: '团队增益',
    依赖团队增益: '朝圣',
    快照类型: '朝圣',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 440 }],
  },
  {
    增益名称: '圣浴明心_快照',
    增益所在位置: '团队增益',
    依赖团队增益: '圣浴明心',
    快照类型: '圣浴明心',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 660 }],
  },
  {
    增益名称: '号令三军_快照_一鼓',
    增益所在位置: '团队增益',
    依赖团队增益: '号令三军',
    快照类型: '号令三军',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 594 }],
  },
  {
    增益名称: '号令三军_快照_二鼓',
    增益所在位置: '团队增益',
    依赖团队增益: '号令三军',
    快照类型: '号令三军',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 297 }],
  },
  {
    增益名称: '仙王蛊鼎_快照',
    增益所在位置: '团队增益',
    依赖团队增益: '仙王蛊鼎',
    快照类型: '仙王蛊鼎',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 360 }],
  },
  {
    增益名称: '寒啸千军_快照',
    增益所在位置: '团队增益',
    依赖团队增益: '寒啸千军',
    快照类型: '寒啸千军',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 51 }],
  },
]

export default 获取快照增益
