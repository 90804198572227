import React from 'react'
import 页面隐藏工具 from './页面隐藏工具'
// import 页面右上角工具 from './页面右上角工具'
import 页面右下角工具 from './页面右下角工具'

function 系统工具() {
  return (
    <>
      <页面隐藏工具 />
      {/* <页面右上角工具 /> */}
      <页面右下角工具 />
    </>
  )
}

export default 系统工具
