/**
 * @name 根据装备信息获取基础属性
 * @description 只计算属性增加，并不计算主属性对各属性的加成
 */

import { 属性加成, 属性类型 } from '@/@types/属性'
import {
  大附魔部位枚举,
  装备位置部位枚举,
  装备信息数据类型,
  装备增益原始数据类型,
  装备增益类型,
  装备特效枚举,
  试炼之地特效枚举,
} from '@/@types/装备'
import { 角色基础属性类型 } from '@/@types/角色'
import { 本赛季英雄品级, 本赛季试炼英雄品级, 精炼加成系数 } from '@/数据/常量'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { 通用增益计算 } from '@/计算模块/统一工具函数/增益计算函数'
import { 角色默认基础属性 } from '@/工具函数/init/默认数据'
import 总装分计算 from '../装分计算'
import { 装备特效存在等级 } from '@/数据/装备/装备增益数据'

const { 装备数据, 镶嵌孔, 附魔, 五彩石 } = 获取当前数据()

interface 计算设置 {
  计算五彩石?: boolean
  计算附魔?: boolean
  计算装备特效?: boolean
  过滤附魔部位?: string[]
}

const 根据装备信息获取基础属性 = (
  装备信息: 装备信息数据类型,
  计算设置?: 计算设置
): 装备信息数据类型 => {
  let 装备基础属性: 角色基础属性类型 = { ...角色默认基础属性 }
  let 装备镶嵌附魔属性: 角色基础属性类型 = { ...角色默认基础属性 }
  const {
    计算五彩石 = true,
    计算附魔 = true,
    计算装备特效 = true,
    过滤附魔部位 = [],
  } = 计算设置 || {}
  let 五彩石郭氏增益: 属性加成[] = []

  // 五彩石计算
  if (计算五彩石 && 装备信息?.五彩石) {
    const 五彩石计算数据 = 五彩石计算(装备信息?.五彩石, 装备基础属性)
    装备基础属性 = 五彩石计算数据?.计算后属性
    五彩石郭氏增益 = 五彩石计算数据?.五彩石郭氏增益
  }

  const 装备增益原始数据: 装备增益原始数据类型 = {}
  const 大附魔增益 = { ...装备信息?.装备增益 }

  // 装备计算
  装备信息?.装备列表?.forEach((装备) => {
    const 该装备属性 = 装备数据[装备?.装备部位]?.find((a) => a?.id === 装备?.id)
    if (该装备属性) {
      if (该装备属性?.武器伤害_最大值 && 该装备属性.武器伤害_最小值) {
        装备基础属性.武器伤害_最小值 += 该装备属性.武器伤害_最小值
        装备基础属性.武器伤害_最大值 += 该装备属性.武器伤害_最大值
      }
      该装备属性.装备增益.forEach((增益) => {
        装备基础属性 = 基础属性计算(
          {
            属性: 增益.属性,
            值: 精炼加成系数算法(增益.值, 装备.当前精炼等级),
          },
          装备基础属性
        )
      })
      装备.镶嵌孔数组?.forEach((item) => {
        const 该镶嵌孔 = 镶嵌孔.find(
          (b) =>
            item.镶嵌类型 && (item.镶嵌类型 === b.镶嵌类型 || b.镶嵌索引?.includes(item.镶嵌类型))
        )
        if (该镶嵌孔) {
          装备基础属性 = 基础属性计算(
            {
              属性: 该镶嵌孔.镶嵌类型,
              值: 该镶嵌孔.各等级增益数据[item.镶嵌宝石等级 || 1]?.增益数值,
            },
            装备基础属性
          )
          装备镶嵌附魔属性 = 基础属性计算(
            {
              属性: 该镶嵌孔.镶嵌类型,
              值: 该镶嵌孔.各等级增益数据[item.镶嵌宝石等级 || 1]?.增益数值,
            },
            装备镶嵌附魔属性
          )
        }
      })
      let 本装备是否过滤附魔计算 = false
      if (过滤附魔部位?.length) {
        本装备是否过滤附魔计算 = 过滤附魔部位?.some(
          (key) => 装备位置部位枚举[key] === 装备.装备部位
        )
      }
      if (装备?.附魔 && 计算附魔 && !本装备是否过滤附魔计算) {
        const 该附魔 = 附魔.find((b) => b.附魔名称 === 装备?.附魔)
        if (该附魔) {
          该附魔.增益集合?.forEach((附魔增益) => {
            装备基础属性 = 基础属性计算(附魔增益, 装备基础属性)
            装备镶嵌附魔属性 = 基础属性计算(附魔增益, 装备镶嵌附魔属性)
          })
        }
      }

      // 计算装备增益
      if (该装备属性?.装备特效 && 计算装备特效) {
        let 增益表现 = 1
        if (试炼之地特效枚举?.[该装备属性?.装备特效]) {
          if (该装备属性?.装备品级 >= 本赛季试炼英雄品级) {
            增益表现 = 2
          }
        } else {
          if (
            该装备属性?.装备品级 >= 本赛季英雄品级 &&
            装备特效存在等级?.includes(该装备属性?.装备特效)
          ) {
            增益表现 = 2
          } else if (该装备属性?.装备特效 === 装备特效枚举.大橙武特效) {
            const 品级对应特效等级枚举 = {
              12500: 99, // 12500没有神兵无双buff
              17100: 1,
              18300: 2,
              19500: 3,
              20750: 4,
              22000: 5,
            }
            增益表现 = 品级对应特效等级枚举[该装备属性?.装备品级]
          }
        }
        装备增益原始数据[该装备属性?.装备特效] = {
          数量: (装备增益原始数据[该装备属性?.装备特效]?.数量 || 0) + 1,
          等级: 增益表现,
        }
      }

      if (大附魔部位枚举?.[装备.装备部位]) {
        const 大附魔名称 = 大附魔部位枚举?.[装备.装备部位]
        if (装备信息?.装备增益?.[大附魔名称]) {
          let 增益表现 = 1
          if (该装备属性?.装备品级 >= 本赛季英雄品级) {
            增益表现 = 2
          }
          大附魔增益[大附魔名称] = 增益表现
        } else {
          大附魔增益[大附魔名称] = 0
        }
      }
    }
  })

  let 最终装备增益: 装备增益类型 = { ...装备信息?.装备增益 }
  最终装备增益 = { ...大附魔增益, ...判断装备增益(装备增益原始数据) }

  if (五彩石郭氏增益?.length) {
    最终装备增益 = 判断五彩石郭氏增益(五彩石郭氏增益, 最终装备增益)
  }

  const 装分 = 总装分计算(装备信息)

  return {
    ...装备信息,
    装备基础属性: {
      ...装备基础属性,
      装分,
    },
    装备镶嵌附魔属性,
    装备增益: 最终装备增益,
  }
}

// 计算五彩石属性
export const 五彩石计算 = (
  五彩石名称: string,
  原始属性: 角色基础属性类型
): { 计算后属性: 角色基础属性类型; 五彩石郭氏增益: 属性加成[] } => {
  const 五彩石数据 = 五彩石[5].concat(五彩石[6]).find((数据) => 数据.五彩石名称 === 五彩石名称)
  let 计算后属性 = { ...原始属性 }
  let 五彩石郭氏增益: 属性加成[] = []
  if (五彩石数据) {
    五彩石数据?.装备增益?.forEach((五彩石增益) => {
      if (
        [属性类型.郭氏身法, 属性类型.郭氏力道, 属性类型.郭氏根骨, 属性类型.郭氏元气]?.includes(
          五彩石增益?.增益类型 as any
        )
      ) {
        五彩石郭氏增益 = 五彩石郭氏增益.concat({
          属性: 五彩石增益.增益类型 as any,
          值: 五彩石增益.增益数值,
        })
      } else {
        计算后属性 = 基础属性计算(
          { 属性: 五彩石增益.增益类型 as any, 值: 五彩石增益.增益数值 },
          计算后属性
        )
      }
    })
  }
  return {
    计算后属性,
    五彩石郭氏增益,
  }
}

export const 基础属性计算 = (增益: 属性加成, 原始属性: 角色基础属性类型) => {
  const 最终属性 = {
    郭氏身法: 0,
    郭氏力道: 0,
    郭氏元气: 0,
    郭氏根骨: 0,
    计算目标: {} as any,
    最终人物属性: 原始属性,
    气血加成: { 郭氏体质: 0, 郭氏基础气血上限: 0, 郭氏最终气血上限: 0 },
    技能增伤: {
      全局伤害因子: 1,
      目标移动状增伤: 1,
      通用增伤: 1,
      易伤增伤: 1,
      非侠增伤: 1,
      系数增伤: 1,
    },
    装备特效: {},
    郭氏额外会效果值: 0,
    额外会心率: 0,
    郭氏无视防御: 0,
    郭氏属性无视防御: 0,
    郭氏额外无双等级: 0,
    郭氏破防等级: 0,
    郭氏无双: 0,
    郭氏破招: 0,
    郭氏基础攻击: 0,
    郭氏武器伤害: 0,
  }
  const 计算后属性 = 通用增益计算(增益, 最终属性)?.最终人物属性

  return 计算后属性
}

/**
 * @name 精炼加成系数算法
 */
export const 精炼加成系数算法 = (基础, 等级) => {
  return 基础 + Math.round(基础 * 精炼加成系数[等级])
}

export const 判断装备增益 = (装备增益原始数据: 装备增益原始数据类型): 装备增益类型 => {
  const 装备增益: Partial<装备增益类型> = {}
  Object.keys(装备增益原始数据)?.forEach((该装备特效) => {
    switch (该装备特效) {
      case 装备特效枚举.水特效武器:
      case 装备特效枚举.风特效腰坠:
      case 装备特效枚举.大橙武特效:
      case 装备特效枚举.小橙武特效:
      case 装备特效枚举.龙门飞剑武器:
      case 装备特效枚举.精简鞋子破防:
      case 装备特效枚举.精简适应之力:
      case 装备特效枚举.精简项链破防:
      case 装备特效枚举.精简腰坠破防:
      case 装备特效枚举.精简暗器特效:
      case 装备特效枚举.精简项链会心:
      case 装备特效枚举.精简鞋子会心:
      case 装备特效枚举.精简腰坠会效:
        装备增益[该装备特效] = 装备增益原始数据?.[该装备特效]?.等级 as any
        break
      case 装备特效枚举.门派套装:
        装备增益.套装会心会效 = 装备增益原始数据?.[该装备特效]?.数量 >= 2 ? 1 : 0
        装备增益.套装技能 = 装备增益原始数据?.[该装备特效]?.数量 >= 4 ? 1 : 0
        break
      case 装备特效枚举.冬至套装:
        装备增益.冬至套装 = 装备增益原始数据?.[该装备特效]?.数量 >= 2 ? 1 : 0
        break
      case 装备特效枚举.切糕_普通:
        装备增益.切糕会心 = 装备增益原始数据?.[该装备特效]?.数量 >= 2 ? 1 : 0
        装备增益.切糕无双 = 装备增益原始数据?.[该装备特效]?.数量 >= 4 ? 1 : 0
        break
      case 装备特效枚举.切糕_英雄:
        装备增益.切糕会心_英雄 = 装备增益原始数据?.[该装备特效]?.数量 >= 2 ? 1 : 0
        装备增益.切糕无双_英雄 = 装备增益原始数据?.[该装备特效]?.数量 >= 4 ? 1 : 0
        break
    }
  })
  return {
    ...装备增益,
  }
}

export const 判断五彩石郭氏增益 = (五彩石郭氏增益: 属性加成[], 最终装备增益: 装备增益类型) => {
  let 计算后增益: any = {
    ...最终装备增益,
    郭氏力道: 0,
    郭氏根骨: 0,
    郭氏身法: 0,
    郭氏元气: 0,
  }
  五彩石郭氏增益.forEach((增益) => {
    计算后增益 = 通用增益计算(增益, 计算后增益)
  })
  return 计算后增益
}

export default 根据装备信息获取基础属性
