/**
 * @name 心法模块-山海心诀
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 背景图 from './资源/背景图'
import 默认数据 from './默认数据'
import 技能系数, { 技能增益 } from './技能系数'
import 奇穴数据 from './奇穴'
import 计算循环 from './计算循环'

const 心法配置: 心法配置类型 = {
  名称: '分山劲',
  数据提供: '数据：考拉 神光毓逍遥 唐宋；测试：神光毓逍遥',
  所属门派: '苍云',
  简写: 'fsj',
  主属性: '身法',
  功法: '外功',
  基础属性: { 基础攻击: 6513, 破防等级: 4696 },
  主属性额外加成: { 面板攻击: 1925 / 1024 },
  基础气血加成: 245, // Math.floor((14 * 1 + 10) / 100 * 1024) // 130级心法等级14
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  覆盖率: { 套装会心会效: 0.7 },
  默认数据: 默认数据,
  系统配置: {
    主题色: '#c14c11',
    心法图标: 'https://img.jx3box.com/image/xf/10390.png',
    背景图: 背景图,
    背景色渐变: `linear-gradient(to right, rgba(0, 0, 0, 0.6) 5%, rgba(105, 86, 5, 0.15) 70%)`,
    // 背景色: 'rgba(25, 18, 1, 0.615)',
  },
}

export default 心法配置
