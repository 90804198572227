export const 档位颜色枚举 = [
  '#000',
  '#389e0d',
  '#7cb305',
  '#d4b106',
  '#faad14',
  '#fa8c16',
  '#fa541c',
  '#f5222d',
]
