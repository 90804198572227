/**
 * @name 心法模块-无方
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 背景图 from './资源/背景图'
import 默认数据 from './默认数据'
import 技能系数, { 技能增益 } from './技能系数'
import 奇穴数据 from './奇穴'
import 计算循环 from './计算循环'

const 心法配置: 心法配置类型 = {
  名称: '毒经',
  数据提供: '数据：考拉 莴苣 唐宋；测试：莴苣 淡淡稻花香',
  所属门派: '五毒',
  简写: 'dj',
  主属性: '根骨',
  功法: '内功',
  基础属性: { 基础攻击: 7242 },
  主属性额外加成: { 面板攻击: 2079 / 1024, 破防等级: 205 / 1024 },
  基础气血加成: 245, // Math.floor((14 * 1 + 10) / 100 * 1024) // 130级心法等级14
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  覆盖率: { 套装会心会效: 0.32 },
  系统配置: {
    主题色: '#7F30A2',
    心法图标: 'https://img.jx3box.com/image/xf/10175.png',
    背景图: 背景图,
    背景色渐变: `linear-gradient(to right, rgba(0,0,0,0.6) 5%, rgba(71,0,128, 0.25) 70%)`,
  },
}

export default 心法配置
