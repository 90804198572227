import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 无界通用增伤5函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_5·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (52 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 无界通用增伤8函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_8·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (82 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 无界通用增伤10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70161
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用增伤_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (103 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 无界通用会心10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70162
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用会心_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.1 * item }],
    } as 技能增益列表类型
  })
}

const 无界通用会效10函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 70162
  return 数组.map((item) => {
    return {
      增益名称: `无界_通用会效_10·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏外功会心效果等级, 值: 100 * item }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('外功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 358 / 1024 }],
  },
  {
    增益名称: '无视防御',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 614 }],
  },
  // 套装双会特效
  {
    增益名称: '羽念',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 41 },
    ],
  },
  {
    // 70167_8
    增益名称: '无界_通用无双_8·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 82 }],
  },
  {
    // 71252_1
    增益名称: `澹然若海·悟·1`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 103 / 1024 }],
  },
  {
    // 71252_2
    增益名称: `澹然若海·悟·2`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    // 71252_3
    增益名称: `澹然若海·悟·3`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  ...无界通用增伤5函数(),
  ...无界通用增伤8函数(),
  ...无界通用增伤10函数(),
  ...无界通用会心10函数(),
  ...无界通用会效10函数(),
]

export default 通用增益
