export enum 计算公式计算类型 {
  '基础' = '基础',
  '真实伤害' = '真实伤害',
  '增伤' = '增伤',
  '破防' = '破防',
  '会心' = '会心',
  '等级减伤' = '等级减伤',
  '无双' = '无双',
  '非侠' = '非侠',
  '易伤' = '易伤',
}

export const 默认伤害计算分类: 计算公式计算类型[] = [
  计算公式计算类型.基础,
  计算公式计算类型.真实伤害,
  计算公式计算类型.增伤,
  计算公式计算类型.破防,
  计算公式计算类型.会心,
  计算公式计算类型.等级减伤,
  计算公式计算类型.无双,
  计算公式计算类型.非侠,
  计算公式计算类型.易伤,
]
