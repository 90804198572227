import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '阳池',
        奇穴图片: 'https://icon.jx3box.com/icon/22990.png',
        id: '38465',
        奇穴描述: '“断脉”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '玄气',
        奇穴图片: 'https://icon.jx3box.com/icon/22989.png',
        id: '38466',
        奇穴描述: '“绝脉”标记引爆时伤害提高20%。',
      },
      {
        奇穴名称: '凝阴',
        奇穴图片: 'https://icon.jx3box.com/icon/22988.png',
        id: '38467',
        奇穴描述: '引爆“绝脉”后使目标移动速度降低50%，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '涌泉',
        奇穴图片: 'https://icon.jx3box.com/icon/22951.png',
        id: '38468',
        奇穴描述: '“引窍”受任脉能量增幅伤害由每层3%提升为每层5%。',
      },
      {
        奇穴名称: '浥尘',
        奇穴图片: 'https://icon.jx3box.com/icon/22950.png',
        id: '38469',
        奇穴描述: '“骤风”扩散“雾渺”效果的速度翻倍。',
      },
      {
        奇穴名称: '启霁',
        奇穴图片: 'https://icon.jx3box.com/icon/22949.png',
        id: '38470',
        奇穴描述: '每次施展“雾刃飞光”时，返还1秒“骤风令”调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '疾掠',
        奇穴图片: 'https://icon.jx3box.com/icon/22966.png',
        id: '38471',
        奇穴描述: '“骤风”范围变为8尺，对至多10个目标造成伤害。',
      },
      {
        奇穴名称: '然谷',
        奇穴图片: 'https://icon.jx3box.com/icon/22968.png',
        id: '38472',
        奇穴描述: '未进入“经脉循行”时，“破穴”不再击退，不再造成定身效果，能量回复提高100%。',
      },
      {
        奇穴名称: '封府',
        奇穴图片: 'https://icon.jx3box.com/icon/22965.png',
        id: '38473',
        奇穴描述: '交替施展“断脉”“截阳”时附带一次点额外伤害。',
      },
      {
        奇穴名称: '清激',
        奇穴图片: 'https://icon.jx3box.com/icon/22967.png',
        id: '38474',
        奇穴描述: '“抟风令”调息时间降低10秒，伤害提高100%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '见飓',
        奇穴图片: 'https://icon.jx3box.com/icon/22984.png',
        id: '38480',
        奇穴描述: '“劈风令”变为3层充能招式。',
      },
      {
        奇穴名称: '山雨重楼',
        奇穴图片: 'https://icon.jx3box.com/icon/22878.png',
        id: '38476',
        奇穴描述: '命中运功目标则打断其运功，并使目标进入4秒“迷云”状态。',
      },
      {
        奇穴名称: '反朴',
        奇穴图片: 'https://icon.jx3box.com/icon/22982.png',
        id: '38477',
        奇穴描述: '每次施展“断脉”，返还“截阳”，“引窍”调息时间1秒。',
      },
      {
        奇穴名称: '轻岚',
        奇穴图片: 'https://icon.jx3box.com/icon/22877.png',
        id: '38478',
        奇穴描述: '“雾刃飞光”使目标额外获得1层“雾渺”效果，但不再使4层及以上目标获得“雾渺”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '静霂',
        奇穴图片: 'https://icon.jx3box.com/icon/22986.png',
        id: '38479',
        奇穴描述:
          '“雾刃飞光”第四段额外对自身周围6尺范围造成一次伤害，并对有自身“雾渺”的目标造成沉默效果，层数越高时间越久。',
      },
      {
        奇穴名称: '霏微',
        奇穴图片: 'https://icon.jx3box.com/icon/22946.png',
        id: '38492',
        奇穴描述: '“雾渺”持续时间提高8秒，目标每次施展招式时消除2层“雾渺”。',
      },
      {
        奇穴名称: '神封',
        奇穴图片: 'https://icon.jx3box.com/icon/22974.png',
        id: '38481',
        奇穴描述: '消耗任脉和督脉能量之和不低于30点时，回复5点任脉，5点督脉。',
      },
      {
        奇穴名称: '皎夜',
        奇穴图片: 'https://icon.jx3box.com/icon/22985.png',
        id: '38482',
        奇穴描述: '施展“月落参横”后，可在5秒内施展一次额外的“破穴”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '飒星',
        奇穴图片: 'https://icon.jx3box.com/icon/22957.png',
        id: '38483',
        奇穴描述: '“抟风令”拉扯目标后使目标获得3秒沉默效果。',
      },
      {
        奇穴名称: '悬枢',
        奇穴图片: 'https://icon.jx3box.com/icon/22960.png',
        id: '38484',
        奇穴描述: '“引窍”变为充能技能，充能两层，施展时使目标获得2层“绝脉”。',
      },
      {
        奇穴名称: '太溪',
        奇穴图片: 'https://icon.jx3box.com/icon/22958.png',
        id: '38485',
        奇穴描述: '“截阳”充能时间增加2秒，若命中正在运功的目标，打断目标运功。',
      },
      {
        奇穴名称: '霰珠',
        奇穴图片: 'https://icon.jx3box.com/icon/22959.png',
        id: '38486',
        奇穴描述: '自身“雾海”中的目标被“截阳”命中将定身2秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '泽前',
        奇穴图片: 'https://icon.jx3box.com/icon/22963.png',
        id: '38487',
        奇穴描述:
          '“断脉”命中至少5层“绝脉”的目标时也会引爆5层“绝脉”，引爆后降低目标3%加速率，可叠加3层，持续10秒。',
      },
      {
        奇穴名称: '止蔌',
        奇穴图片: 'https://icon.jx3box.com/icon/22964.png',
        id: '38488',
        奇穴描述: '“劈风令”引发的骤风共振伤害使目标封轻功3秒。',
      },
      {
        奇穴名称: '心俞',
        奇穴图片: 'https://icon.jx3box.com/icon/22962.png',
        id: '38489',
        奇穴描述: '“破穴”命中后目标10秒无法展开阵眼。',
      },
      {
        奇穴名称: '神门',
        奇穴图片: 'https://icon.jx3box.com/icon/22961.png',
        id: '38490',
        奇穴描述: '“经脉循行”状态下施展“破穴”后，内功基础攻击力提高20%，无双率提高15%，持续23秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '朔风扬尘',
        奇穴图片: 'https://icon.jx3box.com/icon/22874.png',
        id: '38549',
        奇穴描述:
          '跃起并获得7层“朔风扬尘”效果，技能替换为二段招式扬尘，扬尘公共调息时间0.6秒，“朔风扬尘”持续时间内可以连续施展，延迟0.5秒后向目标位置造成点矩形范围伤害，并回复5点任脉5点督脉能量，被扬尘重复命中则受到的伤害将逐步递增。每次释放还会获得可叠加层数的“扬尘”效果：“经脉循行”期间任脉和督脉能量回复增加1点，每次施展“一阳指”套路招式时消耗1层。',
      },
      {
        奇穴名称: '纷飙',
        奇穴图片: 'https://icon.jx3box.com/icon/22983.png',
        id: '38475',
        奇穴描述: '“劈风令”引发的骤风共振伤害提高20%，每次施展“劈风令”返还5%“抟风令”剩余调息时间。',
      },
      {
        奇穴名称: '零露',
        奇穴图片: 'https://icon.jx3box.com/icon/22947.png',
        id: '38493',
        奇穴描述: '获得“雪重”时回复15%最大气血值，消耗的“雪重花满”持续时间变为4秒。',
      },
      {
        奇穴名称: '通里',
        奇穴图片: 'https://icon.jx3box.com/icon/22948.png',
        id: '38494',
        奇穴描述: '“破穴”命中带有自身10层及以上绝脉目标时，消耗8层绝脉使目标禁疗3秒。',
      },
      {
        奇穴名称: '出岫',
        奇穴图片: 'https://icon.jx3box.com/icon/22976.png',
        id: '38495',
        奇穴描述:
          '“风流云散”变为18秒2层充能招式，施展后只能获得1层云散，并获得2秒“出岫”效果：伤害提高15%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '梨盏',
        奇穴图片: 'https://icon.jx3box.com/icon/22952.png',
        id: '38496',
        奇穴描述: '“雪重花满”期间免疫击退，免疫重创。',
      },
      {
        奇穴名称: '意舍',
        奇穴图片: 'https://icon.jx3box.com/icon/22954.png',
        id: '38497',
        奇穴描述: '战斗中若4秒未受到任何伤害，则任脉、督脉能量回复10点。',
      },
      {
        奇穴名称: '流絮',
        奇穴图片: 'https://icon.jx3box.com/icon/22953.png',
        id: '38498',
        奇穴描述: '“雪重花满”期间免封内。',
      },
      {
        奇穴名称: '郁潆',
        奇穴图片: 'https://icon.jx3box.com/icon/22956.png',
        id: '38499',
        奇穴描述: '自身“雾海萦空”范围内的敌对玩家目标基础攻击力和治疗量降低30%。',
      },
      {
        奇穴名称: '玉枕',
        奇穴图片: 'https://icon.jx3box.com/icon/22955.png',
        id: '38500',
        奇穴描述: '“经脉循行”期间，无视目标40%内功防御。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '茫缈',
        奇穴图片: 'https://icon.jx3box.com/icon/22971.png',
        id: '38501',
        奇穴描述:
          '“骤风”对静止目标伤害增加35%，施展“骤风令”后2秒内可施展一次额外不占基础调息的“劈风令”。',
      },
      {
        奇穴名称: '卫分',
        奇穴图片: 'https://icon.jx3box.com/icon/22972.png',
        id: '38502',
        奇穴描述: '“断脉”命中无自身“绝脉”效果的目标时，额外回复15点任脉。',
      },
      {
        奇穴名称: '百会',
        奇穴图片: 'https://icon.jx3box.com/icon/22969.png',
        id: '38503',
        奇穴描述: '每次“绝脉”被引爆时，依据引爆的层数使目标获得10%-60%的减疗效果，持续4秒。',
      },
      {
        奇穴名称: '既晓',
        奇穴图片: 'https://icon.jx3box.com/icon/22970.png',
        id: '38504',
        奇穴描述:
          '施展“风流云散”卸除自身除阳性不利效果、阴性不利效果、混元不利效果、毒性不利效果一次，非战斗状态下施展调息时间降低10秒。',
      },
      {
        奇穴名称: '滃从',
        奇穴图片: 'https://icon.jx3box.com/icon/22973.png',
        id: '38505',
        奇穴描述: '“雾刃飞光”造成伤害每次递增20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '欺霜',
        奇穴图片: 'https://icon.jx3box.com/icon/22979.png',
        id: '38506',
        奇穴描述: '“雪重”期间施展“断脉”造成一次点额外伤害。',
      },
      {
        奇穴名称: '摧烟',
        奇穴图片: 'https://icon.jx3box.com/icon/22977.png',
        id: '38507',
        奇穴描述: '施展“抟风令”后可以刷新“劈风令”调息时间，“劈风令”对目标范围造成一次点额外伤害。',
      },
      {
        奇穴名称: '丘墟',
        奇穴图片: 'https://icon.jx3box.com/icon/22981.png',
        id: '38508',
        奇穴描述: '经脉循行状态下施展“引窍”，重置“引窍”调息时间。',
      },
      {
        奇穴名称: '灵台',
        奇穴图片: 'https://icon.jx3box.com/icon/22978.png',
        id: '38509',
        奇穴描述: '“绝脉”可叠加的层数增加5层，引爆10层及以上时造成一次额外伤害。',
      },
      {
        奇穴名称: '青霭',
        奇穴图片: 'https://icon.jx3box.com/icon/22980.png',
        id: '38510',
        奇穴描述: '对在自身“雾海”中的目标施展“雾刃飞光”后，降低“雾暗迷云”调息时间3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '一阳来复',
        奇穴图片: 'https://icon.jx3box.com/icon/22876.png',
        id: '38511',
        奇穴描述: '获得50点任脉能量和50点督脉能量，施展破穴时，降低8秒“一阳来复”调息时间。',
      },
      {
        奇穴名称: '飞鸿破野',
        奇穴图片: 'https://icon.jx3box.com/icon/22991.png',
        id: '38981',
        奇穴描述:
          '对目标造成僵直控制效果，并造成4段“飞鸿”伤害，之后对自身周围至多6个目标造成“破野”伤害。“破野”对位移被击状态目标造成伤害提高40%。',
      },
      {
        奇穴名称: '徙溟',
        奇穴图片: 'https://icon.jx3box.com/icon/22975.png',
        id: '38513',
        奇穴描述:
          '“抟风令”形成的狂风会吸收属于自身的“雾海”，若成功吸收则不再使目标范围的目标被吹飞而是获得5秒“迷云”。',
      },
      {
        奇穴名称: '相息',
        奇穴图片: 'https://icon.jx3box.com/icon/22945.png',
        id: '38514',
        奇穴描述: '“经脉循行”状态下能量回复增加至5点。',
      },
      {
        奇穴名称: '胧雾观花',
        奇穴图片: 'https://icon.jx3box.com/icon/22875.png',
        id: '38515',
        奇穴描述:
          '被动效果：拥有效果“云散”时，每次闪烁前会创造一个雾气分身和你一同施展“一阳指”，造成伤害并且使目标“雾渺”层数翻倍。&lt;br/&gt;主动效果：获得4层“云散”。',
      },
    ],
  },
]

export default 奇穴数据
