import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 宠物增益 from './通用增益/宠物增益'

const 灵蛇增益: 技能增益列表类型[] = [
  ...宠物增益,
  // -17988
  {
    增益名称: '曲致',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.3 }],
  },
]

export default 灵蛇增益
