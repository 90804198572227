import { 角色基础属性类型 } from '@/@types/角色'
import { 最终计算属性类型 } from '@/@types/计算'
import { 获取郭氏加成值 } from '@/工具函数/data'
import { INT } from '@/工具函数/help'
import { 基础系数 } from '@/数据/常量'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'

const { 基础气血加成: 心法基础气血加成 = 0 } = 获取当前数据()

/**
 * 基础体质 = 体质 + 体质数值增/减益效果和
 * 最终体质 = 基础体质 + 基础体质 * 体质%提升和
 * 基础气血值 = 原始基础气血值 + 最终体质转化气血
 * 最终气血值 = { 基础气血值 *（ 1 + 心法提升% + 常规气血上限增/减益%和 ）+ 心法体质额外转化气血值 + 额外气血值 } * （ 1 + 最终气血上限增/减益%和）
 */
export const 计算气血上限函数 = (
  计算人物面板: 角色基础属性类型,
  最终计算属性: Partial<最终计算属性类型>
) => {
  const 最终体质 = 获取郭氏加成值(计算人物面板.体质, 最终计算属性?.气血加成?.郭氏体质)
  const 基础气血值 = 计算人物面板.基础气血上限 + INT(最终体质 * 基础系数.体质转气血)
  const 最终基础气血值 = 获取郭氏加成值(
    基础气血值,
    (最终计算属性?.气血加成?.郭氏基础气血上限 || 0) + 心法基础气血加成
  )
  // 只有T职业有
  // 心法体质额外转气血值
  // 最终基础气血值 + 心法体质额外转气血值 + 额外气血值
  const 最终气血值 = 最终基础气血值 + (计算人物面板.额外气血上限 || 0)
  const 计算最终额外加成气血值 = 获取郭氏加成值(
    最终气血值,
    最终计算属性?.气血加成?.郭氏最终气血上限 || 0
  )

  return {
    最终体质,
    计算最终额外加成气血值,
  }
}
