import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

const 雪絮金屏增益: 技能增益列表类型[] = [
  {
    // 23066_1
    增益名称: '含风·1',
    依赖奇穴: '含风',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    // 23066_2
    增益名称: '含风·2',
    依赖奇穴: '含风',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
]

export default 雪絮金屏增益
