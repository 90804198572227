import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 留客雨悟增益 from './技能增益/留客雨悟增益'
import 决云势悟增益 from './技能增益/决云势悟增益'
import 断云势悟增益 from './技能增益/断云势悟增益'
import 行云势悟增益 from './技能增益/行云势悟增益'
import 孤风破浪悟增益 from './技能增益/孤风破浪悟增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import { 计算公式计算类型 } from '@/@types/伤害计算'

// 102222
const 流血基础系数 = {
  统计名称: '流血(DOT)',
  技能伤害系数: 336,
  DOT生效间隔: 32,
  DOT跳数: 9,
  技能增益列表: 通用增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: 101381,
    技能名称: '行云势·悟·一',
    统计名称: '行云势·悟',
    技能伤害系数: 300,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 行云势悟增益,
  },
  {
    技能ID: 102082,
    技能名称: '行云势·悟·二',
    统计名称: '行云势·悟',
    技能伤害系数: 306,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 行云势悟增益,
  },
  {
    技能ID: 102083,
    技能名称: '行云势·悟·三',
    统计名称: '行云势·悟',
    技能伤害系数: 314,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 行云势悟增益,
  },
  {
    技能ID: 101448,
    技能名称: '决云势·悟·一',
    统计名称: '决云势·悟',
    技能伤害系数: 530,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 决云势悟增益,
  },
  {
    技能ID: 102226,
    技能名称: '决云势·悟·二',
    统计名称: '决云势·悟',
    技能伤害系数: 386,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 决云势悟增益,
  },
  {
    技能ID: 101393,
    技能名称: '停云势·悟',
    技能伤害系数: 885,
    基础伤害_基础值: 1,
    武器伤害系数: 2048 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 101385,
    技能名称: '横云势·悟·一',
    统计名称: '横云势·悟',
    技能伤害系数: 191,
    基础伤害_基础值: 1,
    武器伤害系数: 0 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: 102220,
    技能名称: '横云势·悟·二', // 横云势二段
    统计名称: '横云势·悟',
    技能伤害系数: 319,
    技能破招系数: 1219 / 1024,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 断云势悟增益,
  },
  {
    技能ID: 101388,
    技能名称: '留客雨·悟',
    技能伤害系数: 512,
    基础伤害_基础值: 1,
    武器伤害系数: 1024 / 1024,
    技能增益列表: 留客雨悟增益,
  },
  {
    技能ID: 101395,
    技能名称: '孤风破浪·悟',
    统计名称: '孤风破浪·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 956,
    武器伤害系数: 2048 / 1024,
    技能增益列表: 孤风破浪悟增益,
  },
  // lua 移动端_孤风破浪_刀气每跳AOE周围 中 没有调用 CALL_PHYSICS_DAMAGE 刀气本体没伤害
  {
    技能ID: 101527,
    技能名称: '截辕·悟',
    统计名称: '孤风破浪·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 538,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 101395,
    技能名称: '截辕·悟(DOT)',
    技能伤害系数: 864,
    DOT生效间隔: 32,
    DOT跳数: 6,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '流血·悟·一',
    ...流血基础系数,
  },
  {
    技能名称: '流血·悟·二',
    ...流血基础系数,
    伤害计算次数: 2,
  },
  {
    技能名称: '流血·悟·三',
    ...流血基础系数,
    伤害计算次数: 3,
  },
  // -------
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
