import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰带装备数据: 装备属性信息模型[] = [
  {
    id: 103787,
    uid: 103787,
    装备名称: '陶然意·月容腰带',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103784,
    uid: 103784,
    装备名称: '陶然意·修茂腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103783,
    uid: 103783,
    装备名称: '陶然意·理乾腰带',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103779,
    uid: 103779,
    装备名称: '陶然意·来仪腰带',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103777,
    uid: 103777,
    装备名称: '陶然意·凡圣腰带',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103773,
    uid: 103773,
    装备名称: '陶然意·连筠腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.外功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103772,
    uid: 103772,
    装备名称: '陶然意·蛇言腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103771,
    uid: 103771,
    装备名称: '陶然意·寒河腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103769,
    uid: 103769,
    装备名称: '陶然意·清静腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103767,
    uid: 103767,
    装备名称: '陶然意·簇锦腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103766,
    uid: 103766,
    装备名称: '陶然意·祇树腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 766 },
      { 属性: 属性类型.内功基础攻击, 值: 1388 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.全能等级, 值: 2756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 101597,
    uid: 101597,
    装备名称: '花念腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.元气, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.全会心等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101596,
    uid: 101596,
    装备名称: '花深腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10107 },
      { 属性: 属性类型.根骨, 值: 825 },
      { 属性: 属性类型.内功基础攻击, 值: 2082 },
      { 属性: 属性类型.内功会心等级, 值: 6891 },
      { 属性: 属性类型.无双等级, 值: 5513 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104333,
    uid: 104333,
    装备名称: '平陌腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 2734 },
      { 属性: 属性类型.破招值, 值: 10406 },
      { 属性: 属性类型.无双等级, 值: 6786 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101569,
    uid: 101569,
    装备名称: '松悲腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.内功破防等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101568,
    uid: 101568,
    装备名称: '经岁腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.全会心等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101567,
    uid: 101567,
    装备名称: '枕簟腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3965 },
      { 属性: 属性类型.内功破防等级, 值: 4524 },
      { 属性: 属性类型.破招值, 值: 4524 },
      { 属性: 属性类型.无双等级, 值: 4524 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101566,
    uid: 101566,
    装备名称: '独还腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 4648 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101531,
    uid: 101531,
    装备名称: '青桑腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101530,
    uid: 101530,
    装备名称: '季春腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101495,
    uid: 101495,
    装备名称: '游山带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101494,
    uid: 101494,
    装备名称: '思玉带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101394,
    uid: 101394,
    装备名称: '孤漠·织春腰带',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101390,
    uid: 101390,
    装备名称: '孤漠·竹烟腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101389,
    uid: 101389,
    装备名称: '孤漠·星思腰带',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101384,
    uid: 101384,
    装备名称: '孤漠·玉寒腰带',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101380,
    uid: 101380,
    装备名称: '孤漠·星野腰带',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101376,
    uid: 101376,
    装备名称: '孤漠·雾山腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101374,
    uid: 101374,
    装备名称: '孤漠·芳丛腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101372,
    uid: 101372,
    装备名称: '孤漠·染晴腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101370,
    uid: 101370,
    装备名称: '孤漠·云岫腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101366,
    uid: 101366,
    装备名称: '孤漠·梅曳腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101364,
    uid: 101364,
    装备名称: '孤漠·闻闲腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101244,
    uid: 101244,
    装备名称: '漠晖腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101243,
    uid: 101243,
    装备名称: '漠安腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103625,
    uid: 103625,
    装备名称: '陶然意·月容腰带',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103622,
    uid: 103622,
    装备名称: '陶然意·修茂腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103621,
    uid: 103621,
    装备名称: '陶然意·理乾腰带',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103617,
    uid: 103617,
    装备名称: '陶然意·来仪腰带',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103615,
    uid: 103615,
    装备名称: '陶然意·凡圣腰带',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103611,
    uid: 103611,
    装备名称: '陶然意·连筠腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.外功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103610,
    uid: 103610,
    装备名称: '陶然意·蛇言腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103609,
    uid: 103609,
    装备名称: '陶然意·寒河腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103607,
    uid: 103607,
    装备名称: '陶然意·清静腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103605,
    uid: 103605,
    装备名称: '陶然意·簇锦腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103604,
    uid: 103604,
    装备名称: '陶然意·祇树腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8685 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1193 },
      { 属性: 属性类型.内功会心等级, 值: 5922 },
      { 属性: 属性类型.全能等级, 值: 2369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 103493,
    uid: 103493,
    装备名称: '孤山寒月·忧生腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 103492,
    uid: 103492,
    装备名称: '孤山寒月·风吟腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101202,
    uid: 101202,
    装备名称: '映宵腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101201,
    uid: 101201,
    装备名称: '云眷腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101166,
    uid: 101166,
    装备名称: '江汜带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101165,
    uid: 101165,
    装备名称: '合苏带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101065,
    uid: 101065,
    装备名称: '西塞·沉醉护腰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101061,
    uid: 101061,
    装备名称: '西塞·银筱腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101060,
    uid: 101060,
    装备名称: '西塞·清浊护腰',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101055,
    uid: 101055,
    装备名称: '西塞·青弦护腰',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101051,
    uid: 101051,
    装备名称: '西塞·大荒护腰',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101047,
    uid: 101047,
    装备名称: '西塞·寒蛩护腰',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101045,
    uid: 101045,
    装备名称: '西塞·朝愁腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101043,
    uid: 101043,
    装备名称: '西塞·烟蘸腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101041,
    uid: 101041,
    装备名称: '西塞·雪欹腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101037,
    uid: 101037,
    装备名称: '西塞·合章腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101035,
    uid: 101035,
    装备名称: '西塞·彼岸腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100981,
    uid: 100981,
    装备名称: '寻踪觅宝·中林腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100980,
    uid: 100980,
    装备名称: '寻踪觅宝·谷风腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100951,
    uid: 100951,
    装备名称: '寻踪觅宝·月诸腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100950,
    uid: 100950,
    装备名称: '寻踪觅宝·陟南腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100789,
    uid: 100789,
    装备名称: '语宇腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.元气, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.内功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100788,
    uid: 100788,
    装备名称: '语郁腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.根骨, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.内功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104506,
    uid: 104506,
    装备名称: '昭文腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104505,
    uid: 104505,
    装备名称: '丘墟腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104460,
    uid: 104460,
    装备名称: '斛尘腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104459,
    uid: 104459,
    装备名称: '熙春腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104118,
    uid: 104118,
    装备名称: '玉凉腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104117,
    uid: 104117,
    装备名称: '樛枝腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100885,
    uid: 100885,
    装备名称: '昭文腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100884,
    uid: 100884,
    装备名称: '丘墟腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100849,
    uid: 100849,
    装备名称: '玉凉腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100848,
    uid: 100848,
    装备名称: '樛枝腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100813,
    uid: 100813,
    装备名称: '斛尘腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100812,
    uid: 100812,
    装备名称: '熙春腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100681,
    uid: 100681,
    装备名称: '弥弦带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100680,
    uid: 100680,
    装备名称: '珀音带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100645,
    uid: 100645,
    装备名称: '霖瑜带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100644,
    uid: 100644,
    装备名称: '瑾辞带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1082 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100765,
    uid: 100765,
    装备名称: '语宇腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.元气, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.内功破防等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100764,
    uid: 100764,
    装备名称: '语郁腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7455 },
      { 属性: 属性类型.根骨, 值: 608 },
      { 属性: 属性类型.内功基础攻击, 值: 1536 },
      { 属性: 属性类型.内功破防等级, 值: 5083 },
      { 属性: 属性类型.无双等级, 值: 4067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100609,
    uid: 100609,
    装备名称: '惜晗带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.元气, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.内功破防等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100608,
    uid: 100608,
    装备名称: '濯缨带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7225 },
      { 属性: 属性类型.根骨, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1489 },
      { 属性: 属性类型.内功破防等级, 值: 4926 },
      { 属性: 属性类型.无双等级, 值: 3941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100741,
    uid: 100741,
    装备名称: '语宇腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.元气, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.内功破防等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100740,
    uid: 100740,
    装备名称: '语郁腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6956 },
      { 属性: 属性类型.根骨, 值: 567 },
      { 属性: 属性类型.内功基础攻击, 值: 1433 },
      { 属性: 属性类型.内功破防等级, 值: 4743 },
      { 属性: 属性类型.无双等级, 值: 3794 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100573,
    uid: 100573,
    装备名称: '绢素护腰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.全会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100572,
    uid: 100572,
    装备名称: '烨霖护腰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6764 },
      { 属性: 属性类型.根骨, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1393 },
      { 属性: 属性类型.内功会心等级, 值: 4612 },
      { 属性: 属性类型.无双等级, 值: 3689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100717,
    uid: 100717,
    装备名称: '语宇腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.元气, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.内功破防等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100716,
    uid: 100716,
    装备名称: '语郁腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6456 },
      { 属性: 属性类型.根骨, 值: 527 },
      { 属性: 属性类型.内功基础攻击, 值: 1330 },
      { 属性: 属性类型.内功破防等级, 值: 4402 },
      { 属性: 属性类型.无双等级, 值: 3522 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101837,
    uid: 101837,
    装备名称: '轻桡带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101836,
    uid: 101836,
    装备名称: '临流带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1306 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.无双等级, 值: 3459 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100537,
    uid: 100537,
    装备名称: '凝玥腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.元气, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100536,
    uid: 100536,
    装备名称: '静漪腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6341 },
      { 属性: 属性类型.根骨, 值: 480 },
      { 属性: 属性类型.内功基础攻击, 值: 871 },
      { 属性: 属性类型.内功破防等级, 值: 4323 },
      { 属性: 属性类型.全能等级, 值: 1729 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
]

export default 腰带装备数据
