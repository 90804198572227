import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 42545,
    uid: 42545,
    装备名称: '陶然意·月容链',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42542,
    uid: 42542,
    装备名称: '陶然意·修茂链',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42541,
    uid: 42541,
    装备名称: '陶然意·理乾链',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42537,
    uid: 42537,
    装备名称: '陶然意·来仪链',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42535,
    uid: 42535,
    装备名称: '陶然意·凡圣链',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42531,
    uid: 42531,
    装备名称: '陶然意·连筠链',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42530,
    uid: 42530,
    装备名称: '陶然意·蛇言链',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42529,
    uid: 42529,
    装备名称: '陶然意·寒河链',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42527,
    uid: 42527,
    装备名称: '陶然意·清静链',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42525,
    uid: 42525,
    装备名称: '陶然意·簇锦链',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42524,
    uid: 42524,
    装备名称: '陶然意·祇树链',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 41412,
    uid: 41412,
    装备名称: '江空链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1487 },
      { 属性: 属性类型.破招值, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41411,
    uid: 41411,
    装备名称: '山深链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1487 },
      { 属性: 属性类型.破招值, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41350,
    uid: 41350,
    装备名称: '清槐项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41349,
    uid: 41349,
    装备名称: '孟春项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41332,
    uid: 41332,
    装备名称: '青桑项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41331,
    uid: 41331,
    装备名称: '季春项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41314,
    uid: 41314,
    装备名称: '游山链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41313,
    uid: 41313,
    装备名称: '思玉链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41296,
    uid: 41296,
    装备名称: '漠晖链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41295,
    uid: 41295,
    装备名称: '漠安链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42829,
    uid: 42829,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.内功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42828,
    uid: 42828,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.全会心等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42827,
    uid: 42827,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2307 },
      { 属性: 属性类型.内功破防等级, 值: 7318 },
      { 属性: 属性类型.无双等级, 值: 3818 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41509,
    uid: 41509,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.内功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41508,
    uid: 41508,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.全会心等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41507,
    uid: 41507,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2307 },
      { 属性: 属性类型.内功破防等级, 值: 7318 },
      { 属性: 属性类型.无双等级, 值: 3818 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42817,
    uid: 42817,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2679 },
      { 属性: 属性类型.全会心等级, 值: 4585 },
      { 属性: 属性类型.全会心效果等级, 值: 2293 },
      { 属性: 属性类型.破招值, 值: 2293 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42816,
    uid: 42816,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 1847 },
      { 属性: 属性类型.破招值, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 4585 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42815,
    uid: 42815,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.全会心等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41497,
    uid: 41497,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2679 },
      { 属性: 属性类型.全会心等级, 值: 4585 },
      { 属性: 属性类型.全会心效果等级, 值: 2293 },
      { 属性: 属性类型.破招值, 值: 2293 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41496,
    uid: 41496,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 1847 },
      { 属性: 属性类型.破招值, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 4585 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41495,
    uid: 41495,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简项链会心,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.全会心等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41400,
    uid: 41400,
    装备名称: '江空链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.元气, 值: 546 },
      { 属性: 属性类型.内功基础攻击, 值: 1380 },
      { 属性: 属性类型.破招值, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41399,
    uid: 41399,
    装备名称: '山深链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.根骨, 值: 546 },
      { 属性: 属性类型.内功基础攻击, 值: 1380 },
      { 属性: 属性类型.破招值, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 42805,
    uid: 42805,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 2932 },
      { 属性: 属性类型.破招值, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42804,
    uid: 42804,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.内功破防等级, 值: 3225 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42803,
    uid: 42803,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 3012 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41485,
    uid: 41485,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 2932 },
      { 属性: 属性类型.破招值, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41484,
    uid: 41484,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.内功破防等级, 值: 3225 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41483,
    uid: 41483,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 3012 },
      { 属性: 属性类型.无双等级, 值: 6451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42464,
    uid: 42464,
    装备名称: '陶然意·月容链',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42461,
    uid: 42461,
    装备名称: '陶然意·修茂链',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42460,
    uid: 42460,
    装备名称: '陶然意·理乾链',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42456,
    uid: 42456,
    装备名称: '陶然意·来仪链',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42454,
    uid: 42454,
    装备名称: '陶然意·凡圣链',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42450,
    uid: 42450,
    装备名称: '陶然意·连筠链',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 42449,
    uid: 42449,
    装备名称: '陶然意·蛇言链',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42448,
    uid: 42448,
    装备名称: '陶然意·寒河链',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42446,
    uid: 42446,
    装备名称: '陶然意·清静链',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42444,
    uid: 42444,
    装备名称: '陶然意·簇锦链',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42443,
    uid: 42443,
    装备名称: '陶然意·祇树链',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42793,
    uid: 42793,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.内功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42792,
    uid: 42792,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.全会心等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42791,
    uid: 42791,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2036 },
      { 属性: 属性类型.内功破防等级, 值: 6457 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41473,
    uid: 41473,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.内功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41472,
    uid: 41472,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.全会心等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41471,
    uid: 41471,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2036 },
      { 属性: 属性类型.内功破防等级, 值: 6457 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41388,
    uid: 41388,
    装备名称: '江空链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41387,
    uid: 41387,
    装备名称: '山深链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41284,
    uid: 41284,
    装备名称: '疏风项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41283,
    uid: 41283,
    装备名称: '锦官项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41266,
    uid: 41266,
    装备名称: '映宵项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41265,
    uid: 41265,
    装备名称: '云眷项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41248,
    uid: 41248,
    装备名称: '江汜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41247,
    uid: 41247,
    装备名称: '合苏链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42781,
    uid: 42781,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 2362 },
      { 属性: 属性类型.全会心等级, 值: 4043 },
      { 属性: 属性类型.全会心效果等级, 值: 2021 },
      { 属性: 属性类型.破招值, 值: 2021 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42780,
    uid: 42780,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1629 },
      { 属性: 属性类型.破招值, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42779,
    uid: 42779,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.全会心等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41461,
    uid: 41461,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 2362 },
      { 属性: 属性类型.全会心等级, 值: 4043 },
      { 属性: 属性类型.全会心效果等级, 值: 2021 },
      { 属性: 属性类型.破招值, 值: 2021 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41460,
    uid: 41460,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1629 },
      { 属性: 属性类型.破招值, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41459,
    uid: 41459,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.全会心等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41236,
    uid: 41236,
    装备名称: '壁镜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41235,
    uid: 41235,
    装备名称: '壁观链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42769,
    uid: 42769,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 2583 },
      { 属性: 属性类型.破招值, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42768,
    uid: 42768,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 1873 },
      { 属性: 属性类型.内功破防等级, 值: 2841 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42767,
    uid: 42767,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2654 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41449,
    uid: 41449,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 2583 },
      { 属性: 属性类型.破招值, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41448,
    uid: 41448,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 1873 },
      { 属性: 属性类型.内功破防等级, 值: 2841 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41447,
    uid: 41447,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2654 },
      { 属性: 属性类型.无双等级, 值: 5682 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41164,
    uid: 41164,
    装备名称: '语任链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41163,
    uid: 41163,
    装备名称: '语若链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41376,
    uid: 41376,
    装备名称: '江空链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.元气, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41375,
    uid: 41375,
    装备名称: '山深链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.根骨, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 42864,
    uid: 42864,
    装备名称: '玉凉链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42863,
    uid: 42863,
    装备名称: '樛枝链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42846,
    uid: 42846,
    装备名称: '斛尘链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42845,
    uid: 42845,
    装备名称: '熙春链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42734,
    uid: 42734,
    装备名称: '灵空·未判链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42733,
    uid: 42733,
    装备名称: '灵空·心斋链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42621,
    uid: 42621,
    装备名称: '昭文链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42620,
    uid: 42620,
    装备名称: '丘墟链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41218,
    uid: 41218,
    装备名称: '昭文链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41217,
    uid: 41217,
    装备名称: '丘墟链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41200,
    uid: 41200,
    装备名称: '玉凉链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41199,
    uid: 41199,
    装备名称: '樛枝链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41182,
    uid: 41182,
    装备名称: '斛尘链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41181,
    uid: 41181,
    装备名称: '熙春链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41092,
    uid: 41092,
    装备名称: '弥弦链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41091,
    uid: 41091,
    装备名称: '珀音链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41074,
    uid: 41074,
    装备名称: '霖瑜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41073,
    uid: 41073,
    装备名称: '瑾辞链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 42757,
    uid: 42757,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.内功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42756,
    uid: 42756,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.全会心等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42755,
    uid: 42755,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 1792 },
      { 属性: 属性类型.内功破防等级, 值: 5682 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41437,
    uid: 41437,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.内功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41436,
    uid: 41436,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.全会心等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41435,
    uid: 41435,
    装备名称: '无修链·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 1792 },
      { 属性: 属性类型.内功破防等级, 值: 5682 },
      { 属性: 属性类型.无双等级, 值: 2965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41146,
    uid: 41146,
    装备名称: '语任链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.元气, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.内功破防等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41145,
    uid: 41145,
    装备名称: '语若链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.根骨, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.内功破防等级, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41056,
    uid: 41056,
    装备名称: '惜晗链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.元气, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41055,
    uid: 41055,
    装备名称: '濯缨链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.根骨, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41128,
    uid: 41128,
    装备名称: '语任链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.元气, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.内功破防等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41127,
    uid: 41127,
    装备名称: '语若链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.根骨, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.内功破防等级, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41038,
    uid: 41038,
    装备名称: '绢素链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.元气, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.全会心等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41037,
    uid: 41037,
    装备名称: '烨霖链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.根骨, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.内功会心等级, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41110,
    uid: 41110,
    装备名称: '语任链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.元气, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.内功破防等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41109,
    uid: 41109,
    装备名称: '语若链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.根骨, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.内功破防等级, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41520,
    uid: 41520,
    装备名称: '轻桡链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41519,
    uid: 41519,
    装备名称: '临流链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41020,
    uid: 41020,
    装备名称: '凝玥链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41019,
    uid: 41019,
    装备名称: '静漪链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
]

export default 项链装备数据
