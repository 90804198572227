import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 衣服装备数据: 装备属性信息模型[] = [
  {
    id: 103874,
    uid: 103874,
    装备名称: '陶然意·拥狸衣',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103873,
    uid: 103873,
    装备名称: '陶然意·万仞衣',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103870,
    uid: 103870,
    装备名称: '陶然意·徇节衣',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103869,
    uid: 103869,
    装备名称: '陶然意·临溟衣',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103868,
    uid: 103868,
    装备名称: '陶然意·上造衣',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103866,
    uid: 103866,
    装备名称: '陶然意·征惯衣',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103864,
    uid: 103864,
    装备名称: '陶然意·春瓮衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103863,
    uid: 103863,
    装备名称: '陶然意·吴陵衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103862,
    uid: 103862,
    装备名称: '陶然意·穿心衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103858,
    uid: 103858,
    装备名称: '陶然意·蜕羽衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103856,
    uid: 103856,
    装备名称: '陶然意·登锋衣',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 101623,
    uid: 101623,
    装备名称: '花永衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1178 },
      { 属性: 属性类型.外功基础攻击, 值: 2675 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101622,
    uid: 101622,
    装备名称: '花世衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1178 },
      { 属性: 属性类型.外功基础攻击, 值: 2675 },
      { 属性: 属性类型.外功破防等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101557,
    uid: 101557,
    装备名称: '羡雁衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101556,
    uid: 101556,
    装备名称: '君深衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101521,
    uid: 101521,
    装备名称: '留诗衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101520,
    uid: 101520,
    装备名称: '清月衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101486,
    uid: 101486,
    装备名称: '孤漠·阑影衣',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101485,
    uid: 101485,
    装备名称: '孤漠·苍雨衣',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101481,
    uid: 101481,
    装备名称: '孤漠·狂澜衣',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101480,
    uid: 101480,
    装备名称: '孤漠·舟帆衣',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101479,
    uid: 101479,
    装备名称: '孤漠·庭月衣',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101475,
    uid: 101475,
    装备名称: '孤漠·雁际战甲',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101472,
    uid: 101472,
    装备名称: '孤漠·泛浪衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101471,
    uid: 101471,
    装备名称: '孤漠·寒鸣衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101470,
    uid: 101470,
    装备名称: '孤漠·照江衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101464,
    uid: 101464,
    装备名称: '孤漠·立霄衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101461,
    uid: 101461,
    装备名称: '孤漠·踏沙战甲',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101270,
    uid: 101270,
    装备名称: '漠名衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101269,
    uid: 101269,
    装备名称: '漠若衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103712,
    uid: 103712,
    装备名称: '陶然意·拥狸衣',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103711,
    uid: 103711,
    装备名称: '陶然意·万仞衣',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103708,
    uid: 103708,
    装备名称: '陶然意·徇节衣',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103707,
    uid: 103707,
    装备名称: '陶然意·临溟衣',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103706,
    uid: 103706,
    装备名称: '陶然意·上造衣',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103704,
    uid: 103704,
    装备名称: '陶然意·征惯衣',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103702,
    uid: 103702,
    装备名称: '陶然意·春瓮衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103701,
    uid: 103701,
    装备名称: '陶然意·吴陵衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103700,
    uid: 103700,
    装备名称: '陶然意·穿心衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103696,
    uid: 103696,
    装备名称: '陶然意·蜕羽衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103694,
    uid: 103694,
    装备名称: '陶然意·登锋衣',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103519,
    uid: 103519,
    装备名称: '孤山寒月·逐刃衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103518,
    uid: 103518,
    装备名称: '孤山寒月·归期衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101228,
    uid: 101228,
    装备名称: '扬明衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101227,
    uid: 101227,
    装备名称: '重悬衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101192,
    uid: 101192,
    装备名称: '静言衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101191,
    uid: 101191,
    装备名称: '棣仪衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101157,
    uid: 101157,
    装备名称: '西塞·离巢衫',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101156,
    uid: 101156,
    装备名称: '西塞·秋星衫',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101152,
    uid: 101152,
    装备名称: '西塞·麟台衫',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101151,
    uid: 101151,
    装备名称: '西塞·川冥衫',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101150,
    uid: 101150,
    装备名称: '西塞·流电衫',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101146,
    uid: 101146,
    装备名称: '西塞·行歌甲',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101143,
    uid: 101143,
    装备名称: '西塞·平塘衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101142,
    uid: 101142,
    装备名称: '西塞·赤眉衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101141,
    uid: 101141,
    装备名称: '西塞·江鸥衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101135,
    uid: 101135,
    装备名称: '西塞·冷辉衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101132,
    uid: 101132,
    装备名称: '西塞·壮志战甲',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101001,
    uid: 101001,
    装备名称: '寻踪觅宝·方思衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101000,
    uid: 101000,
    装备名称: '寻踪觅宝·汉广衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100971,
    uid: 100971,
    装备名称: '寻踪觅宝·迭微衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100970,
    uid: 100970,
    装备名称: '寻踪觅宝·予吉衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100941,
    uid: 100941,
    装备名称: '壁明衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100940,
    uid: 100940,
    装备名称: '壁邺衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104499,
    uid: 104499,
    装备名称: '平皋衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104498,
    uid: 104498,
    装备名称: '淮沛衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104472,
    uid: 104472,
    装备名称: '羽缀衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104471,
    uid: 104471,
    装备名称: '夏凌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104144,
    uid: 104144,
    装备名称: '润源衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104143,
    uid: 104143,
    装备名称: '辞羁衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100911,
    uid: 100911,
    装备名称: '润源衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100910,
    uid: 100910,
    装备名称: '辞羁衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100875,
    uid: 100875,
    装备名称: '平皋衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100874,
    uid: 100874,
    装备名称: '淮沛衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100839,
    uid: 100839,
    装备名称: '羽缀衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100838,
    uid: 100838,
    装备名称: '夏凌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100707,
    uid: 100707,
    装备名称: '镂云衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100706,
    uid: 100706,
    装备名称: '炎翳衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100671,
    uid: 100671,
    装备名称: '智渊衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100670,
    uid: 100670,
    装备名称: '韶曦衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100635,
    uid: 100635,
    装备名称: '修篁衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.身法, 值: 842 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100634,
    uid: 100634,
    装备名称: '绮陌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.力道, 值: 842 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100599,
    uid: 100599,
    装备名称: '涵煦衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.身法, 值: 788 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100598,
    uid: 100598,
    装备名称: '惜霜衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.力道, 值: 788 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101863,
    uid: 101863,
    装备名称: '春齐衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.身法, 值: 739 },
      { 属性: 属性类型.外功基础攻击, 值: 1678 },
      { 属性: 属性类型.破招值, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101862,
    uid: 101862,
    装备名称: '曲汜衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.力道, 值: 739 },
      { 属性: 属性类型.外功基础攻击, 值: 1678 },
      { 属性: 属性类型.破招值, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100563,
    uid: 100563,
    装备名称: '嵘嶂衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.身法, 值: 686 },
      { 属性: 属性类型.外功基础攻击, 值: 1119 },
      { 属性: 属性类型.外功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100562,
    uid: 100562,
    装备名称: '湛影衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.力道, 值: 686 },
      { 属性: 属性类型.外功基础攻击, 值: 1119 },
      { 属性: 属性类型.外功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
]

export default 衣服装备数据
