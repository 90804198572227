import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

const 九字诀山卦通用增益: 技能增益列表类型[] = [
  {
    增益名称: '重山',
    依赖奇穴: '重山',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.095 }],
  },
  // {
  //   增益名称: '重山·一',
  //   依赖奇穴: '重山',
  //   增益所在位置: '奇穴',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.095 }],
  // },
  // {
  //   增益名称: '重山·二',
  //   依赖奇穴: '重山',
  //   增益所在位置: '奇穴',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.095 }],
  // },
  // {
  //   增益名称: '重山·三',
  //   依赖奇穴: '重山',
  //   增益所在位置: '奇穴',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.095 }],
  // },
]

export default 九字诀山卦通用增益
