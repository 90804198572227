import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用增益'
import 小橙武技能增益 from './通用增益/小橙武技能增益'
import 大橙武技能增益 from './通用增益/大橙武技能增益'

const 蝎心增益: 技能增益列表类型[] = [
  ...通用增益,
  ...小橙武技能增益,
  ...大橙武技能增益,

  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.03 }],
  },
  {
    增益名称: '2%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.02 }],
  },
  {
    增益名称: '尻尾',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  {
    增益名称: '减读条_1',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
  {
    增益名称: '减读条_2',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
  {
    增益名称: '套装技能增伤',
    增益所在位置: '装备',
    增益启用: false,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 204 / 1024 }],
  },
]

export default 蝎心增益
