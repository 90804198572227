import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '天宇·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101165.png',
        id: '101165',
        奇穴描述: '{驰风震域·悟}内的目标{轻功沉默}。',
      },
      {
        奇穴名称: '鹏程·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101166.png',
        id: '101166',
        奇穴描述:
          '处于自身飓风或水龙卷范围内时，施展【傍身招式】后降低{缥缈掌法}和【澹然若海·悟】调息时间1秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '九溟·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101167.png',
        id: '101167',
        奇穴描述:
          '有{击落}效果招式命中空中敌方时，造成130%攻击力的外功伤害，若成功击落目标，则再造成1次伤害。',
      },
      {
        奇穴名称: '浩渺·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101168.png',
        id: '101168',
        奇穴描述: '不处于{撑伞浮空}且在{驰风震域·悟}范围内时，自身造成的伤害与无双提高8%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '击浪·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101169.png',
        id: '101169',
        奇穴描述:
          '施展冲刺招式移动10尺以上距离时，使自身在1.5秒后对周围8尺敌方造成169%攻击力的外功伤害，已有该效果时不会重复获得。',
      },
      {
        奇穴名称: '海溟·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/101170.png',
        id: '101170',
        奇穴描述: '处于自身飓风、水龙卷范围内时，分别使自身的会心率和会心效果提高10%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '澹然若海·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/102111.png',
        id: '102111',
        奇穴描述:
          '对周围敌方造成伤害，并在原地出现{水龙卷}。自身在{水龙卷}内施展{缥缈掌法}命中目标会使{水龙卷}造成的伤害提高10%，最多提高3次，持续8秒。',
      },
      {
        奇穴名称: '飞倾列缺·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/102112.png',
        id: '102112',
        奇穴描述:
          '对目标区域内敌方造成伤害并{定身}，随即再次对范围内敌方造成伤害并将其拉向空中，期间对敌方持续造成伤害，并使其{轻功沉默}。',
      },
    ],
  },
]

export default 奇穴数据
