import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

const 闹须弥增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '绝期',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.7 }],
  },
]

export default 闹须弥增益
