export default {
  装备基础属性: {
    基础攻击: 51955,
    破防等级: 61490,
    无双等级: 88384,
    会心等级: 12924,
    会心效果等级: 6684,
    全能等级: 0,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    破招值: 26621,
    力道: 44,
    元气: 7872,
    身法: 44,
    根骨: 44,
    体质: 134672,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
    装分: 506419,
  },
  装备镶嵌附魔属性: {
    基础攻击: 11163,
    破防等级: 4170,
    无双等级: 7928,
    会心等级: 2502,
    会心效果等级: 834,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 834,
    力道: 44,
    元气: 256,
    身法: 44,
    根骨: 44,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSpunkBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101428,
      装备部位: '帽子',
      附魔: '攻击+633',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101459,
      装备部位: '衣服',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101566,
      装备部位: '腰带',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101335,
      装备部位: '护腕',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101583,
      装备部位: '下装',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101397,
      装备部位: '鞋子',
      附魔: '攻击+633',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41412,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41362,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41370,
      装备部位: '戒指',
      附魔: '攻击+633',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41370,
      装备部位: '戒指',
      附魔: '攻击+633',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39359,
      装备部位: '暗器',
      附魔: '加速+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSpunkBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39328,
      装备部位: '武器',
      附魔: '攻击+633',
    },
  ],
  五彩石: '彩·无双·激流·月华(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 1,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
