import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 净世破魔击增益 from './净世破魔击'

const 净世破魔击日增益: 技能增益列表类型[] = [
  ...净世破魔击增益,
  {
    // 25759
    增益名称: '明光·日',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 246 }],
  },
]

export default 净世破魔击日增益
