import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '彤弓',
        奇穴图片: 'https://icon.jx3box.com/icon/20314.png',
        id: '35714',
        奇穴描述: '“劲风簇”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '素矰',
        奇穴图片: 'https://icon.jx3box.com/icon/20313.png',
        id: '35715',
        奇穴描述: '“贯穿”伤害提高5%。',
      },
      {
        奇穴名称: '争疾',
        奇穴图片: 'https://icon.jx3box.com/icon/20312.png',
        id: '35717',
        奇穴描述: '“上弦星流术”套路招式攻击“贯穿”的目标，自身移动速度提高20%，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '棘矢',
        奇穴图片: 'https://icon.jx3box.com/icon/20272.png',
        id: '35718',
        奇穴描述: '箭袋内最后一发弓箭会引爆目标最多3跳“贯穿”。',
      },
      {
        奇穴名称: '孰湖',
        奇穴图片: 'https://icon.jx3box.com/icon/20271.png',
        id: '35719',
        奇穴描述: '“偕行动物”出现时额外获得一层“承契”。',
      },
      {
        奇穴名称: '弧旌',
        奇穴图片: 'https://icon.jx3box.com/icon/20270.png',
        id: '35720',
        奇穴描述:
          '“箭袋”中箭矢数量为1到4支时施展“寒更晓箭”，使下2支弓箭获得“金乌见坠”、“空弦惊雁”效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '襄尺',
        奇穴图片: 'https://icon.jx3box.com/icon/20287.png',
        id: '35721',
        奇穴描述:
          '“承契”层数为5层且剩余时间大于等于8秒时，若再次获得“承契”效果则降低“金乌见坠”3秒调息时间。',
      },
      {
        奇穴名称: '冉遗',
        奇穴图片: 'https://icon.jx3box.com/icon/20289.png',
        id: '35722',
        奇穴描述: '“应天授命”期间受治疗效果提高20%。',
      },
      {
        奇穴名称: '虹流',
        奇穴图片: 'https://icon.jx3box.com/icon/20288.png',
        id: '35723',
        奇穴描述: '“霖集簇”调息时间减少5秒，“流矢雨集”使目标额外获得两层“标鹄”。',
      },
      {
        奇穴名称: '弋矰',
        奇穴图片: 'https://icon.jx3box.com/icon/20286.png',
        id: '35724',
        奇穴描述: '“上弦星流术”套路招式命中背对自身目标时，使目标获得4秒“弋矰”：无法施展轻功效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '长右',
        奇穴图片: 'https://icon.jx3box.com/icon/20305.png',
        id: '35725',
        奇穴描述: '主动施展“引风唤灵”以及“驰律召野”召唤的“偕行动物”对目标造成伤害时回复15%内力。',
      },
      {
        奇穴名称: '射革',
        奇穴图片: 'https://icon.jx3box.com/icon/20306.png',
        id: '35726',
        奇穴描述: '对锁足、定身、眩晕、击倒目标造成伤害提高15%。',
      },
      {
        奇穴名称: '蓄锐',
        奇穴图片: 'https://icon.jx3box.com/icon/20307.png',
        id: '35727',
        奇穴描述: '战斗中若4秒内未释放伤害招式则获得“承契”效果。',
      },
      {
        奇穴名称: '虚彀',
        奇穴图片: 'https://icon.jx3box.com/icon/23483.png',
        id: '38664',
        奇穴描述:
          '附带“空弦惊雁”效果的箭矢命中未被定身、眩晕、击倒的目标时，直接使目标获得3秒定身效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '鹿蜀',
        奇穴图片: 'https://icon.jx3box.com/icon/20311.png',
        id: '35729',
        奇穴描述: '“偕行动物”离场时，自身获得“偕行动物”的“承契”。',
      },
      {
        奇穴名称: '镇祟',
        奇穴图片: 'https://icon.jx3box.com/icon/20292.png',
        id: '35735',
        奇穴描述: '施展“汇山岚”后玩家的追击范围会增大2尺，最外圈2尺会造成内圈1.5倍的伤害。',
      },
      {
        奇穴名称: '反曲',
        奇穴图片: 'https://icon.jx3box.com/icon/20309.png',
        id: '35731',
        奇穴描述: '“饮羽簇”命中4层以上“贯穿”目标时，使得目标获得一层“重创”效果。',
      },
      {
        奇穴名称: '腾驾',
        奇穴图片: 'https://icon.jx3box.com/icon/20310.png',
        id: '35732',
        奇穴描述: '“游雾乘云”状态下施展“腾云”不再立刻解除骑乘，“腾云”变为两重充能招式。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '诸怀',
        奇穴图片: 'https://icon.jx3box.com/icon/20281.png',
        id: '35733',
        奇穴描述:
          '施展“引风唤灵”时，自身获得一层“承契”。召唤兽和自身在“承契”期间基础攻击力提高10%，无视目标20%外功防御。',
      },
      {
        奇穴名称: '片羽',
        奇穴图片: 'https://icon.jx3box.com/icon/20425.png',
        id: '36449',
        奇穴描述: '“风尽浮生”一段冲刺过程中，重置“白羽流星”并激活“没石饮羽”。',
      },
      {
        奇穴名称: '祓厄',
        奇穴图片: 'https://icon.jx3box.com/icon/20308.png',
        id: '35730',
        奇穴描述: '“汇山岚”不再造成眩晕而是3秒恐惧。',
      },
      {
        奇穴名称: '岚汇',
        奇穴图片: 'https://icon.jx3box.com/icon/20315.png',
        id: '38867',
        奇穴描述: '每施展一次“归平野”降低“汇山岚”调息时间3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '于狩',
        奇穴图片: 'https://icon.jx3box.com/icon/20284.png',
        id: '35737',
        奇穴描述:
          '施展“没石饮羽”时获得一层“承契”。施展“没石饮羽”时若箭袋内箭矢数量为3-5，则引爆命中的非侠士目标3跳“贯穿”。',
      },
      {
        奇穴名称: '九婴',
        奇穴图片: 'https://icon.jx3box.com/icon/20283.png',
        id: '35738',
        奇穴描述:
          '“霖集簇”命中“贯穿”目标时，记录最高的层数，“霖集簇”的强化招式“流矢雨集”使命中目标获得对应层数的“贯穿”。',
      },
      {
        奇穴名称: '陆吾',
        奇穴图片: 'https://icon.jx3box.com/icon/20274.png',
        id: '35746',
        奇穴描述:
          '“强击型”为即将召唤的三个“偕行动物”之一时，“偕行动物”攻击都会使目标获得一层减疗效果。',
      },
      {
        奇穴名称: '同渡',
        奇穴图片: 'https://icon.jx3box.com/icon/20285.png',
        id: '35740',
        奇穴描述:
          '“汇灵合契”期间，受治疗效果增加15%。对你释放治疗招式的治疗心法获得5秒“同渡”效果：招式施展距离增加20%，移动速度提高20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '沃焦',
        奇穴图片: 'https://icon.jx3box.com/icon/20304.png',
        id: '35728',
        奇穴描述:
          '“空弦惊雁”后下一次“劲风簇”若命中运功目标则击退目标，若成功击退则使目标获得5秒“沃焦”效果：招式施展距离变为0尺，无法看到其他目标。',
      },
      {
        奇穴名称: '领胡',
        奇穴图片: 'https://icon.jx3box.com/icon/20269.png',
        id: '35742',
        奇穴描述: '“弛律召野”调息时间降低10秒，“偕行动物”造成伤害提高30%。',
      },
      {
        奇穴名称: '命俦',
        奇穴图片: 'https://icon.jx3box.com/icon/23482.png',
        id: '35756',
        奇穴描述:
          '“弛律召野”将会额外召唤强击型“偕行动物”助战持续15秒。协同动物会每3秒出现一次并打击“弛律召野”的释放目标，直到释放一次白羽流星，协同动物便会转换打击目标为白羽流星的目标。',
      },
      {
        奇穴名称: '九乌',
        奇穴图片: 'https://icon.jx3box.com/icon/20266.png',
        id: '35744',
        奇穴描述: '“偕行动物”造成伤害时使目标获得两层“贯穿”。',
      },
      {
        奇穴名称: '卢令',
        奇穴图片: 'https://icon.jx3box.com/icon/20268.png',
        id: '35745',
        奇穴描述: '身法提高10%，内力回复提高20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '集雨',
        奇穴图片: 'https://icon.jx3box.com/icon/20267.png',
        id: '38865',
        奇穴描述: '“霖集簇”对没有标鹄贯穿的目标造成伤害时，使目标获得4层贯穿，4层标鹄。',
      },
      {
        奇穴名称: '畴野',
        奇穴图片: 'https://icon.jx3box.com/icon/20277.png',
        id: '35747',
        奇穴描述: '施展“引风唤灵”时，自身获得6秒15%最大气血值护盾，若被打破则回复5%内力。',
      },
      {
        奇穴名称: '兴游',
        奇穴图片: 'https://icon.jx3box.com/icon/20275.png',
        id: '35748',
        奇穴描述: '施展“汇灵合契”需求的“唤灵印”减少一个。引风唤灵调息时间增加2.5秒。',
      },
      {
        奇穴名称: '通灵',
        奇穴图片: 'https://icon.jx3box.com/icon/19589.png',
        id: '38866',
        奇穴描述:
          '“汇灵合契”消耗唤灵印超过施展要求数时，每超过一个则“汇灵合契”状态持续时间增加6秒，并延长自身6秒承契。',
      },
      {
        奇穴名称: '贯侯',
        奇穴图片: 'https://icon.jx3box.com/icon/20299.png',
        id: '35757',
        奇穴描述: '“标鹄”引爆时额外使目标获得一层“标鹄”，引爆伤害对非侠士目标提高20%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '佩弦',
        奇穴图片: 'https://icon.jx3box.com/icon/20298.png',
        id: '35751',
        奇穴描述:
          '“饮羽簇”无视目标65%外功防御，施展“金乌见坠”后下次施展“饮羽簇”无需运功，持续10秒。',
      },
      {
        奇穴名称: '扶国',
        奇穴图片: 'https://icon.jx3box.com/icon/20296.png',
        id: '35752',
        奇穴描述: '“腾云”不再造成恐惧，而是可释放一次将被控制的队友带离战场的招式。',
      },
      {
        奇穴名称: '祛邪',
        奇穴图片: 'https://icon.jx3box.com/icon/20301.png',
        id: '35760',
        奇穴描述: '释放“汇灵合契”时，使当前全部箭矢获得“金乌见坠”、“空弦惊雁”。',
      },
      {
        奇穴名称: '丛云隐月',
        奇穴图片: 'https://icon.jx3box.com/icon/20297.png',
        id: '35754',
        奇穴描述:
          '被动效果：“饮羽簇”运功时间降低0.5秒，调息时间降低0.5秒。&lt;br/&gt;主动效果：消耗20%内力，获得十秒“丛云隐月”效果：招式内力消耗降低80%，“白羽流星”无调息时间，“饮羽簇”调息时间降低3秒。',
      },
      {
        奇穴名称: '涉野徙林',
        奇穴图片: 'https://icon.jx3box.com/icon/20294.png',
        id: '35755',
        奇穴描述:
          '主动效果：自己、小队成员移动速度提高60%，持续5秒，每层涉野徙林使持续时间增加1秒，消耗全部涉野效果。&lt;br/&gt;被动效果：攻击移动速度低于自身的目标的玩家时，回复1%内力，并且叠加1层涉野徙林。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '时祯',
        奇穴图片: 'https://icon.jx3box.com/icon/20265.png',
        id: '35743',
        奇穴描述: '“汇灵合契”状态结束后，自身获得2秒“澄神醒梦”和“应天授命”效果。',
      },
      {
        奇穴名称: '灌羽',
        奇穴图片: 'https://icon.jx3box.com/icon/20279.png',
        id: '35734',
        奇穴描述:
          '召唤“飞行型”偕行动物后，10秒内自身周围10尺内三名敌方玩家，对自身以及自身小队成员隐身效果失效。',
      },
      {
        奇穴名称: '桑柘',
        奇穴图片: 'https://icon.jx3box.com/icon/20280.png',
        id: '35736',
        奇穴描述: '“贯穿”增加一跳，“标鹄”引爆时额外增加一层“贯穿”。',
      },
      {
        奇穴名称: '审固',
        奇穴图片: 'https://icon.jx3box.com/icon/20303.png',
        id: '35759',
        奇穴描述:
          '使6层以上目标获得“贯穿”时，不再刷新持续时间，而是延长2秒“贯穿”的持续时间，同一次“贯穿”状态最多可触发3次。',
      },
      {
        奇穴名称: '泑泽',
        奇穴图片: 'https://icon.jx3box.com/icon/20295.png',
        id: '35753',
        奇穴描述: '“群战型”召唤兽的伤害还会使得目标获得一层“泑泽”效果：受到的范围伤害增加10%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '朱厌',
        奇穴图片: 'https://icon.jx3box.com/icon/20293.png',
        id: '35761',
        奇穴描述:
          '“弛律召野”一次召唤三只“偕行动物”，“弛律召野”召唤的“偕行动物”造成的伤害降低30%。“弛律召野”调息时间增加20秒。',
      },
      {
        奇穴名称: '祝灵',
        奇穴图片: 'https://icon.jx3box.com/icon/20290.png',
        id: '35762',
        奇穴描述: '“汇山岚”调息时间降低6秒。释放“聚长川”、“汇山岚”会使下一次“归平野”伤害提高80%。',
      },
      {
        奇穴名称: '星烨',
        奇穴图片: 'https://icon.jx3box.com/icon/20278.png',
        id: '35763',
        奇穴描述:
          '“游雾乘云”需要2枚“唤灵印”方可施展，施展后清空全部“唤灵印”并重置调息时间，使当前全部箭矢获得“空弦惊雁”效果。期间“劲风簇”造成伤害提高140%。',
      },
      {
        奇穴名称: '朝仪万汇',
        奇穴图片: 'https://icon.jx3box.com/icon/19602.png',
        id: '35764',
        奇穴描述:
          '对目标进行20发的连射（伤害视作“劲风簇”），此次射击不消耗箭矢，最大持续运功8.75秒。可在“游雾乘云”期间施展。',
      },
      {
        奇穴名称: '白虹贯日',
        奇穴图片: 'https://icon.jx3box.com/icon/19598.png',
        id: '35765',
        奇穴描述:
          '蓄力2秒射出40尺的超远一箭，蓄力期间免疫控制效果，使被攻击的目标获得“贯穿”效果，初始为一层，每蓄力0.5秒增加一层“贯穿”效果，最多四层。',
      },
    ],
  },
]

export default 奇穴数据
