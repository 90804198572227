export default {
  装备基础属性: {
    基础攻击: 34524,
    破防等级: 29716,
    无双等级: 70572,
    会心等级: 20651,
    会心效果等级: 0,
    全能等级: 0,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    破招值: 20641,
    力道: 44,
    元气: 9314,
    身法: 44,
    根骨: 44,
    体质: 106512,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 8887,
    装分: 373774,
  },
  装备镶嵌附魔属性: {
    基础攻击: 8108,
    破防等级: 1668,
    无双等级: 5004,
    会心等级: 2502,
    会心效果等级: 0,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 1668,
    力道: 44,
    元气: 630,
    身法: 44,
    根骨: 44,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 0,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100867,
      装备部位: '帽子',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSpunkBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100837,
      装备部位: '衣服',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100885,
      装备部位: '腰带',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100879,
      装备部位: '护腕',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAllTypeCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100897,
      装备部位: '下装',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSpunkBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 100819,
      装备部位: '鞋子',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 42734,
      装备部位: '项链',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41206,
      装备部位: '腰坠',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41194,
      装备部位: '戒指',
      附魔: '元气+268',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41194,
      装备部位: '戒指',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 38847,
      装备部位: '暗器',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atMagicAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSpunkBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atMagicOvercome',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 38869,
      装备部位: '武器',
    },
  ],
  五彩石: '彩·急速·激流·无双(陆)',
  装备增益: {
    大附魔_伤帽: 0,
    大附魔_伤衣: 0,
    大附魔_伤腰: 0,
    大附魔_伤腕: 0,
    大附魔_伤鞋: 0,
  },
}
