import { 技能基础数据模型 } from '@/@types/技能'

import { 计算公式计算类型 } from '@/@types/伤害计算'
import 通用增益 from './技能增益/通用增益/通用增益'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 劲风簇增益 from './技能增益/劲风簇增益'
import 唤兽招式增益 from './技能增益/唤兽招式增益'
import 弓箭招式增益 from './技能增益/弓箭招式增益'
import 狼增益 from './技能增益/狼增益'

// 102211 技能ID
// 71175 BuffID
const 流血基础系数 = {
  技能ID: 71175,
  统计名称: '流血(DOT)',
  基础伤害_基础值: 10,
  技能伤害系数: 768,
  DOT生效间隔: 16,
  DOT跳数: 6,
  技能增益列表: 通用增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: 102018,
    技能名称: '劲风簇·悟·一',
    统计名称: '劲风簇·悟',
    技能伤害系数: 277,
    基础伤害_基础值: 1,
    技能破招系数: 1063 / 1024,
    技能增益列表: 劲风簇增益,
  },
  {
    技能ID: 102019,
    技能名称: '劲风簇·悟·二',
    统计名称: '劲风簇·悟',
    技能伤害系数: 108,
    基础伤害_基础值: 1,
    技能破招系数: 412 / 1024,
    技能增益列表: 劲风簇增益,
  },
  {
    技能ID: 101998,
    技能名称: '饮雨簇·悟·一',
    统计名称: '饮雨簇·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 269,
    技能破招系数: 1032 / 1024,
    武器伤害系数: 1024 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益, ...弓箭招式增益],
  },
  {
    技能ID: 102027,
    技能名称: '饮雨簇·悟·二',
    统计名称: '饮雨簇·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 125,
    技能破招系数: 479 / 1024,
    武器伤害系数: 205 / 1024,
    技能增益列表: [...通用增益, ...对阵招式增益, ...弓箭招式增益],
  },
  {
    技能ID: 102028,
    技能名称: '携行动物_野猪',
    统计名称: '野豕叱虎·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 426,
    技能增益列表: [...通用增益, ...对阵招式增益, ...唤兽招式增益],
  },
  {
    技能ID: 102029,
    技能名称: '携行动物_虎',
    统计名称: '野豕叱虎·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 423,
    技能增益列表: [...通用增益, ...对阵招式增益, ...唤兽招式增益],
  },
  {
    技能ID: 102030,
    技能名称: '携行动物_鹰',
    统计名称: '疾隼驰狼·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 437,
    技能增益列表: [...通用增益, ...对阵招式增益, ...唤兽招式增益],
  },
  {
    技能ID: 102031,
    技能名称: '携行动物_狼',
    统计名称: '疾隼驰狼·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 477,
    技能增益列表: 狼增益,
  },
  {
    技能ID: 102032,
    技能名称: '携行动物_熊',
    统计名称: '巨熊龙象·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 418,
    技能增益列表: [...通用增益, ...对阵招式增益, ...唤兽招式增益],
  },
  {
    技能ID: 102033,
    技能名称: '携行动物_大象',
    统计名称: '巨熊龙象·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 413,
    技能增益列表: [...通用增益, ...对阵招式增益, ...唤兽招式增益],
  },
  {
    技能ID: 102035,
    技能名称: '朝仪万汇·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 184,
    技能增益列表: [...通用增益, ...对阵招式增益, ...弓箭招式增益],
  },
  {
    技能ID: 102037,
    技能名称: '白虹贯日·悟',
    基础伤害_基础值: 1,
    技能伤害系数: 2252,
    技能增益列表: [...通用增益, ...弓箭招式增益],
  },
  {
    技能名称: '流血(DOT)·一',
    ...流血基础系数,
    伤害计算次数: 1,
  },
  // -------
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
