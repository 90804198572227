import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 劈风增益 from './劈风'

const 劈风骤增益: 技能增益列表类型[] = [
  ...劈风增益,
  {
    增益名称: '纷飙',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
]

export default 劈风骤增益
