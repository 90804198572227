import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 对阵招式增益 from './通用增益/对阵招式增益'

const 兵主逆增益: 技能增益列表类型[] = [
  ...通用增益,
  ...对阵招式增益,
  {
    增益名称: '兵主逆绝篇',
    增益所在位置: '技能',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.2 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 205 },
    ],
  },
]

export default 兵主逆增益
