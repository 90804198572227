import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 停云势增益 from './技能增益/停云势增益'
import 行云势增益 from './技能增益/行云势增益'
import 留客雨增益 from './技能增益/留客雨增益'
import 决云势增益 from './技能增益/决云势增益'
import 断云势增益 from './技能增益/断云势增益'
import 沧浪三叠一增益 from './技能增益/沧浪三叠一增益'
import 沧浪三叠二增益 from './技能增益/沧浪三叠二增益'
import 沧浪三叠三增益 from './技能增益/沧浪三叠三增益'
import 横云断浪增益 from './技能增益/横云断浪增益'
import 孤锋破浪增益 from './技能增益/孤锋破浪增益'
import 避实击虚增益 from './技能增益/避实击虚增益'
import 鸣锋增益 from './技能增益/鸣锋增益'
import 触石雨增益 from './技能增益/触石雨增益'

import {
  流血1层增益,
  流血2层增益,
  流血3层增益,
  流血4层增益,
  流血5层增益,
  流血6层增益,
} from './技能增益/流血增益'

const 流血基础伤害 = 114
const 流血DOT跳数 = 3
const 流血DOT间隔 = 32
const 流血基础系数 = 110.00000000000001
const 斩浪破锋基础系数 = 500

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '行云势·神兵',
    技能伤害系数: 60,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '行云势·一',
    秘籍依赖技能: '行云势',
    技能伤害系数: 135 * 1.5 * 1.2 * 1.25,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 行云势增益,
  },
  {
    技能名称: '行云势·二',
    秘籍依赖技能: '行云势',
    技能伤害系数: 159 * 1.5 * 1.2 * 1.25,
    基础伤害_基础值: 216,
    基础伤害_浮动值: 18,
    武器伤害系数: 1.5,
    技能增益列表: 行云势增益,
  },
  {
    技能名称: '行云势·三',
    秘籍依赖技能: '行云势',
    技能伤害系数: 190 * 1.5 * 1.5 * 1.2 * 1.25,
    基础伤害_基础值: 270,
    基础伤害_浮动值: 22,
    武器伤害系数: 2,
    技能增益列表: 行云势增益,
  },
  {
    技能名称: '停云势',
    技能伤害系数: 240 * 1.5 * 0.9,
    基础伤害_基础值: 405,
    基础伤害_浮动值: 15,
    武器伤害系数: 2,
    技能增益列表: 停云势增益,
  },
  {
    技能名称: '留客雨',
    技能伤害系数: 200,
    基础伤害_基础值: 160,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 留客雨增益,
  },
  {
    技能名称: '避实击虚',
    技能伤害系数: 80 * 0.9,
    基础伤害_基础值: 35,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 避实击虚增益,
    技能等级数据: {
      '1,7': {
        基础伤害_基础值: [35, 42, 45, 50, 55, 60],
        技能伤害系数: (i) => {
          switch (i) {
            case 6:
              return 206.99999999999997
            case 5:
              return 165.6
            case 4:
              return 149.76000000000002
            case 3:
              return 126.36000000000001
            case 2:
              return 105.3
            default:
              return 93.60000000000001
          }
        },
      },
    },
  },
  {
    技能名称: '决云势',
    技能伤害系数: 188 * 1.2,
    基础伤害_基础值: 200,
    基础伤害_浮动值: 18,
    武器伤害系数: 1,
    技能增益列表: 决云势增益,
  },
  {
    技能名称: '驰风八步·一',
    秘籍依赖技能: '驰风八步',
    技能伤害系数: 45,
    基础伤害_基础值: 10,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '驰风八步·二',
    秘籍依赖技能: '驰风八步',
    技能伤害系数: 60,
    基础伤害_基础值: 10,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '断云势',
    统计名称: '断云势',
    秘籍依赖技能: '断云势',
    技能伤害系数: 380 * 0.9 * 1.2,
    基础伤害_基础值: 172,
    基础伤害_浮动值: 22,
    武器伤害系数: 2,
    技能增益列表: 断云势增益,
  },
  {
    技能名称: '断云势·额外伤害',
    统计名称: '断云势',
    秘籍依赖技能: '断云势',
    技能伤害系数: 380 * 0.4 * 0.9 * 1.2,
    基础伤害_基础值: 69,
    基础伤害_浮动值: 9,
    武器伤害系数: 2,
    技能增益列表: 断云势增益,
  },
  {
    技能名称: '沧浪三叠·一',
    秘籍依赖技能: '沧浪三叠',
    技能伤害系数: 230 * 1.2, // 乘0.9没了？不知道是不是bug
    基础伤害_基础值: 204,
    基础伤害_浮动值: 16,
    武器伤害系数: 2,
    技能增益列表: 沧浪三叠一增益,
  },
  {
    技能名称: '沧浪三叠·二',
    秘籍依赖技能: '沧浪三叠',
    技能伤害系数: 255 * 1.2, // 乘0.9没了？不知道是不是bug
    基础伤害_基础值: 267,
    基础伤害_浮动值: 21,
    武器伤害系数: 2.5,
    技能增益列表: 沧浪三叠二增益,
  },
  {
    技能名称: '沧浪三叠·三',
    秘籍依赖技能: '沧浪三叠',
    技能伤害系数: 290 * 1.2, // 乘0.9没了？不知道是不是bug
    基础伤害_基础值: 293,
    基础伤害_浮动值: 23,
    武器伤害系数: 3,
    技能增益列表: 沧浪三叠三增益,
  },
  {
    技能名称: '横云断浪',
    技能伤害系数: 450 * 0.8 * 1.2,
    基础伤害_基础值: 721,
    基础伤害_浮动值: 88,
    武器伤害系数: 3,
    技能增益列表: 横云断浪增益,
  },
  {
    技能名称: '孤锋破浪',
    技能伤害系数: 650 * 0.9 * 0.85 * 0.9 * 1.1 * 1.15 * 1.2,
    基础伤害_基础值: 1450,
    基础伤害_浮动值: 75,
    武器伤害系数: 3,
    技能增益列表: 孤锋破浪增益,
  },
  {
    技能名称: '界破',
    技能伤害系数: 350 * 1.1 * 1.1,
    基础伤害_基础值: 160,
    基础伤害_浮动值: 317,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '触石雨',
    技能伤害系数: 200,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 15,
    技能增益列表: 触石雨增益,
  },
  {
    技能名称: '截辕',
    统计名称: '截辕',
    技能伤害系数: 340 * 0.75 * 1.1,
    基础伤害_基础值: 77,
    基础伤害_浮动值: 25,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '截辕（DOT）',
    技能伤害系数: 400 * 0.75 * 1.1,
    DOT生效间隔: 48,
    DOT跳数: 6,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '截辕·破碎',
    统计名称: '截辕',
    技能伤害系数: 838.3499999999999,
    基础伤害_基础值: 109,
    基础伤害_浮动值: 38,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '云刀',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '流血·一',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    技能增益列表: 流血1层增益,
  },
  {
    技能名称: '流血·二',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    伤害计算次数: 2,
    技能增益列表: 流血2层增益,
  },
  {
    技能名称: '流血·三',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    伤害计算次数: 3,
    技能增益列表: 流血3层增益,
  },
  {
    技能名称: '流血·四',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    伤害计算次数: 4,
    技能增益列表: 流血4层增益,
  },
  {
    技能名称: '流血·五',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    伤害计算次数: 5,
    技能增益列表: 流血5层增益,
  },
  {
    技能名称: '流血·六',
    统计名称: '流血',
    秘籍依赖技能: '横云断浪',
    技能伤害系数: 流血基础系数,
    DOT生效间隔: 流血DOT间隔,
    DOT跳数: 流血DOT跳数,
    基础伤害_基础值: 流血基础伤害,
    基础伤害_浮动值: 流血基础伤害,
    伤害计算次数: 6,
    技能增益列表: 流血6层增益,
  },
  {
    技能名称: '凝神势·破锋',
    技能伤害系数: 1.46873461349,
    武器伤害系数: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '鸣锋',
    技能伤害系数: 230 * (1 + 1 * 0.13) * 0.5,
    基础伤害_基础值: 76,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 鸣锋增益,
  },
  {
    技能名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.48 - 1)),
    技能增益列表: 通用增益,
  },
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '潋风·携刃',
    技能伤害系数: 150 * 1.1 * 1.25 * 1.4,
    基础伤害_基础值: 78,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '斩浪破锋·一',
    统计名称: '斩浪破锋（DOT）',
    技能伤害系数: 斩浪破锋基础系数,
    DOT生效间隔: 48,
    DOT跳数: 6,
    基础伤害_基础值: 25,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '斩浪破锋·二',
    统计名称: '斩浪破锋（DOT）',
    技能伤害系数: 斩浪破锋基础系数,
    DOT生效间隔: 48,
    DOT跳数: 6,
    基础伤害_基础值: 25,
    伤害计算次数: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '斩浪破锋·三',
    统计名称: '斩浪破锋（DOT）',
    技能伤害系数: 斩浪破锋基础系数,
    DOT生效间隔: 48,
    DOT跳数: 6,
    基础伤害_基础值: 25,
    伤害计算次数: 3,
    技能增益列表: 通用增益,
  },
]

const 技能增益 = {
  通用增益,
  停云势增益,
  行云势增益,
  留客雨增益,
  决云势增益,
  断云势增益,
  沧浪三叠一增益,
  沧浪三叠二增益,
  沧浪三叠三增益,
  横云断浪增益,
  孤锋破浪增益,
  避实击虚增益,
  鸣锋增益,
  触石雨增益,
  流血1层增益,
  流血2层增益,
  流血3层增益,
  流血4层增益,
  流血5层增益,
  流血6层增益,
}

export { 技能增益 }

export default 技能基础数据
