import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试 from './测试.json'
import 蛇香_206 from './蛇香_206.json'
import 蛇香_20106 from './蛇香_20106.json'
import 橙武 from './橙武.json'

const 计算循环: 循环数据[] = [蛇香_206, 蛇香_20106, 橙武] as 循环数据[]

export default 计算循环
