/**
 * 当前只内置了部分心法的循环模拟
 */
import React, { useMemo } from 'react'
import { Button, Tooltip } from 'antd'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'

const 山海心诀循环模拟 = React.lazy(() => import('./心法循环模拟/山海心诀'))
const 孤锋诀循环模拟 = React.lazy(() => import('./心法循环模拟/孤锋诀'))
const 孤锋诀_悟循环模拟 = React.lazy(() => import('./心法循环模拟/孤锋诀_悟'))
const 周天功循环模拟 = React.lazy(() => import('./心法循环模拟/周天功'))

const 支持循环心法 = {
  山海心诀: '山海心诀',
  孤锋诀: '孤锋诀',
  孤锋诀_悟: '孤锋诀_悟',
  周天功: '周天功',
}

const { 名称 } = 获取当前数据()

function 循环模拟() {
  const CycleComponent = useMemo(() => {
    return 名称 === '山海心诀' ? (
      <山海心诀循环模拟 />
    ) : 名称 === '孤锋诀' ? (
      <孤锋诀循环模拟 />
    ) : 名称 === '孤锋诀_悟' ? (
      <孤锋诀_悟循环模拟 />
    ) : 名称 === '周天功' ? (
      <周天功循环模拟 />
    ) : (
      <></>
    )
  }, [名称])

  return 支持循环心法?.[名称] ? (
    <React.Suspense
      fallback={
        <Button danger size='small'>
          Loading
        </Button>
      }
    >
      {CycleComponent}
    </React.Suspense>
  ) : (
    <Tooltip title='该心法暂不支持循环模拟功能'>
      <Button danger size='small' disabled className={'cycle-disabled-btn'}>
        循环模拟
      </Button>
    </Tooltip>
  )
}

export default 循环模拟
