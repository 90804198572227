export default {
  装备基础属性: {
    基础攻击: 40792,
    破防等级: 49076,
    无双等级: 90270,
    会心等级: 17761,
    会心效果等级: 1463,
    全能等级: 0,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    破招值: 72982,
    力道: 44,
    元气: 44,
    身法: 7790,
    根骨: 44,
    体质: 134164,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 1929,
    装分: 509180,
  },
  装备镶嵌附魔属性: {
    基础攻击: 7433,
    破防等级: 5425,
    无双等级: 8762,
    会心等级: 12361,
    会心效果等级: 0,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 9190,
    力道: 44,
    元气: 44,
    身法: 256,
    根骨: 44,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 0,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101455,
      装备部位: '帽子',
      附魔: '破招+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101557,
      装备部位: '衣服',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 104334,
      装备部位: '腰带',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 104336,
      装备部位: '护腕',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 104338,
      装备部位: '下装',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101424,
      装备部位: '鞋子',
      附魔: '破招+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41414,
      装备部位: '项链',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41364,
      装备部位: '腰坠',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 8,
      id: 42441,
      装备部位: '戒指',
      附魔: '破招+2089',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41372,
      装备部位: '戒指',
      附魔: '破招+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39361,
      装备部位: '暗器',
      附魔: '破防+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39356,
      装备部位: '武器',
      附魔: '攻击+568',
    },
  ],
  五彩石: '彩·痛击·锐刃·无双(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 0,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
