import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '尻尾',
        奇穴图片: 'https://icon.jx3box.com/icon/2783.png',
        id: '6618',
        奇穴描述: '“蝎心”可以在移动中施展，伤害提高10%。',
      },
      {
        奇穴名称: '生发',
        奇穴图片: 'https://icon.jx3box.com/icon/3027.png',
        id: '6877',
        奇穴描述: '所有宠物的技能的调息时间降低20%，攻击附带减速效果，持续4秒。',
      },
      {
        奇穴名称: '蝎毒',
        奇穴图片: 'https://icon.jx3box.com/icon/2783.png',
        id: '6620',
        奇穴描述:
          '“蝎心”不再运功，不再立刻对目标造成伤害，但使目标每2秒受到一次毒性内功伤害，持续12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '圣涌',
        奇穴图片: 'https://icon.jx3box.com/icon/3029.png',
        id: '6658',
        奇穴描述: '当宠物存在时，你受到的伤害降低10%。',
      },
      {
        奇穴名称: '无常',
        奇穴图片: 'https://icon.jx3box.com/icon/3030.png',
        id: '6622',
        奇穴描述: '“蝎心”会心提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '食髓',
        奇穴图片: 'https://icon.jx3box.com/icon/3036.png',
        id: '6649',
        奇穴描述:
          '灵蛇的“幻击”命中有自身“蛇影”的目标造成毒性伤害（该效果不受其他奇穴效果影响），若“幻击”招式会心则调息时间降低6秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '随风',
        奇穴图片: 'https://icon.jx3box.com/icon/2813.png',
        id: '6628',
        奇穴描述: '“化蝶”后会向前额外冲刺一段距离。',
      },
      {
        奇穴名称: '黯影',
        奇穴图片: 'https://icon.jx3box.com/icon/3031.png',
        id: '6629',
        奇穴描述:
          '所有持续伤害提高25%，且添加持续伤害的招式命中已有对应持续伤害目标时会额外造成毒性伤害。',
      },
      {
        奇穴名称: '离争',
        奇穴图片: 'https://icon.jx3box.com/icon/3020.png',
        id: '6881',
        奇穴描述:
          '宠物受到的伤害降低60%，招式伤害造成的威胁值提高，毒性内功招式命中目标后，都能恢复当前宠物7%气血最大值。',
      },
      {
        奇穴名称: '不僵',
        奇穴图片: 'https://icon.jx3box.com/icon/17214.png',
        id: '30572',
        奇穴描述:
          '“百足”作用目标增加1个，首跳造成的伤害提高100%，且“百足”持续效果每跳造成的效果衰减6%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '凄切',
        奇穴图片: 'https://icon.jx3box.com/icon/2755.png',
        id: '6627',
        奇穴描述:
          '宠物免疫控制，宠物攻击目标，则自身恢复3%最大气血值和6%内力值，若攻击玩家则自身额外恢复2%内力值。',
      },
      {
        奇穴名称: '蛛魄',
        奇穴图片: 'https://icon.jx3box.com/icon/3035.png',
        id: '21819',
        奇穴描述:
          '“千丝”每释放两次后15秒内的下一个“千丝”可对目标范围释放，释放后从天而降一个巨大的蜘蛛，并吐出一个半径7尺的蛛网，范围内敌对目标移动速度降低60%，敌对侠士目标受到范围类伤害提高10%，并且无法施展轻功，持续4秒，4秒后蜘蛛自爆，对7尺范围内敌对目标造成毒性伤害，网内的目标会被拉向蛛网中心并封轻功4秒。',
      },
      {
        奇穴名称: '虫兽',
        奇穴图片: 'https://icon.jx3box.com/icon/3023.png',
        id: '6879',
        奇穴描述: '当宠物存在时，自身毒性内功基础攻击力提高15%，疗伤效果提高15%。',
      },
      {
        奇穴名称: '祭灵',
        奇穴图片: 'https://icon.jx3box.com/icon/2803.png',
        id: '18311',
        奇穴描述:
          '“蛊虫献祭”调息时间降低2秒，施展后立即重置所有宠物技能调息时间。“蛊虫献祭”后30秒内首次施展召唤宠物招式，额外获得一次对应的献祭宠物的效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '蟾啸',
        奇穴图片: 'https://icon.jx3box.com/icon/2784.png',
        id: '6625',
        奇穴描述: '“蟾啸”的调息时间降低2秒，持续时间提高4秒，立刻对当前目标造成大量毒性伤害。',
      },
      {
        奇穴名称: '重蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/19192.png',
        id: '34388',
        奇穴描述:
          '施展“引魂蛊术”套路招式命中敌对目标使自身恢复10%内力最大值，若命中带有“夺命蛊”效果的目标则6秒内“引魂蛊术”套路招式伤害提高15%。',
      },
      {
        奇穴名称: '荒骨',
        奇穴图片: 'https://icon.jx3box.com/icon/17711.png',
        id: '37356',
        奇穴描述: '自身不利效果被卸除时会基于被卸除时的剩余跳数造成额外伤害。',
      },
      {
        奇穴名称: '蝎噬',
        奇穴图片: 'https://icon.jx3box.com/icon/2757.png',
        id: '18312',
        奇穴描述: '“圣蝎”每攻击一次目标则减少“驭虫奇术”套路下技能调息时间2秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '不鸣',
        奇穴图片: 'https://icon.jx3box.com/icon/2801.png',
        id: '37952',
        奇穴描述:
          '宠物死亡时将对周围10尺最多5个目标造成点毒性伤害，并给其添加“蛇影”“蟾啸”持续伤害效果。',
      },
      {
        奇穴名称: '活祭',
        奇穴图片: 'https://icon.jx3box.com/icon/2809.png',
        id: '6636',
        奇穴描述:
          '当宠物死亡时，对周围6尺内最多5个敌方目标造成点毒性伤害，并使目标获得禁疗效果，持续3秒。',
      },
      {
        奇穴名称: '忘情',
        奇穴图片: 'https://icon.jx3box.com/icon/19191.png',
        id: '34640',
        奇穴描述: '“蛇影”持续不利效果可叠加两层，并且每次被驱散或者转移时，只会被作用一层。',
      },
      {
        奇穴名称: '盟誓',
        奇穴图片: 'https://icon.jx3box.com/icon/7478.png',
        id: '14864',
        奇穴描述:
          '“百足”会心几率提高10%，会心效果提高10%，施展“蛊虫献祭”后立刻重置“百足”调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '嗜蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/13442.png',
        id: '30088',
        奇穴描述:
          '施展“蛊虫献祭”后自身获得“嗜蛊”效果，持续30秒。“嗜蛊”效果持续期间自身攻击无视目标60%内功防御等级，无双等级提高30%，并且成功运功“蝎心”会对目标6尺内额外五个目标造成伤害。',
      },
      {
        奇穴名称: '毒手',
        奇穴图片: 'https://icon.jx3box.com/icon/3034.png',
        id: '6644',
        奇穴描述:
          '“玄水蛊”可以对20尺内的敌方玩家施展，造成点点伤害，从自身转移一个减益效果给敌方玩家，并且效果持续期间自身受到的60%伤害将由敌方玩家承担，期间目标气血低于1%或距离超过自身50尺则效果消失。（气血和距离限制不在名剑大会，阵营战场，绝境战场场景生效）',
      },
      {
        奇穴名称: '蛛王之触',
        奇穴图片: 'https://icon.jx3box.com/icon/11895.png',
        id: '38624',
        奇穴描述:
          '在敌方侠士目标当前位置创建蛊虫蛛王，吐出蛛丝链接目标，持续15秒，期间若目标距离蛛王超过3尺则会持续向自身方向拖拽目标。蛛王免疫控制，所有人均可攻击，受到伤害的30%会传递给目标。蛛王继承目标等级、防御、御劲、化劲属性和75％的最大生命值，在血量清空后消失。',
      },
      {
        奇穴名称: '幻蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/2780.png',
        id: '2218',
        奇穴描述: '将目标定身5秒并使其获得2层“灵蛊”效果，目标定身期间受到的所有伤害提高10%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '善友',
        奇穴图片: 'https://icon.jx3box.com/icon/2781.png',
        id: '18320',
        奇穴描述: '“圣蝎”存在期间，小队技能调息速度提高6%。',
      },
      {
        奇穴名称: '浮身',
        奇穴图片: 'https://icon.jx3box.com/icon/2813.png',
        id: '19306',
        奇穴描述:
          '“风蜈”存在期间，自身移动速度提高15%，施展“风蜈”主动技能“影滞”时使周围团队成员移动速度提高20%，持续5秒。',
      },
      {
        奇穴名称: '相照',
        奇穴图片: 'https://icon.jx3box.com/icon/23486.png',
        id: '6878',
        奇穴描述:
          '施展“蛊虫献祭”后释放的第一个“千劫万毒手”套路招式会同时释放对应蛊虫的主动技能，该效果不受奇穴修饰。',
      },
      {
        奇穴名称: '固灵',
        奇穴图片: 'https://icon.jx3box.com/icon/9561.png',
        id: '18330',
        奇穴描述: '“百足”调息时间降低2秒，持续效果作用间隔缩短1秒，总持续时间保持不变。',
      },
      {
        奇穴名称: '释灵',
        奇穴图片: 'https://icon.jx3box.com/icon/13445.png',
        id: '37351',
        奇穴描述:
          '施展“蛇影”命中已经有自身“蛇影”效果的目标，若该“蛇影”持续时间低于4秒，则给目标额外添加一个6秒的持续性伤害效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '驭虫',
        奇穴图片: 'https://icon.jx3box.com/icon/18316.png',
        id: '32741',
        奇穴描述:
          '使自身宠物召唤招式拥有15秒公用调息时间，宠物可以召唤到目标位置并且每次召唤宠物会使自身获得和免疫控制效果（推拉除外）和移动速度提高20%，持续6秒。施展招式“蛊虫献祭”时会重置该公用调息时间。',
      },
      {
        奇穴名称: '荒息',
        奇穴图片: 'https://icon.jx3box.com/icon/2782.png',
        id: '25018',
        奇穴描述:
          '“凤凰蛊”不再附带重伤起身效果，调息时间降低为30秒，对自身施展，使得自身获得“凤凰蛊”效果，移动速度提高5%，此效果持续3分钟。自身拥有“凤凰蛊”效果时施展“蛊虫献祭”会使得接下来35秒自身和宠物的默契增加，宠物移动速度提高20%，自身每造成两次伤害，宠物会额外对目标发动一次攻击，对目标造成点毒性内功伤害并减少“灵蛇引”10秒调息时间。在非战斗状态下施展“灵蛇引”则返还招式调息时间。',
      },
      {
        奇穴名称: '蛊影',
        奇穴图片: 'https://icon.jx3box.com/icon/9564.png',
        id: '18689',
        奇穴描述:
          '“灵蛊”可对自身、队友或者团队成员释放，使其免疫控制（击退和被拉除外），侠士目标受到的伤害降低30%，持续4秒。最多叠加到20秒。若对没有“迷心蛊”、“枯残蛊”、“夺命蛊”的敌方目标施展“灵蛊”则返还10秒调息时间。',
      },
      {
        奇穴名称: '共栖',
        奇穴图片: 'https://icon.jx3box.com/icon/19187.png',
        id: '36451',
        奇穴描述:
          '“玄水蛊”效果期间，若为自身承受伤害的目标未死亡，则效果结束后降低12秒“玄水蛊”调息时间并随机降低一个“引魂蛊术”招式5秒调息时间。',
      },
      {
        奇穴名称: '蟾噬',
        奇穴图片: 'https://icon.jx3box.com/icon/14139.png',
        id: '25027',
        奇穴描述:
          '替换“蟾躁”，施展后“玉蟾”可以对团队成员施展，解除其受到的控制效果，将其吞入口中保护，期间“玉蟾”不可移动，持续3秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '篾片蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/13444.png',
        id: '29545',
        奇穴描述:
          '为目标种下“篾片蛊”，持续8秒，“篾片蛊”会在目标体内逐渐成长，成长期间每次自身“千劫万毒手”套路招式和宠物招式攻击会提升“篾片蛊”的毒性，最多可提升4次。成长4次或时间结束则会对目标造成伤害且伤害量取决于持续时间内成长的次数。该招式视为“引魂蛊术”套路招式。',
      },
      {
        奇穴名称: '蝉蜕',
        奇穴图片: 'https://icon.jx3box.com/icon/2806.png',
        id: '6652',
        奇穴描述:
          '任何伤害使你的当前气血值低于1%时，若自身存在宠物，则牺牲宠物并解除自身控制，回复自身50%最大气血值，该效果最多每150秒触发一次。',
      },
      {
        奇穴名称: '蛇悉',
        奇穴图片: 'https://icon.jx3box.com/icon/2812.png',
        id: '21302',
        奇穴描述:
          '“蛇影”变为可移动运功技能，调息时间6秒，立即对目标造成点毒性伤害，同时使6秒内下一次施展“蝎心”无需运动。“蛇影”持续时间增加四秒，若命中带有“夺命蛊”效果的目标则回复自身10%内力最大值。',
      },
      {
        奇穴名称: '蛊虫狂暴',
        奇穴图片: 'https://icon.jx3box.com/icon/2763.png',
        id: '2227',
        奇穴描述:
          '移除自身及小队成员所有控制效果（小队成员击倒除外），移动速度提高40%并免疫控制（击退和被拉除外）持续5秒，并使当前宠物恢复全部气血值，气血最大值提高50%，移动速度提高50%，免疫控制（击退和被拉除外），防御等级提高100%，伤害提高50%，持续16秒。自身招式伤害提高10%，加速率提高10%，持续16秒，同时目标将获得“虫毒”不利效果，持续45秒。该效果下，无法再次获得“蛊虫狂暴”效果。有宠物时方能施展。',
      },
      {
        奇穴名称: '忘攸蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/17215.png',
        id: '30540',
        奇穴描述:
          '五毒侠士为自身添加“忘攸蛊”保护自身，最多可持续8秒，“忘攸蛊”持续时间内自身受到的伤害降低50%，并可施展二段招式“忘攸蛊·迭迷”：将“忘攸蛊”对敌方侠士施展，使其移动速度和加速率降低30%，持续4秒，并为目标添加一层“蛊毒”。4秒后“忘攸蛊”毒性会更强，对敌方侠士施展，会使其移动速度和加速率降低60%，持续4秒，且为目标添加两层“蛊毒”。该招式视为“引魂蛊术”套路招式。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '二圣',
        奇穴图片: 'https://icon.jx3box.com/icon/14138.png',
        id: '25038',
        奇穴描述:
          '施展“化蝶”，召唤蝴蝶从自身脚底位置扩散，对20尺范围最多5个目标造成毒性内功伤害，且刷新目标身上属于自身的持续伤害效果时间。并为目标添加“展缓”不利效果，使其6秒后受到和累计移动距离有关的伤害。',
      },
      {
        奇穴名称: '引魂',
        奇穴图片: 'https://icon.jx3box.com/icon/2714.png',
        id: '18325',
        奇穴描述:
          '“灵蛇”招式“幻击”施展距离提高至15尺。“灵蛇引”不再立即召唤宠物，而是使宠物残影附身在自身身上，宠物攻击距离提高10尺，伤害提高40%，自身伤害提高10%，移动速度提高20%，持续10秒，10秒后分离。',
      },
      {
        奇穴名称: '曳光',
        奇穴图片: 'https://icon.jx3box.com/icon/2762.png',
        id: '14861',
        奇穴描述:
          '施展“蛊虫献祭”或“化蝶”后，使自身受到的伤害降低40%，持续4秒，该效果每12秒最多触发一次。',
      },
      {
        奇穴名称: '奇术',
        奇穴图片: 'https://icon.jx3box.com/icon/18313.png',
        id: '32788',
        奇穴描述:
          '5秒内对不同的玩家添加“蛊毒”会将他们连接起来，持续6秒，对被连接的任一目标添加持续伤害效果时会为其余被连接的目标也添加相同的持续伤害效果并有25%几率重置自身“百足”招式调息时间。',
      },
      {
        奇穴名称: '急星',
        奇穴图片: 'https://icon.jx3box.com/icon/17213.png',
        id: '38581',
        奇穴描述: '自身“千劫万毒手”套路招式添加的持续伤害效果间隔时间降低30%，总跳数不变。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '寂灭蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/17710.png',
        id: '32753',
        奇穴描述:
          '每0.5秒对自身6尺范围最多3个敌方玩家造成伤害，并为其添加一层持续3秒的“寂灭”不利效果，3秒后造成一次大额伤害，延长目标身上属于自己的持续不利伤害效果2跳并恢复自身50%已损失气血值。期间自身免疫控制、免疫打断、打退、无法运功、缴械效果。“寂灭”：移动速度降低50%，友方目标对其造成伤害会额外附带一次伤害。该招式视为“引魂蛊术”套路招式。',
      },
      {
        奇穴名称: '残香',
        奇穴图片: 'https://icon.jx3box.com/icon/11894.png',
        id: '38455',
        奇穴描述:
          '“蝎心”伤害提高40%，运功“蝎心”招式命中非侠士目标后使目标身上已有的属于自身“千劫万毒手”套路的持续不利效果立即额外造成一跳伤害，且下一次施展除“蝎心”外的“千劫万毒手”套路招式时返还6秒的调息时间并造成一次额外伤害。',
      },
      {
        奇穴名称: '怀泽蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/19189.png',
        id: '34654',
        奇穴描述:
          '为一名敌方玩家施种持续6秒的“怀泽蛊”并附带6秒“小荒泽域”效果，每秒对目标6尺内最多5个敌方玩家目标造成一次毒性内功伤害。“怀泽蛊”期间若目标受到治疗效果，会成长为持续25秒的“大荒泽域”，并将使目标每秒叠加一层“泽靡”效果。没有“泽靡”效果的玩家连续受到3次“大荒泽域”伤害时，会将“大荒泽域”转移至自身，并附带两层“泽靡”效果，原目标的“泽靡”被清空。毒经攻击带有自身“泽靡”效果的目标时会额外造成一次毒性内功伤害，层数越多伤害越高。该招式视为“引魂蛊术”套路招式。',
      },
      {
        奇穴名称: '蚀心蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/7233.png',
        id: '15121',
        奇穴描述:
          '使目标玩家不受控制，并随机攻击周围敌方目标，作用期间目标移动速度提高50%，受到的伤害降低50%，持续6秒，若目标是敌对玩家则命中时造成点点伤害。若对队友施展，则持续时间减半。该招式视为“引魂蛊术”套路招式。',
      },
      {
        奇穴名称: '连缘蛊',
        奇穴图片: 'https://icon.jx3box.com/icon/14140.png',
        id: '25043',
        奇穴描述:
          '运功每0.312秒对目标造成毒性内功伤害，持续期间不被打退、打断、不受无法施展内功招式和缴械效果的影响，自身免疫控制（被拉除外），持续1.56秒。目标每存在一个自身“千劫万毒手”套路持续伤害，则伤害额外提高80%。每跳降低自身“玄水蛊”1.5秒的调息时间。若完整运功则将额外对目标造成一次基于自身持续伤害数量的伤害。“连缘蛊”每段伤害都会造成一次基于自身持续伤害数量的（0.46*破招系数*破招值）到（1.035*破招系数*破招值）点破招伤害。该招式视为“引魂蛊术”套路招式。',
      },
    ],
  },
]

export default 奇穴数据
