import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'
// import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'
import 绝刀增益 from './技能增益/绝刀'
import 盾飞增益 from './技能增益/盾飞'
import 劫刀增益 from './技能增益/劫刀'
import 盾压增益 from './技能增益/盾压'
import 斩刀增益 from './技能增益/斩刀'
import 盾击增益 from './技能增益/盾击'
import { 属性类型 } from '@/@types/属性'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32745',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-629145.6),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-629145.6) },
      2: { 技能伤害系数: 获取破招实际系数(-854453.12512) },
      3: { 技能伤害系数: 获取破招实际系数(-314572.80000000005) },
      4: { 技能伤害系数: 获取破招实际系数(-766215.45472) },
      5: { 技能伤害系数: 获取破招实际系数(-209715.19999999995) },
      6: { 技能伤害系数: 获取破招实际系数(-104857.59999999998) },
      7: { 技能伤害系数: 获取破招实际系数(-815628.550144) },
      8: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      9: {
        技能伤害系数: 获取破招实际系数(-201494.36415999988),
        技能增益列表: [
          ...通用增益,
          {
            增益名称: '技能无视',
            增益启用: true,
            增益所在位置: '技能',
            增益类型: '全局启用',
            增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
          },
        ],
      },
    },
  },
  {
    技能ID: '37448',
    技能名称: '破·麟光',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-913513.5391744),
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38890',
    技能名称: '破·绝刀惊涌',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-709743.345664),
    技能增益列表: [
      ...通用增益,
      {
        // 9889
        增益名称: '刀煞',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      2: { 技能伤害系数: 获取破招实际系数(-641976.8147968) },
      3: { 技能伤害系数: 获取破招实际系数(-574210.2839295999) },
      4: { 技能伤害系数: 获取破招实际系数(-506443.7530623999) },
      5: { 技能伤害系数: 获取破招实际系数(-438677.2221951999) },
    },
  },
  {
    技能ID: '38889',
    技能名称: '破·绝刀',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-766215.45472),
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-766215.45472) },
      2: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      3: { 技能伤害系数: 获取破招实际系数(-653271.236608) },
      4: { 技能伤害系数: 获取破招实际系数(-596799.1275519999) },
      5: { 技能伤害系数: 获取破招实际系数(-540327.018496) },
    },
    技能增益列表: [
      ...通用增益,
      {
        // 9889
        增益名称: '刀煞',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '38971',
    技能名称: '破·盾舞',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-914882.56),
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-914882.56) },
      2: { 技能伤害系数: 获取破招实际系数(-888143.872) },
    },
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36065',
    技能名称: '击破·援戈',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-862207.34464),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-862207.34464) },
      2: { 技能伤害系数: 获取破招实际系数(-901235.1336448) },
      3: { 技能伤害系数: 获取破招实际系数(-901235.1336448) },
      4: { 技能伤害系数: 获取破招实际系数(-925791.944704) },
      5: { 技能伤害系数: 获取破招实际系数(-925791.944704) },
    },
  },
  {
    技能ID: '13039',
    技能名称: '卷雪刀',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13075',
    技能名称: '绝刀',
    技能伤害系数: 485,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13092',
    技能名称: '斩刀',
    技能伤害系数: 652,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '13099',
    技能名称: '盾猛',
    技能伤害系数: 643,
    基础伤害_基础值: 130,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '19409',
    技能名称: '盾压',
    技能伤害系数: 443,
    基础伤害_基础值: 202,
    基础伤害_浮动值: 18,
    武器伤害系数: 1,
    技能增益列表: 盾压增益,
  },
  {
    技能ID: '38973',
    技能名称: '盾舞',
    技能伤害系数: 156,
    基础伤害_基础值: 325,
    基础伤害_浮动值: 30,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13110',
    技能名称: '盾击',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13161',
    技能名称: '盾击·二',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13106',
    技能名称: '盾击·正常',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13160',
    技能名称: '盾击·正常换动作',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13107',
    技能名称: '盾击·双倍',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 208,
    基础伤害_浮动值: 20,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13108',
    技能名称: '盾击·三倍',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 312,
    基础伤害_浮动值: 31,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13463',
    技能名称: '盾飞',
    技能伤害系数: 27,
    基础伤害_基础值: 24,
    基础伤害_浮动值: 2,
    武器伤害系数: 1,
    技能增益列表: 盾飞增益,
  },
  {
    技能ID: '8249', // 8249_29188
    技能名称: '流血(DOT)',
    技能伤害系数: 291.06,
    DOT生效间隔: 32,
    DOT跳数: 13,
    基础伤害_基础值: 47,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '25780',
    技能名称: '盾击·神兵',
    技能伤害系数: 50,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28479',
    技能名称: '劫刀',
    技能伤害系数: 317,
    基础伤害_基础值: 110,
    基础伤害_浮动值: 29,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '30857',
    技能名称: '雁门迢递',
    统计名称: '阵云结晦',
    技能伤害系数: 810,
    基础伤害_基础值: 120,
    基础伤害_浮动值: 9,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '30925',
    技能名称: '阵云结晦',
    统计名称: '阵云结晦',
    技能伤害系数: 540,
    基础伤害_基础值: 72,
    基础伤害_浮动值: 2,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '30926',
    技能名称: '月照连营',
    统计名称: '阵云结晦',
    技能伤害系数: 675,
    基础伤害_基础值: 96,
    基础伤害_浮动值: 3,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '34674',
    技能名称: '麟光甲寒',
    技能伤害系数: 377.52000000000004,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34714',
    技能名称: '业火焚城·云盾',
    技能伤害系数: 228.8,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36482',
    技能名称: '援戈·血影',
    技能伤害系数: 687.2399999999999,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37253',
    技能名称: '麾远',
    技能伤害系数: 340,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  // ===============
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
