import { 属性类型 } from '@/@types/属性'
import { 小药小吃数据类型 } from '@/@types/小药小吃'
import { 小吃类型枚举 } from '@/@types/枚举'
import 通用小药小吃数据 from '../通用'

const 内功小药小吃数据: 小药小吃数据类型[] = [
  {
    小吃名称: '风语·中品展凤散（内攻）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 712 }],
  },
  {
    小吃名称: '风语·上品展凤散（内攻）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 1424 }],
  },
  {
    小吃名称: '炼气·秘境·增（内攻）',
    小吃部位: 小吃类型枚举.药品增强,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 266 }],
  },
  {
    小吃名称: '风语·中品静心丸（根骨）',
    小吃部位: 小吃类型枚举.药品辅助,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.根骨, 值: 301 }],
  },
  {
    小吃名称: '风语·上品静心丸（根骨）',
    小吃部位: 小吃类型枚举.药品辅助,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.根骨, 值: 602 }],
  },
  {
    小吃名称: '风语·中品聚魂丹（元气）',
    小吃部位: 小吃类型枚举.药品辅助,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.元气, 值: 301 }],
  },
  {
    小吃名称: '风语·上品聚魂丹（元气）',
    小吃部位: 小吃类型枚举.药品辅助,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.元气, 值: 602 }],
  },
  {
    小吃名称: '风语·鲜肉包子（内攻）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 554 }],
  },
  {
    小吃名称: '风语·灌汤包（内攻）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 1108 }],
  },
  {
    小吃名称: '强身·秘境·增（内攻）',
    小吃部位: 小吃类型枚举.食品增强,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 207 }],
  },
  {
    小吃名称: '风语·老火骨汤（根骨）',
    小吃部位: 小吃类型枚举.食品辅助,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.根骨, 值: 234 }],
  },
  {
    小吃名称: '风语·咸骨粥（根骨）',
    小吃部位: 小吃类型枚举.食品辅助,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.根骨, 值: 469 }],
  },
  {
    小吃名称: '风语·鱼头豆腐汤（元气）',
    小吃部位: 小吃类型枚举.食品辅助,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.元气, 值: 234 }],
  },
  {
    小吃名称: '风语·鱼片砂锅粥（元气）',
    小吃部位: 小吃类型枚举.食品辅助,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.元气, 值: 469 }],
  },
  {
    小吃名称: '风语·坠宵磨石（内攻）',
    小吃部位: 小吃类型枚举.武器磨石,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 475 }],
  },
  {
    小吃名称: '风语·坠宵熔锭（内攻）',
    小吃部位: 小吃类型枚举.武器磨石,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 949 }],
  },
  {
    小吃名称: '高粱酒·旬又三（根骨）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.根骨, 值: 344 }],
  },
  {
    小吃名称: '高粱酒·六日醉（根骨）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.根骨, 值: 258 }],
  },
  {
    小吃名称: '高粱酒·今朝醉（根骨）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.根骨, 值: 172 }],
  },
  {
    小吃名称: '高粱酒（根骨）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.根骨, 值: 86 }],
  },
  {
    小吃名称: '状元红·旬又三（元气）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.元气, 值: 344 }],
  },
  {
    小吃名称: '状元红·六日醉（元气）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.元气, 值: 258 }],
  },
  {
    小吃名称: '状元红·今朝醉（元气）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.元气, 值: 172 }],
  },
  {
    小吃名称: '状元红（元气）',
    小吃部位: 小吃类型枚举.家园酒品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.元气, 值: 86 }],
  },
  {
    小吃名称: '小炒青菜（内攻）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '蓝',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 384 }],
  },
  {
    小吃名称: '风语·小炒青菜（内攻）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    增益集合: [{ 属性: 属性类型.内功基础攻击, 值: 768 }],
  },
  {
    小吃名称: '风语·二十四桥明月夜',
    小吃部位: 小吃类型枚举.团队宴席,
    小吃品级: '紫',
    增益集合: [
      { 属性: 属性类型.内功基础攻击, 值: 633 },
      { 属性: 属性类型.全会心等级, 值: 2088 },
      { 属性: 属性类型.破招值, 值: 2088 },
    ],
  },
  {
    小吃名称: '创意料理（-破招+攻击）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    增益集合: [
      { 属性: 属性类型.全基础攻击, 值: 1382 },
      { 属性: 属性类型.破招值, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-无双+攻击）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    增益集合: [
      { 属性: 属性类型.全基础攻击, 值: 1382 },
      { 属性: 属性类型.无双等级, 值: -2032 },
    ],
  },
  {
    小吃名称: '创意料理（-气血+攻击）',
    小吃部位: 小吃类型枚举.家园菜品,
    小吃品级: '紫',
    增益集合: [
      { 属性: 属性类型.全基础攻击, 值: 1382 },
      { 属性: 属性类型.额外气血上限, 值: -2032 },
    ],
  },
  ...通用小药小吃数据,
]

export default 内功小药小吃数据
