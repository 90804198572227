import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 103852,
    uid: 103852,
    装备名称: '陶然意·拥狸帽',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103851,
    uid: 103851,
    装备名称: '陶然意·万仞帽',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103848,
    uid: 103848,
    装备名称: '陶然意·徇节帽',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103847,
    uid: 103847,
    装备名称: '陶然意·临溟帽',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103846,
    uid: 103846,
    装备名称: '陶然意·上造帽',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103844,
    uid: 103844,
    装备名称: '陶然意·征惯帽',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103842,
    uid: 103842,
    装备名称: '陶然意·春瓮帽',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103841,
    uid: 103841,
    装备名称: '陶然意·吴陵帽',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103840,
    uid: 103840,
    装备名称: '陶然意·穿心帽',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103836,
    uid: 103836,
    装备名称: '陶然意·蜕羽帽',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103834,
    uid: 103834,
    装备名称: '陶然意·登锋帽',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 984 },
      { 属性: 属性类型.外功基础攻击, 值: 1605 },
      { 属性: 属性类型.外功会心等级, 值: 8860 },
      { 属性: 属性类型.全能等级, 值: 3544 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101617,
    uid: 101617,
    装备名称: '花永冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.身法, 值: 1060 },
      { 属性: 属性类型.外功基础攻击, 值: 2408 },
      { 属性: 属性类型.破招值, 值: 8860 },
      { 属性: 属性类型.无双等级, 值: 7088 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101616,
    uid: 101616,
    装备名称: '花世冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12995 },
      { 属性: 属性类型.力道, 值: 1060 },
      { 属性: 属性类型.外功基础攻击, 值: 2408 },
      { 属性: 属性类型.破招值, 值: 8860 },
      { 属性: 属性类型.无双等级, 值: 7088 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101659,
    uid: 101659,
    装备名称: '客行江湖·泉葩冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101658,
    uid: 101658,
    装备名称: '客行江湖·冥绝冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101551,
    uid: 101551,
    装备名称: '羡雁帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101550,
    uid: 101550,
    装备名称: '君深帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101515,
    uid: 101515,
    装备名称: '留诗冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101514,
    uid: 101514,
    装备名称: '清月冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101455,
    uid: 101455,
    装备名称: '孤漠·阑影冠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101454,
    uid: 101454,
    装备名称: '孤漠·苍雨帽',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101450,
    uid: 101450,
    装备名称: '孤漠·狂澜冠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101449,
    uid: 101449,
    装备名称: '孤漠·舟帆冠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101448,
    uid: 101448,
    装备名称: '孤漠·庭月冠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101444,
    uid: 101444,
    装备名称: '孤漠·雁际冠',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101441,
    uid: 101441,
    装备名称: '孤漠·泛浪冠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101440,
    uid: 101440,
    装备名称: '孤漠·寒鸣冠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101439,
    uid: 101439,
    装备名称: '孤漠·照江冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101433,
    uid: 101433,
    装备名称: '孤漠·立霄冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101430,
    uid: 101430,
    装备名称: '孤漠·踏沙战盔',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101264,
    uid: 101264,
    装备名称: '漠名冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101263,
    uid: 101263,
    装备名称: '漠若冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104447,
    uid: 104447,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 4513 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功破防等级, 值: 5727 },
      { 属性: 属性类型.无双等级, 值: 5727 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104446,
    uid: 104446,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 3735 },
      { 属性: 属性类型.外功会心等级, 值: 6300 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104445,
    uid: 104445,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 5292 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101811,
    uid: 101811,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 4513 },
      { 属性: 属性类型.外功会心等级, 值: 5727 },
      { 属性: 属性类型.外功破防等级, 值: 5727 },
      { 属性: 属性类型.无双等级, 值: 5727 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101810,
    uid: 101810,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 3735 },
      { 属性: 属性类型.外功会心等级, 值: 6300 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101809,
    uid: 101809,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12600 },
      { 属性: 属性类型.外功基础攻击, 值: 5292 },
      { 属性: 属性类型.无双等级, 值: 12600 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104435,
    uid: 104435,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 4336 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 5502 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104434,
    uid: 104434,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 4336 },
      { 属性: 属性类型.外功会心等级, 值: 8254 },
      { 属性: 属性类型.外功会心效果等级, 值: 4127 },
      { 属性: 属性类型.无双等级, 值: 4127 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104433,
    uid: 104433,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 3589 },
      { 属性: 属性类型.外功破防等级, 值: 12656 },
      { 属性: 属性类型.无双等级, 值: 6603 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101787,
    uid: 101787,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 4336 },
      { 属性: 属性类型.外功会心等级, 值: 5502 },
      { 属性: 属性类型.破招值, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 5502 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101786,
    uid: 101786,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 4336 },
      { 属性: 属性类型.外功会心等级, 值: 8254 },
      { 属性: 属性类型.外功会心效果等级, 值: 4127 },
      { 属性: 属性类型.无双等级, 值: 4127 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101785,
    uid: 101785,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简适应之力,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12105 },
      { 属性: 属性类型.外功基础攻击, 值: 3589 },
      { 属性: 属性类型.外功破防等级, 值: 12656 },
      { 属性: 属性类型.无双等级, 值: 6603 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104423,
    uid: 104423,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 4159 },
      { 属性: 属性类型.外功会心等级, 值: 5278 },
      { 属性: 属性类型.外功破防等级, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104422,
    uid: 104422,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 4159 },
      { 属性: 属性类型.外功会心等级, 值: 5278 },
      { 属性: 属性类型.破招值, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104421,
    uid: 104421,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 3442 },
      { 属性: 属性类型.外功会心等级, 值: 12139 },
      { 属性: 属性类型.无双等级, 值: 6333 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101763,
    uid: 101763,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 4159 },
      { 属性: 属性类型.外功会心等级, 值: 5278 },
      { 属性: 属性类型.外功破防等级, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101762,
    uid: 101762,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 4159 },
      { 属性: 属性类型.外功会心等级, 值: 5278 },
      { 属性: 属性类型.破招值, 值: 5278 },
      { 属性: 属性类型.无双等级, 值: 5278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101761,
    uid: 101761,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11611 },
      { 属性: 属性类型.外功基础攻击, 值: 3442 },
      { 属性: 属性类型.外功会心等级, 值: 12139 },
      { 属性: 属性类型.无双等级, 值: 6333 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103690,
    uid: 103690,
    装备名称: '陶然意·拥狸帽',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103689,
    uid: 103689,
    装备名称: '陶然意·万仞帽',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.力道, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103686,
    uid: 103686,
    装备名称: '陶然意·徇节帽',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103685,
    uid: 103685,
    装备名称: '陶然意·临溟帽',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103684,
    uid: 103684,
    装备名称: '陶然意·上造帽',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.力道, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103682,
    uid: 103682,
    装备名称: '陶然意·征惯帽',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103680,
    uid: 103680,
    装备名称: '陶然意·春瓮帽',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.力道, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103679,
    uid: 103679,
    装备名称: '陶然意·吴陵帽',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103678,
    uid: 103678,
    装备名称: '陶然意·穿心帽',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.力道, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103674,
    uid: 103674,
    装备名称: '陶然意·蜕羽帽',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.身法, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103672,
    uid: 103672,
    装备名称: '陶然意·登锋帽',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11167 },
      { 属性: 属性类型.力道, 值: 846 },
      { 属性: 属性类型.外功基础攻击, 值: 1379 },
      { 属性: 属性类型.外功会心等级, 值: 7614 },
      { 属性: 属性类型.全能等级, 值: 3045 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 104411,
    uid: 104411,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 3982 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功破防等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 5053 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104410,
    uid: 104410,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 3296 },
      { 属性: 属性类型.外功会心等级, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104409,
    uid: 104409,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 4669 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103513,
    uid: 103513,
    装备名称: '孤山寒月·逐刃冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103512,
    uid: 103512,
    装备名称: '孤山寒月·归期冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101739,
    uid: 101739,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 3982 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.外功破防等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 5053 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101738,
    uid: 101738,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 3296 },
      { 属性: 属性类型.外功会心等级, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101737,
    uid: 101737,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.外功基础攻击, 值: 4669 },
      { 属性: 属性类型.无双等级, 值: 11117 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101641,
    uid: 101641,
    装备名称: '客行江湖·烟景冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101640,
    uid: 101640,
    装备名称: '客行江湖·秋序冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101222,
    uid: 101222,
    装备名称: '扬明帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101221,
    uid: 101221,
    装备名称: '重悬帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101186,
    uid: 101186,
    装备名称: '静言冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101185,
    uid: 101185,
    装备名称: '棣仪冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101126,
    uid: 101126,
    装备名称: '西塞·离巢冠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101125,
    uid: 101125,
    装备名称: '西塞·秋星冠',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101121,
    uid: 101121,
    装备名称: '西塞·麟台冠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101120,
    uid: 101120,
    装备名称: '西塞·川冥冠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101119,
    uid: 101119,
    装备名称: '西塞·流电冠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101115,
    uid: 101115,
    装备名称: '西塞·行歌盔',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101112,
    uid: 101112,
    装备名称: '西塞·平塘冠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101111,
    uid: 101111,
    装备名称: '西塞·赤眉冠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101110,
    uid: 101110,
    装备名称: '西塞·江鸥冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101104,
    uid: 101104,
    装备名称: '西塞·冷辉冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101101,
    uid: 101101,
    装备名称: '西塞·壮志战盔',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100995,
    uid: 100995,
    装备名称: '寻踪觅宝·方思帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100994,
    uid: 100994,
    装备名称: '寻踪觅宝·汉广帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104399,
    uid: 104399,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3823 },
      { 属性: 属性类型.外功会心等级, 值: 4851 },
      { 属性: 属性类型.破招值, 值: 4851 },
      { 属性: 属性类型.无双等级, 值: 4851 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104398,
    uid: 104398,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3823 },
      { 属性: 属性类型.外功会心等级, 值: 7277 },
      { 属性: 属性类型.外功会心效果等级, 值: 3638 },
      { 属性: 属性类型.无双等级, 值: 3638 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104397,
    uid: 104397,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3164 },
      { 属性: 属性类型.外功破防等级, 值: 11158 },
      { 属性: 属性类型.无双等级, 值: 5821 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101715,
    uid: 101715,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3823 },
      { 属性: 属性类型.外功会心等级, 值: 4851 },
      { 属性: 属性类型.破招值, 值: 4851 },
      { 属性: 属性类型.无双等级, 值: 4851 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101714,
    uid: 101714,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3823 },
      { 属性: 属性类型.外功会心等级, 值: 7277 },
      { 属性: 属性类型.外功会心效果等级, 值: 3638 },
      { 属性: 属性类型.无双等级, 值: 3638 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101713,
    uid: 101713,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10673 },
      { 属性: 属性类型.外功基础攻击, 值: 3164 },
      { 属性: 属性类型.外功破防等级, 值: 11158 },
      { 属性: 属性类型.无双等级, 值: 5821 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100965,
    uid: 100965,
    装备名称: '寻踪觅宝·迭微帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.身法, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100964,
    uid: 100964,
    装备名称: '寻踪觅宝·予吉帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.力道, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100935,
    uid: 100935,
    装备名称: '壁明帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.身法, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100934,
    uid: 100934,
    装备名称: '壁邺帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.力道, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104387,
    uid: 104387,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3664 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.外功破防等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104386,
    uid: 104386,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3664 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.破招值, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104385,
    uid: 104385,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3032 },
      { 属性: 属性类型.外功会心等级, 值: 10693 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101691,
    uid: 101691,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3664 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.外功破防等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101690,
    uid: 101690,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3664 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.破招值, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 4649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101689,
    uid: 101689,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.外功基础攻击, 值: 3032 },
      { 属性: 属性类型.外功会心等级, 值: 10693 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100803,
    uid: 100803,
    装备名称: '语晚帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.身法, 值: 834 },
      { 属性: 属性类型.外功基础攻击, 值: 1895 },
      { 属性: 属性类型.外功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100802,
    uid: 100802,
    装备名称: '语梧帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.力道, 值: 834 },
      { 属性: 属性类型.外功基础攻击, 值: 1895 },
      { 属性: 属性类型.外功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104494,
    uid: 104494,
    装备名称: '平皋冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104493,
    uid: 104493,
    装备名称: '淮沛冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104466,
    uid: 104466,
    装备名称: '羽缀冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104465,
    uid: 104465,
    装备名称: '夏凌冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104138,
    uid: 104138,
    装备名称: '润源冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104137,
    uid: 104137,
    装备名称: '辞羁冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100905,
    uid: 100905,
    装备名称: '润源冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100904,
    uid: 100904,
    装备名称: '辞羁冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100869,
    uid: 100869,
    装备名称: '平皋冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100868,
    uid: 100868,
    装备名称: '淮沛冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100833,
    uid: 100833,
    装备名称: '羽缀冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100832,
    uid: 100832,
    装备名称: '夏凌冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100701,
    uid: 100701,
    装备名称: '镂云帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100700,
    uid: 100700,
    装备名称: '炎翳帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100665,
    uid: 100665,
    装备名称: '智渊帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100664,
    uid: 100664,
    装备名称: '韶曦帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 104375,
    uid: 104375,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 3505 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功破防等级, 值: 4447 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104374,
    uid: 104374,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 2900 },
      { 属性: 属性类型.外功会心等级, 值: 4892 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104373,
    uid: 104373,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 4109 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101667,
    uid: 101667,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 3505 },
      { 属性: 属性类型.外功会心等级, 值: 4447 },
      { 属性: 属性类型.外功破防等级, 值: 4447 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101666,
    uid: 101666,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 2900 },
      { 属性: 属性类型.外功会心等级, 值: 4892 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101665,
    uid: 101665,
    装备名称: '无修冠·外·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9783 },
      { 属性: 属性类型.外功基础攻击, 值: 4109 },
      { 属性: 属性类型.无双等级, 值: 9783 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100779,
    uid: 100779,
    装备名称: '语晚帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9586 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1776 },
      { 属性: 属性类型.外功破防等级, 值: 6536 },
      { 属性: 属性类型.无双等级, 值: 5228 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100778,
    uid: 100778,
    装备名称: '语梧帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9586 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1776 },
      { 属性: 属性类型.外功破防等级, 值: 6536 },
      { 属性: 属性类型.无双等级, 值: 5228 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100629,
    uid: 100629,
    装备名称: '修篁帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9289 },
      { 属性: 属性类型.身法, 值: 758 },
      { 属性: 属性类型.外功基础攻击, 值: 1721 },
      { 属性: 属性类型.加速等级, 值: 6333 },
      { 属性: 属性类型.无双等级, 值: 5067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100628,
    uid: 100628,
    装备名称: '绮陌帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9289 },
      { 属性: 属性类型.力道, 值: 758 },
      { 属性: 属性类型.外功基础攻击, 值: 1721 },
      { 属性: 属性类型.加速等级, 值: 6333 },
      { 属性: 属性类型.无双等级, 值: 5067 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100755,
    uid: 100755,
    装备名称: '语晚帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8943 },
      { 属性: 属性类型.身法, 值: 730 },
      { 属性: 属性类型.外功基础攻击, 值: 1657 },
      { 属性: 属性类型.外功破防等级, 值: 6098 },
      { 属性: 属性类型.无双等级, 值: 4878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100754,
    uid: 100754,
    装备名称: '语梧帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8943 },
      { 属性: 属性类型.力道, 值: 730 },
      { 属性: 属性类型.外功基础攻击, 值: 1657 },
      { 属性: 属性类型.外功破防等级, 值: 6098 },
      { 属性: 属性类型.无双等级, 值: 4878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100593,
    uid: 100593,
    装备名称: '涵煦巾',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8696 },
      { 属性: 属性类型.身法, 值: 709 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功破防等级, 值: 5929 },
      { 属性: 属性类型.无双等级, 值: 4743 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100592,
    uid: 100592,
    装备名称: '惜霜巾',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8696 },
      { 属性: 属性类型.力道, 值: 709 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功破防等级, 值: 5929 },
      { 属性: 属性类型.无双等级, 值: 4743 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100731,
    uid: 100731,
    装备名称: '语晚帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.身法, 值: 677 },
      { 属性: 属性类型.外功基础攻击, 值: 1538 },
      { 属性: 属性类型.外功破防等级, 值: 5660 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100730,
    uid: 100730,
    装备名称: '语梧帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8301 },
      { 属性: 属性类型.力道, 值: 677 },
      { 属性: 属性类型.外功基础攻击, 值: 1538 },
      { 属性: 属性类型.外功破防等级, 值: 5660 },
      { 属性: 属性类型.无双等级, 值: 4528 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101857,
    uid: 101857,
    装备名称: '春齐冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.身法, 值: 665 },
      { 属性: 属性类型.外功基础攻击, 值: 1511 },
      { 属性: 属性类型.破招值, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101856,
    uid: 101856,
    装备名称: '曲汜冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.力道, 值: 665 },
      { 属性: 属性类型.外功基础攻击, 值: 1511 },
      { 属性: 属性类型.破招值, 值: 5559 },
      { 属性: 属性类型.无双等级, 值: 4447 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100557,
    uid: 100557,
    装备名称: '嵘嶂帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.身法, 值: 618 },
      { 属性: 属性类型.外功基础攻击, 值: 1007 },
      { 属性: 属性类型.外功破防等级, 值: 5559 },
      { 属性: 属性类型.全能等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100556,
    uid: 100556,
    装备名称: '湛影帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8153 },
      { 属性: 属性类型.力道, 值: 618 },
      { 属性: 属性类型.外功基础攻击, 值: 1007 },
      { 属性: 属性类型.外功破防等级, 值: 5559 },
      { 属性: 属性类型.全能等级, 值: 2223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 帽子装备数据
