import { 技能增益列表类型 } from '@/@types/技能'
import { 属性类型 } from '@/@types/属性'
import 通用增益 from './通用增益/通用'
import 傍身招式增益 from './通用增益/傍身招式增益'

const 击水三千增益: 技能增益列表类型[] = [
  ...通用增益,
  ...傍身招式增益,
  {
    // 70188_20
    增益名称: '无界_通用易伤_20',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 205 / 1024 }],
  },
  {
    // 70188_30
    增益名称: '无界_通用易伤_30',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 308 / 1024 }],
  },
]

export default 击水三千增益
