import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰坠装备数据: 装备属性信息模型[] = [
  {
    id: 42567,
    uid: 42567,
    装备名称: '陶然意·月容坠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42564,
    uid: 42564,
    装备名称: '陶然意·修茂坠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42563,
    uid: 42563,
    装备名称: '陶然意·理乾坠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42559,
    uid: 42559,
    装备名称: '陶然意·来仪坠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42557,
    uid: 42557,
    装备名称: '陶然意·凡圣坠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42553,
    uid: 42553,
    装备名称: '陶然意·连筠坠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.外功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42552,
    uid: 42552,
    装备名称: '陶然意·蛇言坠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42551,
    uid: 42551,
    装备名称: '陶然意·寒河坠',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42549,
    uid: 42549,
    装备名称: '陶然意·清静坠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42547,
    uid: 42547,
    装备名称: '陶然意·簇锦坠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42546,
    uid: 42546,
    装备名称: '陶然意·祇树坠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 547 },
      { 属性: 属性类型.内功基础攻击, 值: 992 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41418,
    uid: 41418,
    装备名称: '江空坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.元气, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1487 },
      { 属性: 属性类型.全会心等级, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41417,
    uid: 41417,
    装备名称: '山深坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7219 },
      { 属性: 属性类型.根骨, 值: 589 },
      { 属性: 属性类型.内功基础攻击, 值: 1487 },
      { 属性: 属性类型.内功会心等级, 值: 4922 },
      { 属性: 属性类型.无双等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41362,
    uid: 41362,
    装备名称: '寻幽径',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41361,
    uid: 41361,
    装备名称: '画青山',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41338,
    uid: 41338,
    装备名称: '青桑坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41337,
    uid: 41337,
    装备名称: '季春坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41320,
    uid: 41320,
    装备名称: '游山坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 41319,
    uid: 41319,
    装备名称: '思玉坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 41302,
    uid: 41302,
    装备名称: '漠晖坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41301,
    uid: 41301,
    装备名称: '漠安坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 42835,
    uid: 42835,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.全会心等级, 值: 4773 },
      { 属性: 属性类型.全会心效果等级, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 2386 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42834,
    uid: 42834,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.内功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42833,
    uid: 42833,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 3269 },
      { 属性: 属性类型.无双等级, 值: 7000 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41515,
    uid: 41515,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.全会心等级, 值: 4773 },
      { 属性: 属性类型.全会心效果等级, 值: 2386 },
      { 属性: 属性类型.无双等级, 值: 2386 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41514,
    uid: 41514,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 2788 },
      { 属性: 属性类型.内功破防等级, 值: 3182 },
      { 属性: 属性类型.破招值, 值: 3182 },
      { 属性: 属性类型.无双等级, 值: 3182 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41513,
    uid: 41513,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7000 },
      { 属性: 属性类型.内功基础攻击, 值: 3269 },
      { 属性: 属性类型.无双等级, 值: 7000 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42823,
    uid: 42823,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2679 },
      { 属性: 属性类型.全会心等级, 值: 3057 },
      { 属性: 属性类型.内功破防等级, 值: 3057 },
      { 属性: 属性类型.无双等级, 值: 3057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42822,
    uid: 42822,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.全会心等级, 值: 3363 },
      { 属性: 属性类型.无双等级, 值: 6725 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42821,
    uid: 42821,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.内功破防等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41503,
    uid: 41503,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2679 },
      { 属性: 属性类型.全会心等级, 值: 3057 },
      { 属性: 属性类型.内功破防等级, 值: 3057 },
      { 属性: 属性类型.无双等级, 值: 3057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41502,
    uid: 41502,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠会效,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.全会心等级, 值: 3363 },
      { 属性: 属性类型.无双等级, 值: 6725 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41501,
    uid: 41501,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 24500,
    装备特效: 装备特效枚举.精简腰坠破防,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6725 },
      { 属性: 属性类型.内功基础攻击, 值: 2217 },
      { 属性: 属性类型.内功破防等级, 值: 7031 },
      { 属性: 属性类型.无双等级, 值: 3668 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41406,
    uid: 41406,
    装备名称: '江空坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.元气, 值: 546 },
      { 属性: 属性类型.内功基础攻击, 值: 1380 },
      { 属性: 属性类型.全会心等级, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41405,
    uid: 41405,
    装备名称: '山深坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 24400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6698 },
      { 属性: 属性类型.根骨, 值: 546 },
      { 属性: 属性类型.内功基础攻击, 值: 1380 },
      { 属性: 属性类型.内功会心等级, 值: 4567 },
      { 属性: 属性类型.无双等级, 值: 3653 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42811,
    uid: 42811,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 4398 },
      { 属性: 属性类型.全会心效果等级, 值: 2199 },
      { 属性: 属性类型.无双等级, 值: 2199 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42810,
    uid: 42810,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 2932 },
      { 属性: 属性类型.内功破防等级, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42809,
    uid: 42809,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.全会心等级, 值: 6744 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41491,
    uid: 41491,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 4398 },
      { 属性: 属性类型.全会心效果等级, 值: 2199 },
      { 属性: 属性类型.无双等级, 值: 2199 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41490,
    uid: 41490,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2569 },
      { 属性: 属性类型.全会心等级, 值: 2932 },
      { 属性: 属性类型.内功破防等级, 值: 2932 },
      { 属性: 属性类型.无双等级, 值: 2932 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41489,
    uid: 41489,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 23500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6451 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.全会心等级, 值: 6744 },
      { 属性: 属性类型.无双等级, 值: 3519 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42486,
    uid: 42486,
    装备名称: '陶然意·月容坠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42483,
    uid: 42483,
    装备名称: '陶然意·修茂坠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42482,
    uid: 42482,
    装备名称: '陶然意·理乾坠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42478,
    uid: 42478,
    装备名称: '陶然意·来仪坠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42476,
    uid: 42476,
    装备名称: '陶然意·凡圣坠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42472,
    uid: 42472,
    装备名称: '陶然意·连筠坠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42471,
    uid: 42471,
    装备名称: '陶然意·蛇言坠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42470,
    uid: 42470,
    装备名称: '陶然意·寒河坠',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42468,
    uid: 42468,
    装备名称: '陶然意·清静坠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42466,
    uid: 42466,
    装备名称: '陶然意·簇锦坠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42465,
    uid: 42465,
    装备名称: '陶然意·祇树坠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 852 },
      { 属性: 属性类型.内功会心等级, 值: 4230 },
      { 属性: 属性类型.全能等级, 值: 1692 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42799,
    uid: 42799,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.全会心效果等级, 值: 2106 },
      { 属性: 属性类型.无双等级, 值: 2106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42798,
    uid: 42798,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.内功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42797,
    uid: 42797,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2884 },
      { 属性: 属性类型.无双等级, 值: 6176 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41479,
    uid: 41479,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.全会心效果等级, 值: 2106 },
      { 属性: 属性类型.无双等级, 值: 2106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41478,
    uid: 41478,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2460 },
      { 属性: 属性类型.内功破防等级, 值: 2807 },
      { 属性: 属性类型.破招值, 值: 2807 },
      { 属性: 属性类型.无双等级, 值: 2807 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41477,
    uid: 41477,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 22500,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.内功基础攻击, 值: 2884 },
      { 属性: 属性类型.无双等级, 值: 6176 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41394,
    uid: 41394,
    装备名称: '江空坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41393,
    uid: 41393,
    装备名称: '山深坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41272,
    uid: 41272,
    装备名称: '映宵坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41271,
    uid: 41271,
    装备名称: '云眷坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41254,
    uid: 41254,
    装备名称: '江汜坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 41253,
    uid: 41253,
    装备名称: '合苏坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 42787,
    uid: 42787,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 2362 },
      { 属性: 属性类型.全会心等级, 值: 2695 },
      { 属性: 属性类型.内功破防等级, 值: 2695 },
      { 属性: 属性类型.无双等级, 值: 2695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42786,
    uid: 42786,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.全会心等级, 值: 2965 },
      { 属性: 属性类型.无双等级, 值: 5929 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42785,
    uid: 42785,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.内功破防等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 41467,
    uid: 41467,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 2362 },
      { 属性: 属性类型.全会心等级, 值: 2695 },
      { 属性: 属性类型.内功破防等级, 值: 2695 },
      { 属性: 属性类型.无双等级, 值: 2695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41466,
    uid: 41466,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.全会心等级, 值: 2965 },
      { 属性: 属性类型.无双等级, 值: 5929 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41465,
    uid: 41465,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 21600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5929 },
      { 属性: 属性类型.内功基础攻击, 值: 1955 },
      { 属性: 属性类型.内功破防等级, 值: 6199 },
      { 属性: 属性类型.无双等级, 值: 3234 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 42775,
    uid: 42775,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 3874 },
      { 属性: 属性类型.全会心效果等级, 值: 1937 },
      { 属性: 属性类型.无双等级, 值: 1937 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42774,
    uid: 42774,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 2583 },
      { 属性: 属性类型.内功破防等级, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 42773,
    uid: 42773,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 1873 },
      { 属性: 属性类型.全会心等级, 值: 5940 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41455,
    uid: 41455,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 3874 },
      { 属性: 属性类型.全会心效果等级, 值: 1937 },
      { 属性: 属性类型.无双等级, 值: 1937 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41454,
    uid: 41454,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 2263 },
      { 属性: 属性类型.全会心等级, 值: 2583 },
      { 属性: 属性类型.内功破防等级, 值: 2583 },
      { 属性: 属性类型.无双等级, 值: 2583 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41453,
    uid: 41453,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 20700,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.内功基础攻击, 值: 1873 },
      { 属性: 属性类型.全会心等级, 值: 5940 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41170,
    uid: 41170,
    装备名称: '语可坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41169,
    uid: 41169,
    装备名称: '语乐坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41382,
    uid: 41382,
    装备名称: '江空坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.元气, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.全会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41381,
    uid: 41381,
    装备名称: '山深坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.根骨, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.内功会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42868,
    uid: 42868,
    装备名称: '玉凉坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42867,
    uid: 42867,
    装备名称: '樛枝坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42852,
    uid: 42852,
    装备名称: '斛尘坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 42851,
    uid: 42851,
    装备名称: '熙春坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 42740,
    uid: 42740,
    装备名称: '灵空·未判坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42739,
    uid: 42739,
    装备名称: '灵空·心斋坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42627,
    uid: 42627,
    装备名称: '昭文坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42626,
    uid: 42626,
    装备名称: '丘墟坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41224,
    uid: 41224,
    装备名称: '昭文坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41223,
    uid: 41223,
    装备名称: '丘墟坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41206,
    uid: 41206,
    装备名称: '玉凉坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41205,
    uid: 41205,
    装备名称: '樛枝坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41188,
    uid: 41188,
    装备名称: '斛尘坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 41187,
    uid: 41187,
    装备名称: '熙春坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 41098,
    uid: 41098,
    装备名称: '弥弦佩',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41097,
    uid: 41097,
    装备名称: '珀音佩',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41080,
    uid: 41080,
    装备名称: '霖瑜佩',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41079,
    uid: 41079,
    装备名称: '瑾辞佩',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 773 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 42763,
    uid: 42763,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.全会心等级, 值: 3706 },
      { 属性: 属性类型.全会心效果等级, 值: 1853 },
      { 属性: 属性类型.无双等级, 值: 1853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42762,
    uid: 42762,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.内功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 42761,
    uid: 42761,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2538 },
      { 属性: 属性类型.无双等级, 值: 5435 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41443,
    uid: 41443,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.全会心等级, 值: 3706 },
      { 属性: 属性类型.全会心效果等级, 值: 1853 },
      { 属性: 属性类型.无双等级, 值: 1853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41442,
    uid: 41442,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2165 },
      { 属性: 属性类型.内功破防等级, 值: 2471 },
      { 属性: 属性类型.破招值, 值: 2471 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41441,
    uid: 41441,
    装备名称: '无修坠·内·荒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 19800,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5435 },
      { 属性: 属性类型.内功基础攻击, 值: 2538 },
      { 属性: 属性类型.无双等级, 值: 5435 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41152,
    uid: 41152,
    装备名称: '语可坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.元气, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.破招值, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41151,
    uid: 41151,
    装备名称: '语乐坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5325 },
      { 属性: 属性类型.根骨, 值: 434 },
      { 属性: 属性类型.内功基础攻击, 值: 1097 },
      { 属性: 属性类型.破招值, 值: 3631 },
      { 属性: 属性类型.无双等级, 值: 2905 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41062,
    uid: 41062,
    装备名称: '惜晗坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.元气, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.全会心等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41061,
    uid: 41061,
    装备名称: '濯缨坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5161 },
      { 属性: 属性类型.根骨, 值: 421 },
      { 属性: 属性类型.内功基础攻击, 值: 1063 },
      { 属性: 属性类型.内功会心等级, 值: 3519 },
      { 属性: 属性类型.无双等级, 值: 2815 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41134,
    uid: 41134,
    装备名称: '语可坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.元气, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.破招值, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41133,
    uid: 41133,
    装备名称: '语乐坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18100,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4968 },
      { 属性: 属性类型.根骨, 值: 405 },
      { 属性: 属性类型.内功基础攻击, 值: 1024 },
      { 属性: 属性类型.破招值, 值: 3388 },
      { 属性: 属性类型.无双等级, 值: 2710 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41044,
    uid: 41044,
    装备名称: '绢素坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.元气, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.破招值, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41043,
    uid: 41043,
    装备名称: '烨霖坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4831 },
      { 属性: 属性类型.根骨, 值: 394 },
      { 属性: 属性类型.内功基础攻击, 值: 995 },
      { 属性: 属性类型.破招值, 值: 3294 },
      { 属性: 属性类型.无双等级, 值: 2635 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41116,
    uid: 41116,
    装备名称: '语可坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.元气, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.破招值, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 41115,
    uid: 41115,
    装备名称: '语乐坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4612 },
      { 属性: 属性类型.根骨, 值: 376 },
      { 属性: 属性类型.内功基础攻击, 值: 950 },
      { 属性: 属性类型.破招值, 值: 3144 },
      { 属性: 属性类型.无双等级, 值: 2515 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 41526,
    uid: 41526,
    装备名称: '轻桡坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41525,
    uid: 41525,
    装备名称: '临流坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 370 },
      { 属性: 属性类型.内功基础攻击, 值: 933 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.无双等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41026,
    uid: 41026,
    装备名称: '凝玥坠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.元气, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.全会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41025,
    uid: 41025,
    装备名称: '静漪坠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4529 },
      { 属性: 属性类型.根骨, 值: 343 },
      { 属性: 属性类型.内功基础攻击, 值: 622 },
      { 属性: 属性类型.内功会心等级, 值: 3088 },
      { 属性: 属性类型.全能等级, 值: 1235 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 腰坠装备数据
