export default {
  装备基础属性: {
    基础攻击: 46940,
    破防等级: 73936,
    无双等级: 89422,
    会心等级: 33100,
    会心效果等级: 3759,
    全能等级: 0,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    破招值: 1668,
    力道: 44,
    元气: 44,
    身法: 7863,
    根骨: 44,
    体质: 134555,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
    装分: 506032,
  },
  装备镶嵌附魔属性: {
    基础攻击: 10809,
    破防等级: 7514,
    无双等级: 2082,
    会心等级: 9130,
    会心效果等级: 834,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 1668,
    力道: 44,
    元气: 44,
    身法: 256,
    根骨: 44,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 2089,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101449,
      装备部位: '帽子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101480,
      装备部位: '衣服',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101387,
      装备部位: '腰带',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101579,
      装备部位: '护腕',
      附魔: '破防+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101589,
      装备部位: '下装',
      附魔: '破防+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101418,
      装备部位: '鞋子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41316,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41364,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41371,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41374,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39289,
      装备部位: '暗器',
      附魔: '加速+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39350,
      装备部位: '武器',
      附魔: '攻击+568',
    },
  ],
  五彩石: '彩·无双·痛击·锐刃(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 1,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
