import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 小橙武技能增益 from './通用增益/小橙武技能增益'

const 银月斩DOT增益: 技能增益列表类型[] = [
  ...通用增益,
  ...小橙武技能增益,
  {
    增益名称: '腾焰飞芒',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
    ],
  },
  {
    增益名称: '5%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.05 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.03 }],
  },
]

export default 银月斩DOT增益
