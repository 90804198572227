import { 装备增益类型 } from '@/@types/装备'
import { 角色基础属性类型 } from '@/@types/角色'

const 试炼装备特效加成 = (人物面板: 角色基础属性类型, 装备增益: 装备增益类型): 角色基础属性类型 => {
  if (装备增益?.精简项链破防) {
    人物面板 = 项链破防转攻击算法(人物面板, 装备增益?.精简项链破防)
  }
  if (装备增益?.精简项链会心) {
    人物面板 = 项链会心转会效算法(人物面板, 装备增益?.精简项链会心)
  }
  if (装备增益?.精简适应之力) {
    人物面板 = 适应算法(人物面板, 装备增益?.精简适应之力)
  }
  return 人物面板
}

// 加成等级 1 普通 2 英雄
const 项链破防转攻击算法 = (人物面板: 角色基础属性类型, 加成等级: number): 角色基础属性类型 => {
  const 破防等级 = 人物面板?.破防等级
  const 是否为内功 = 人物面板?.元气 > 人物面板?.力道 || 人物面板?.根骨 > 人物面板?.力道
  let 加成攻击数值 = 0
  if (加成等级 === 1) {
    const 每层攻击加成 = 是否为内功 ? 33 : 30
    加成攻击数值 = (破防等级 / 5427) * 每层攻击加成
  } else {
    const 每层攻击加成 = 是否为内功 ? 35 : 32
    加成攻击数值 = (破防等级 / 5648) * 每层攻击加成
  }
  return {
    ...人物面板,
    基础攻击: (人物面板?.基础攻击 || 0) + 加成攻击数值,
    面板攻击: (人物面板?.面板攻击 || 0) + 加成攻击数值,
  }
}

// 加成等级 1 普通 2 英雄
const 项链会心转会效算法 = (人物面板: 角色基础属性类型, 加成等级): 角色基础属性类型 => {
  const 会心等级 = 人物面板?.会心等级
  let 加成会心效果数值 = 0
  if (加成等级 === 1) {
    加成会心效果数值 = (会心等级 / 4748) * 109
  } else {
    加成会心效果数值 = (会心等级 / 4942) * 114
  }
  return {
    ...人物面板,
    会心效果等级: (人物面板?.会心效果等级 || 0) + 加成会心效果数值,
  }
}

// 取会心破防破招的最大值再进行加成的算法
const 适应算法 = (人物面板: 角色基础属性类型, 加成等级: number): 角色基础属性类型 => {
  let 最终面板 = { ...人物面板 }
  const 加成数值 = 加成等级 === 1 ? 2440 : 2540
  const 会心等级 = 人物面板?.会心等级
  const 破防等级 = 人物面板?.破防等级
  const 破招值 = 人物面板?.破招值
  const 最大值 = Math.max(会心等级, 破防等级, 破招值)

  if (最大值 === 破防等级) {
    最终面板 = {
      ...最终面板,
      破防等级: 最终面板.破防等级 + 加成数值,
    }
  } else if (最大值 === 会心等级) {
    最终面板 = {
      ...最终面板,
      会心等级: 最终面板.会心等级 + 加成数值,
    }
  } else if (最大值 === 破招值) {
    最终面板 = {
      ...最终面板,
      破招值: 最终面板.破招值 + 加成数值,
    }
  }

  return 最终面板
}

export default 试炼装备特效加成
