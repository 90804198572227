import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用'
import 贯穿增益 from './技能增益/贯穿'
import 劲风簇增益 from './技能增益/劲风簇'
import 标鹄增益 from './技能增益/标鹄'
import 饮羽簇增益 from './技能增益/饮羽簇'
import 宠物增益 from './技能增益/宠物通用'
import 风矢增益 from './技能增益/风矢'
// import 朝仪万汇增益 from './技能增益/朝仪万汇'

const 贯穿伤害系数 = 215 * 0.7 * 1.15 * 0.9 * 0.9 * 0.9 * 1.1
const 贯穿DOT间隔 = 8
const 贯穿跳数 = 4

const 贯穿基础伤害 = 32

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '标鹄',
    技能伤害系数: 553.7664,
    基础伤害_基础值: 30,
    基础伤害_浮动值: 20,
    技能增益列表: 标鹄增益,
  },
  {
    技能名称: '劲风簇',
    技能伤害系数: 154.86187499999997,
    基础伤害_基础值: 333,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 劲风簇增益,
  },
  {
    技能名称: '饮羽簇',
    技能伤害系数: 552 * 0.9 * 0.9 * 0.95 * 0.9 * 0.95 * 1.1,
    基础伤害_基础值: 732,
    基础伤害_浮动值: 10,
    武器伤害系数: 2,
    技能增益列表: 饮羽簇增益,
  },
  {
    技能名称: '霖急簇',
    技能伤害系数: 160 * 1.1,
    基础伤害_基础值: 28,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '风矢',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 风矢增益,
  },
  {
    技能名称: '攻击-狼',
    统计名称: '攻击',
    技能伤害系数: 276 * 1.05,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '攻击-虎',
    统计名称: '攻击',
    技能伤害系数: 869.4000000000001,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '攻击-鹰',
    统计名称: '攻击',
    技能伤害系数: 607 * 1.05,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '攻击-熊',
    统计名称: '攻击',
    技能伤害系数: 165 * 1.05,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '重击', // 野猪
    技能伤害系数: 276 * 1.05,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '践踏', // 大象
    技能伤害系数: 607 * 1.05,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '贯穿·1',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·2',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·3',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·4',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·5',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·6',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 6,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·8',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4 * 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·9',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 3 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·10',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5 * 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·12',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·15',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·18',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 6 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '白虹贯日',
    技能伤害系数: 7.25,
    基础伤害_基础值: 400,
    基础伤害_浮动值: 200,
    武器伤害系数: 2,
    技能增益列表: 通用增益,
  },
  {
    // 实测没有武伤
    技能ID: '36453',
    技能名称: '朝仪万汇',
    秘籍依赖技能: '劲风簇',
    技能伤害系数: 368.94000000000005,
    基础伤害_基础值: 37,
    基础伤害_浮动值: 5,
    技能增益列表: 劲风簇增益,
  },
  {
    技能名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.3 - 1)),
    武器伤害系数: 0,
    伤害计算次数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '劲风簇·神兵',
    技能伤害系数: 60,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  // CW期间饮羽命中后施加的额外伤害
  {
    技能名称: '月弦激星',
    技能伤害系数: 390,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  贯穿增益,
  劲风簇增益,
  标鹄增益,
  饮羽簇增益,
  宠物增益,
  风矢增益,
}

export { 技能增益 }
