import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'

// 流血吃横刀断浪会心秘籍
const 流血通用增益: 技能增益列表类型[] = [
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.03 }],
  },
]

const 流血1层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.1 }],
  },
]

const 流血2层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.2 }],
  },
]

const 流血3层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.3 }],
  },
]

const 流血4层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.4 }],
  },
]

const 流血5层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.5 }],
  },
]

const 流血6层增益: 技能增益列表类型[] = [
  ...通用增益,
  ...流血通用增益,
  {
    增益名称: '涤瑕',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.6 }],
  },
]

export { 流血1层增益, 流血2层增益, 流血3层增益, 流血4层增益, 流血5层增益, 流血6层增益 }
