import { GLOBAL_CDN_PREFIX } from '@/工具函数/const'
// import ImgBg_1 from './bg.png'

const ImgBg_1 = `${GLOBAL_CDN_PREFIX}/bg_yjj_1.jpg`
// const ImgBg_2 = `${GLOBAL_CDN_PREFIX}/bg_txj_2.png`
// const ImgBg_3 = `${GLOBAL_CDN_PREFIX}/bg_txj_3.png`
// const ImgBg_4 = `${GLOBAL_CDN_PREFIX}/bg_txj_4.png`

// export default [ImgBg_1, ImgBg_2, ImgBg_3, ImgBg_4]
export default [ImgBg_1]
