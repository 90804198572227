import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '无幽不烛',
        奇穴图片: 'https://icon.jx3box.com/icon/3826.png',
        id: '5969',
        奇穴描述: '“赤日轮”、“幽月轮”的伤害提高10%。',
      },
      {
        奇穴名称: '腾焰飞芒',
        奇穴图片: 'https://icon.jx3box.com/icon/3825.png',
        id: '5972',
        奇穴描述: '“烈日斩”“银月斩”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '血泪成悦',
        奇穴图片: 'https://icon.jx3box.com/icon/3819.png',
        id: '5974',
        奇穴描述: '造成伤害的10%转化为自身气血。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '日月凌天',
        奇穴图片: 'https://icon.jx3box.com/icon/3831.png',
        id: '5971',
        奇穴描述: '“赤日轮”、“幽月轮”获得的日灵、月魂提高30%。',
      },
      {
        奇穴名称: '无往不复',
        奇穴图片: 'https://icon.jx3box.com/icon/3827.png',
        id: '37274',
        奇穴描述: '“赤日轮”“幽月轮”对带有“烈日”的目标造成伤害提高40%。',
      },
      {
        奇穴名称: '净身明礼',
        奇穴图片: 'https://icon.jx3box.com/icon/3793.png',
        id: '18279',
        奇穴描述:
          '“烈日斩”“银月斩”伤害提高25%，命中目标，对目标周围6尺的5个目标造成伤害。该伤害不会受到“烈日斩”“银月斩”秘籍的影响。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '普愿离诸',
        奇穴图片: 'https://icon.jx3box.com/icon/17702.png',
        id: '37275',
        奇穴描述: '“驱夜断愁”命中时，使目标身上属于自身的“月劫”效果提升至60%，且无法被驱散和刷新。',
      },
      {
        奇穴名称: '洞若观火',
        奇穴图片: 'https://icon.jx3box.com/icon/3837.png',
        id: '5978',
        奇穴描述:
          '“烈日斩”或者消耗“满日”后立即对周围8尺的5个目标造成阳性内功伤害，施展“银月斩”或者消耗“满月”则造成阴性内功伤害。',
      },
      {
        奇穴名称: '诛邪镇魔',
        奇穴图片: 'https://icon.jx3box.com/icon/11829.png',
        id: '22888',
        奇穴描述:
          '施展“净世破魔击”8秒内再次施展“净世破魔击”“生死劫”，使“净世破魔击”强化为“诛邪镇魔”，根据破魔击日月类型，对目标造成一次阴性或阳性的混合内功伤害（对非侠士目标造成伤害增加50%并使自身10秒内对非侠士造成伤害提高6%）。该伤害视作“净世破魔击”伤害，享受对应的秘籍效果伤害加成。',
      },
      {
        奇穴名称: '寒月耀',
        奇穴图片: 'https://icon.jx3box.com/icon/3787.png',
        id: '3961',
        奇穴描述:
          '对目标及目标周围8尺范围内最多6个敌对目标造成点阴性内功伤害并打断目标运功，成功打断后使目标4秒内无法施展内功招式，每击中一个目标获得10点月魂。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '无明业火',
        奇穴图片: 'https://icon.jx3box.com/icon/3836.png',
        id: '6717',
        奇穴描述: '在目标背后施展伤害招式则自身会心几率提高10%，会心效果提高5%。',
      },
      {
        奇穴名称: '幽隐尘迹',
        奇穴图片: 'https://icon.jx3box.com/icon/3824.png',
        id: '5985',
        奇穴描述: '“净世破魔击·日”造成伤害时无视目标15%化劲。',
      },
      {
        奇穴名称: '燎原烈火',
        奇穴图片: 'https://icon.jx3box.com/icon/3835.png',
        id: '5967',
        奇穴描述: '消耗满日或满月后，自身获得5%伤害吸收盾，最多叠加2层，持续8秒。',
      },
      {
        奇穴名称: '无间影狱',
        奇穴图片: 'https://icon.jx3box.com/icon/3829.png',
        id: '26710',
        奇穴描述:
          '处于“暗尘弥散”状态下超过3秒，在解除伪装状态时获得抵挡最多自身20%气血值的伤害吸收盾，期间自身伤害提高10%，持续10秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '明光恒照',
        奇穴图片: 'https://icon.jx3box.com/icon/3825.png',
        id: '34383',
        奇穴描述:
          '消耗满日后，使下一次消耗满月招式额外受到24%阴性内功攻击力加成。&lt;br/&gt;消耗满月后，使下一次消耗满日招式额外受到24%阳性内功攻击力加成。',
      },
      {
        奇穴名称: '恶秽满路',
        奇穴图片: 'https://icon.jx3box.com/icon/4578.png',
        id: '5989',
        奇穴描述: '“驱夜断愁”在目标背后施展时，使目标无法使用轻功，持续3秒。',
      },
      {
        奇穴名称: '烈血灼芒',
        奇穴图片: 'https://icon.jx3box.com/icon/3805.png',
        id: '21174',
        奇穴描述:
          '“暗尘弥散”或“暗尘”状态下施展“驱夜断愁”标记目标玩家，持续6秒，结束后对目标造成标记期间受到伤害的20%的额外真实伤害。不可对已经标记的目标重复标记。',
      },
      {
        奇穴名称: '赤日赫炎',
        奇穴图片: 'https://icon.jx3box.com/icon/18288.png',
        id: '32622',
        奇穴描述:
          '满日状态施展“生死劫”会标记玩家目标，持续3秒，结束后使目标受到等同于“赤日赫炎”效果期间受到治疗量30%的伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '辉耀红尘',
        奇穴图片: 'https://icon.jx3box.com/icon/3843.png',
        id: '5968',
        奇穴描述:
          '“净世破魔击·日”：命中目标后有100%几率使目标灼烧，使目标每2秒受到点阳性内功伤害，持续10秒。&lt;br/&gt;“净世破魔击·月”：命中目标后获得20点日灵。',
      },
      {
        奇穴名称: '秽身浴露',
        奇穴图片: 'https://icon.jx3box.com/icon/3832.png',
        id: '6718',
        奇穴描述:
          '“贪魔体”施展后卸除自身所有混元、阳性、阴性、毒性及持续伤害不利效果，效果结束后恢复25%气血。',
      },
      {
        奇穴名称: '日月同辉',
        奇穴图片: 'https://icon.jx3box.com/icon/3834.png',
        id: '34395',
        奇穴描述: '每施展3次“烈日斩”可激活一次“驱夜断愁”，若命中非侠士目标则直接激活。',
      },
      {
        奇穴名称: '秽孽如形',
        奇穴图片: 'https://icon.jx3box.com/icon/3794.png',
        id: '18282',
        奇穴描述:
          '“驱夜断愁”会心几率提高10%。“驱夜断愁”在目标背后施展时，立刻降低“怖畏暗刑”2秒调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '靡业报劫',
        奇穴图片: 'https://icon.jx3box.com/icon/19171.png',
        id: '34372',
        奇穴描述:
          '“生死劫”调息时间增加14秒。施展后使目标受到“靡业报劫”：每2秒受到对应阴阳属性的内功伤害，持续14秒。目标最大气血值每高于自身最大气血值的100%，则伤害提升100%，最多提升300%。若消耗满灵施展，则立即对其造成点内功伤害，并在“靡业报劫”造成完整伤害后再次对其造成点内功伤害。',
      },
      {
        奇穴名称: '超凡入圣',
        奇穴图片: 'https://icon.jx3box.com/icon/3823.png',
        id: '38523',
        奇穴描述:
          '“怖畏暗刑”期间目标每受到一次伤害，使自身“御暗烬灭令”套路下其它招式调息时间降低1秒，该效果每0.5秒最多触发一次。',
      },
      {
        奇穴名称: '极本溯源',
        奇穴图片: 'https://icon.jx3box.com/icon/3841.png',
        id: '34511',
        奇穴描述:
          '施展“贪魔体”每秒对周围8尺内10个目标造成伤害并减速90%。触发的二段招式改为：冲向敌对侠士使其眩晕2秒，短暂延迟后向8尺内的敌对侠士再次冲刺，每次冲刺延长2秒贪魔体持续时间，最多冲刺5次。',
      },
      {
        奇穴名称: '善恶如梦',
        奇穴图片: 'https://icon.jx3box.com/icon/3867.png',
        id: '6727',
        奇穴描述: '施展“生死劫”，同时使目标受到“生死劫·日”和“生死劫·月”的效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '用晦而明',
        奇穴图片: 'https://icon.jx3box.com/icon/3846.png',
        id: '17567',
        奇穴描述: '对受到“烈日”不利效果的目标施展伤害招式无视目标55%的内功防御。',
      },
      {
        奇穴名称: '伏明众生',
        奇穴图片: 'https://icon.jx3box.com/icon/9537.png',
        id: '18626',
        奇穴描述:
          '满日方可施展。进入“日盈”状态6秒，期间“净世破魔击”释放后获得满日，每2秒在自身位置产生一个日影，对前方180度的6尺扇形区域内最多6个目标造成点阳性内功伤害后消失。',
      },
      {
        奇穴名称: '采曜生明',
        奇穴图片: 'https://icon.jx3box.com/icon/12725.png',
        id: '30928',
        奇穴描述: '施展4次“生死劫”后，获得1层“光明相”效果。',
      },
      {
        奇穴名称: '影随光生',
        奇穴图片: 'https://icon.jx3box.com/icon/17165.png',
        id: '30776',
        奇穴描述:
          '“光明相”效果生效后获得10秒“影随”效果：施展“驱夜断愁”后额外获得40点“日灵”“月魂”。',
      },
      {
        奇穴名称: '五明乐见',
        奇穴图片: 'https://icon.jx3box.com/icon/13403.png',
        id: '25160',
        奇穴描述: '“光明相”调息时间降低5秒，效果提高至3次。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '天地诛戮',
        奇穴图片: 'https://icon.jx3box.com/icon/3830.png',
        id: '5979',
        奇穴描述:
          '施展“驱夜断愁”后自身的内功基础攻击提高10%，威胁值降低30%，造成伤害的10%转化成自身气血，持续20秒。',
      },
      {
        奇穴名称: '光影',
        奇穴图片: 'https://icon.jx3box.com/icon/4173.png',
        id: '6893',
        奇穴描述:
          '施展“幻光步”，解除自身“无法施展内功招式”效果影响，并不会受到此效果影响，持续3秒。',
      },
      {
        奇穴名称: '净体不畏',
        奇穴图片: 'https://icon.jx3box.com/icon/14103.png',
        id: '25166',
        奇穴描述:
          '“烈日斩”“银月斩”变为2层7秒充能招式。施展“日月净世”套路中的阳性招式命中“银月斩”持续伤害，或施展套路中阴性招式命中“烈日”效果的目标时，根据触发招式类型，分别造成阳性内功伤害或阴性内功伤害。',
      },
      {
        奇穴名称: '生灭予夺',
        奇穴图片: 'https://icon.jx3box.com/icon/3799.png',
        id: '3978',
        奇穴描述:
          '立刻重置“暗尘弥散”“驱夜断愁” “流光囚影”“无明魂锁”调息时间，并使自身日灵、月魂的获取速度提高100%，持续10秒。',
      },
      {
        奇穴名称: '夷数神光',
        奇穴图片: 'https://icon.jx3box.com/icon/14105.png',
        id: '25163',
        奇穴描述: '施展“流光囚影”，使得自身3秒内可以施展“驱夜断愁”。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '秘影诡行',
        奇穴图片: 'https://icon.jx3box.com/icon/3800.png',
        id: '18634',
        奇穴描述: '“幻光步”变为瞬间释放，并可解除自身受控状态(击倒除外)。',
      },
      {
        奇穴名称: '降灵尊',
        奇穴图片: 'https://icon.jx3box.com/icon/7257.png',
        id: '38526',
        奇穴描述:
          '获得满月、满日，使自身12秒内施展招式无威胁并激活“驱夜断愁”，消耗满日恢复20点月魂，消耗满月获得20点日灵。且耗灵技会对当前目标触发一次破招伤害，以此方式触发破招时，对目标追加一次内功伤害。',
      },
      {
        奇穴名称: '万念俱寂',
        奇穴图片: 'https://icon.jx3box.com/icon/3818.png',
        id: '6724',
        奇穴描述: '“驱夜断愁”调息时间增加至6秒，无需隐身状态，可直接施展，获得日灵月魂降低为20点。',
      },
      {
        奇穴名称: '极乐引',
        奇穴图片: 'https://icon.jx3box.com/icon/4179.png',
        id: '3971',
        奇穴描述:
          '将自身周围10尺范围内最多6个敌对目标拉至身边，在“焚影圣诀”下使其眩晕4秒。在“明尊琉璃体”下，强制敌人攻击自身，持续4秒，并使自身造成的威胁值提高100%，周围30尺范围内所有团队成员获得无威胁气劲，持续6秒。',
      },
      {
        奇穴名称: '清静心',
        奇穴图片: 'https://icon.jx3box.com/icon/18291.png',
        id: '32661',
        奇穴描述:
          '进入“清静心”状态，持续10秒。期间受到控制效果时结束该状态，并在8秒内可施展二段招式“晦冥曳”：解除自身受控状态，使自身免疫控制与内外功伤害，持续5秒。二段招式施展时不受无法运功效果影响。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '悬象著明',
        奇穴图片: 'https://icon.jx3box.com/icon/19168.png',
        id: '34347',
        奇穴描述:
          '耗灵技，需消耗满日或满月，两者对应效果不同。&lt;br/&gt;满日：对目标造成阳性内功伤害，使悬象·日覆盖自身。&lt;br/&gt;满月：对目标造成阴性内功伤害，使悬象·月覆盖自身。&lt;br/&gt;有悬象时，每次自身施展“日月净世”套路招式，悬象会施展与其日月相同类型的悬象招式。悬象施展6次招式后消失，视为自身施展，但不获得或消耗“日灵”“月魂”，伤害不受秘籍影响。',
      },
      {
        奇穴名称: '妙相常晖',
        奇穴图片: 'https://icon.jx3box.com/icon/18289.png',
        id: '32663',
        奇穴描述:
          '自身在“光明相”效果期间消耗满月施展“生死劫”会附带“霜月”效果，在目标脚下生成一个冰柱，持续8秒。冰柱被攻击3次后消失，存在期间目标被禁锢，招式施展距离降低30%。',
      },
      {
        奇穴名称: '劳心忡寿',
        奇穴图片: 'https://icon.jx3box.com/icon/7441.png',
        id: '14695',
        奇穴描述:
          '“暗尘弥散”调息时间降低5秒，战斗状态下隐身持续时间增加2秒，施展“暗尘弥散”后进入调息期间自身受到的范围类伤害降低20%。',
      },
      {
        奇穴名称: '明齐日月',
        奇穴图片: 'https://icon.jx3box.com/icon/11833.png',
        id: '22743',
        奇穴描述:
          '在自身范围内生成8尺“羁影”效果，效果持续期间范围内自身被命中的几率降低35%，最多4名敌对玩家致盲且持续期间视觉会产生影响，效果持续3秒。',
      },
      {
        奇穴名称: '净恶瑕诛',
        奇穴图片: 'https://icon.jx3box.com/icon/20074.png',
        id: '36093',
        奇穴描述:
          '对敌方侠士施展“流光囚影”后，下三次“幽月轮”“赤日轮”施展后返还0.5秒公共调息时间，并使其伤害无视目标15%化劲。若以此方式施展三次“赤日轮”或“幽月轮”，使自身净世破魔击造成伤害提升30%，持续5秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '日月齐光',
        奇穴图片: 'https://icon.jx3box.com/icon/7228.png',
        id: '34370',
        奇穴描述:
          '在12秒内分别消耗满日与满月后，使自身触发“日月齐光”：内功伤害提升8%，持续20秒，最多叠加3层，效果完全结束时减少一层。',
      },
      {
        奇穴名称: '崇光斩恶',
        奇穴图片: 'https://icon.jx3box.com/icon/21960.png',
        id: '37337',
        奇穴描述:
          '“光明相”替换为“崇光斩恶”，每消耗2次满灵后可施展一次，最多可累计6次施展次数。施展时将双刀合并挥出，对目标劈出一刀造成无质阳性伤害和一段破招伤害，连续施展时，该招式伤害依次提升，施展第三刀后重置。目标最大气血高于自身最大气血六倍时，伤害提高175%。',
      },
      {
        奇穴名称: '日月晦',
        奇穴图片: 'https://icon.jx3box.com/icon/16204.png',
        id: '28593',
        奇穴描述:
          '向目标方向进行一次12尺的冲刺，10秒内可再次施展两次冲刺。第一次冲刺后获得满日，并对冲刺路径4尺造成。第二次冲刺后获得满月，并对冲刺路径4尺造成。第三次冲刺后获得40点月魂、日灵，并对冲刺路径4尺造成点阴性内功伤害和点阳性内功伤害。该招式可在位移过程中施展。三段冲刺均命中侠士目标时进入一次4秒“暗尘弥散”状态，否则使自身被命中几率降低100%。“日月晦”伤害命中时附带18秒“银月斩”效果。',
      },
      {
        奇穴名称: '冥月渡心',
        奇穴图片: 'https://icon.jx3box.com/icon/9556.png',
        id: '18629',
        奇穴描述:
          '满月方可施展，月魂满足要求则进入“暗步”状态，“暗步”状态持续7秒，结束后进入2秒“暗尘弥散”状态。“暗步”期间内：免疫控制效果（被推被拉除外），净世破魔击伤害提升30%，施展“净世破魔击”后获得满月。',
      },
      {
        奇穴名称: '微妙风',
        奇穴图片: 'https://icon.jx3box.com/icon/14107.png',
        id: '25171',
        奇穴描述:
          '对自身6尺范围最多5个目标造成5次点阴性内功伤害和点阳性内功伤害，期间自身获得“微妙风”效果，且受到范围伤害降低90%。每次命中则将降低“贪魔体”1秒的调息时间。命中带有“烈日”“银月斩”效果目标时将造成一次范围爆炸并传染“烈日”“银月斩”。',
      },
    ],
  },
]

export default 奇穴数据
