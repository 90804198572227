import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 下装装备数据: 装备属性信息模型[] = [
  {
    id: 103830,
    uid: 103830,
    装备名称: '陶然意·拥狸裤',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103829,
    uid: 103829,
    装备名称: '陶然意·万仞裤',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103826,
    uid: 103826,
    装备名称: '陶然意·徇节裤',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103825,
    uid: 103825,
    装备名称: '陶然意·临溟裤',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103824,
    uid: 103824,
    装备名称: '陶然意·上造裤',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103822,
    uid: 103822,
    装备名称: '陶然意·征惯裤',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103820,
    uid: 103820,
    装备名称: '陶然意·春瓮裤',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103819,
    uid: 103819,
    装备名称: '陶然意·吴陵裤',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103818,
    uid: 103818,
    装备名称: '陶然意·穿心裤',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103814,
    uid: 103814,
    装备名称: '陶然意·蜕羽裤',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103812,
    uid: 103812,
    装备名称: '陶然意·登锋裤',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1094 },
      { 属性: 属性类型.外功基础攻击, 值: 1784 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101611,
    uid: 101611,
    装备名称: '花永裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.身法, 值: 1178 },
      { 属性: 属性类型.外功基础攻击, 值: 2675 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101610,
    uid: 101610,
    装备名称: '花世裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.力道, 值: 1178 },
      { 属性: 属性类型.外功基础攻击, 值: 2675 },
      { 属性: 属性类型.外功会心等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104338,
    uid: 104338,
    装备名称: '澄潭裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 3513 },
      { 属性: 属性类型.破招值, 值: 14865 },
      { 属性: 属性类型.无双等级, 值: 9695 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101653,
    uid: 101653,
    装备名称: '客行江湖·泉葩下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101652,
    uid: 101652,
    装备名称: '客行江湖·冥绝下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101589,
    uid: 101589,
    装备名称: '复梦裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 4215 },
      { 属性: 属性类型.外功破防等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101588,
    uid: 101588,
    装备名称: '重世裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 5621 },
      { 属性: 属性类型.加速等级, 值: 17451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101587,
    uid: 101587,
    装备名称: '涧松裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 5094 },
      { 属性: 属性类型.外功破防等级, 值: 6463 },
      { 属性: 属性类型.破招值, 值: 6463 },
      { 属性: 属性类型.无双等级, 值: 6463 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101586,
    uid: 101586,
    装备名称: '云合裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 4215 },
      { 属性: 属性类型.外功会心等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101563,
    uid: 101563,
    装备名称: '临云裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101562,
    uid: 101562,
    装备名称: '君信裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101545,
    uid: 101545,
    装备名称: '羡雁裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101544,
    uid: 101544,
    装备名称: '君深裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101509,
    uid: 101509,
    装备名称: '留诗下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101508,
    uid: 101508,
    装备名称: '清月下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101258,
    uid: 101258,
    装备名称: '漠名裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101257,
    uid: 101257,
    装备名称: '漠若裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 103668,
    uid: 103668,
    装备名称: '陶然意·拥狸裤',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103667,
    uid: 103667,
    装备名称: '陶然意·万仞裤',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103664,
    uid: 103664,
    装备名称: '陶然意·徇节裤',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103663,
    uid: 103663,
    装备名称: '陶然意·临溟裤',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103662,
    uid: 103662,
    装备名称: '陶然意·上造裤',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103660,
    uid: 103660,
    装备名称: '陶然意·征惯裤',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103658,
    uid: 103658,
    装备名称: '陶然意·春瓮裤',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103657,
    uid: 103657,
    装备名称: '陶然意·吴陵裤',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103656,
    uid: 103656,
    装备名称: '陶然意·穿心裤',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103652,
    uid: 103652,
    装备名称: '陶然意·蜕羽裤',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.身法, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103650,
    uid: 103650,
    装备名称: '陶然意·登锋裤',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.力道, 值: 940 },
      { 属性: 属性类型.外功基础攻击, 值: 1533 },
      { 属性: 属性类型.外功会心等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103507,
    uid: 103507,
    装备名称: '孤山寒月·逐刃裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103506,
    uid: 103506,
    装备名称: '孤山寒月·归期裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101635,
    uid: 101635,
    装备名称: '客行江湖·烟景裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101634,
    uid: 101634,
    装备名称: '客行江湖·秋序裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101234,
    uid: 101234,
    装备名称: '御水裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101233,
    uid: 101233,
    装备名称: '振藻裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101216,
    uid: 101216,
    装备名称: '扬明裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101215,
    uid: 101215,
    装备名称: '重悬裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101180,
    uid: 101180,
    装备名称: '静言下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101179,
    uid: 101179,
    装备名称: '棣仪下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100929,
    uid: 100929,
    装备名称: '壁明裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.外功会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100928,
    uid: 100928,
    装备名称: '壁邺裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.外功会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104520,
    uid: 104520,
    装备名称: '润源裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104519,
    uid: 104519,
    装备名称: '辞羁裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104488,
    uid: 104488,
    装备名称: '平皋裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 104487,
    uid: 104487,
    装备名称: '淮沛裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 104132,
    uid: 104132,
    装备名称: '羽缀裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104131,
    uid: 104131,
    装备名称: '夏凌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100899,
    uid: 100899,
    装备名称: '润源裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100898,
    uid: 100898,
    装备名称: '辞羁裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100863,
    uid: 100863,
    装备名称: '平皋裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100862,
    uid: 100862,
    装备名称: '淮沛裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100827,
    uid: 100827,
    装备名称: '羽缀裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100826,
    uid: 100826,
    装备名称: '夏凌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100695,
    uid: 100695,
    装备名称: '镂云裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100694,
    uid: 100694,
    装备名称: '炎翳裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100659,
    uid: 100659,
    装备名称: '智渊裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100658,
    uid: 100658,
    装备名称: '韶曦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1390 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100623,
    uid: 100623,
    装备名称: '修篁裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.身法, 值: 842 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100622,
    uid: 100622,
    装备名称: '绮陌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.力道, 值: 842 },
      { 属性: 属性类型.外功基础攻击, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100587,
    uid: 100587,
    装备名称: '涵煦裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.身法, 值: 788 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100586,
    uid: 100586,
    装备名称: '惜霜裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.力道, 值: 788 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101851,
    uid: 101851,
    装备名称: '春齐裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.身法, 值: 739 },
      { 属性: 属性类型.外功基础攻击, 值: 1678 },
      { 属性: 属性类型.外功会心等级, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101850,
    uid: 101850,
    装备名称: '曲汜裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.力道, 值: 739 },
      { 属性: 属性类型.外功基础攻击, 值: 1678 },
      { 属性: 属性类型.外功会心等级, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100551,
    uid: 100551,
    装备名称: '嵘嶂裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.身法, 值: 686 },
      { 属性: 属性类型.外功基础攻击, 值: 1119 },
      { 属性: 属性类型.外功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100550,
    uid: 100550,
    装备名称: '湛影裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.力道, 值: 686 },
      { 属性: 属性类型.外功基础攻击, 值: 1119 },
      { 属性: 属性类型.外功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
]

export default 下装装备数据
