import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('外功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 307 / 1024 }],
  },
  // 套装双会特效
  {
    // 11378
    增益名称: '朔气',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 41 },
    ],
  },
  {
    // 19510
    增益名称: '斩纷',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 154 }],
  },
  {
    // 29219
    增益名称: '斩狂枭',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  {
    // 29561
    增益名称: '冥鼔无视',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 512 }],
  },
  {
    增益名称: '星火',
    是否常驻面板: true,
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.郭氏力道, 值: 102 },
      { 属性: 属性类型.郭氏无双, 值: 307 },
    ],
  },
  // {
  //   // 29478
  //   增益名称: '星火无双',
  //   依赖奇穴: '星火',
  //   增益所在位置: '奇穴',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏无双, 值: 307 }],
  // },
]

export default 通用增益
