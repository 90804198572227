import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 一键宏 from './一键宏.json'
import 传统寒 from './传统寒.json'
import 紫温 from './紫温.json'
import CW常规15点 from './CW常规15点.json'
import CW声控21点 from './CW声控21点.json'

const 计算循环: 循环数据[] = [传统寒, 紫温, CW常规15点, CW声控21点] as 循环数据[]

export default 计算循环
