import { 技能基础数据模型 } from '@/@types/技能'
import 击水三千增益 from './技能增益/击水三千'
import 通用增益 from './技能增益/通用增益/通用'
import { 计算公式计算类型 } from '@/@types/伤害计算'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 逐波灵游增益 from './技能增益/逐波灵游'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '102091',
    技能名称: '击水三千·悟·一',
    统计名称: '击水三千·悟',
    技能伤害系数: 268,
    武器伤害系数: 1,
    技能破招系数: 1024 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102092',
    技能名称: '击水三千·悟·二',
    统计名称: '击水三千·悟',
    技能伤害系数: 298,
    武器伤害系数: 1,
    技能破招系数: 1147 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102093',
    技能名称: '击水三千·悟·三',
    统计名称: '击水三千·悟',
    技能伤害系数: 327,
    武器伤害系数: 1,
    技能破招系数: 1249 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102111',
    技能名称: '澹然若海·悟',
    统计名称: '澹然若海·悟',
    技能伤害系数: 783,
    武器伤害系数: 2,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102145',
    技能名称: '澹然若海·悟·龙卷',
    统计名称: '澹然若海·悟',
    技能伤害系数: 109,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102134',
    技能名称: '振翅图南·悟',
    统计名称: '振翅图南·悟',
    技能伤害系数: 80,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102173',
    技能名称: '振翅图南·悟·额外',
    统计名称: '振翅图南·悟',
    技能伤害系数: 279,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102161',
    技能名称: '定波砥澜·悟',
    技能伤害系数: 808,
    技能破招系数: 3103 / 1024,
    武器伤害系数: 2,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102104',
    技能名称: '逐波灵游·悟',
    技能伤害系数: 522,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: 逐波灵游增益,
  },
  {
    技能ID: '102103',
    技能名称: '溟海御波·悟·一',
    统计名称: '溟海御波·悟',
    技能伤害系数: 386,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102228',
    技能名称: '溟海御波·悟·二',
    统计名称: '溟海御波·悟',
    技能伤害系数: 348,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  // --------------
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 154425, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 177760, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 102927, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能名称: '刃凌·英雄',
    真实伤害: 118480, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

const 技能增益 = {}

export { 技能增益 }
export default 技能基础数据
