import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
import 多打芙蓉 from './22多打芙蓉.json'
import 橙武 from './橙武.json'
// import 水月芙蓉 from './22水月芙蓉.json'
// import 调整2 from './22调整.json'
// import 调整少芙 from './22调整少芙.json'
import 一键宏焚玉 from './一键宏焚玉.json'
// import 一键宏青歌 from './一键宏青歌.json'
// import 测试循环 from './测试循环.json'

const 计算循环: 循环数据[] = [
  // 测试循环,
  多打芙蓉,
  // 水月芙蓉,
  // 调整2,
  // 调整少芙,
  一键宏焚玉,
  // 一键宏青歌,
  橙武,
] as 循环数据[]

export default 计算循环
